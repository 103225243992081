import React, { useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../../../../context/aldersgate';
import EventFilterOptions from './../../../../dashboard/EventFilterOptions';
import SelectedEventModalView from './SelectedEventModalView';
import DReportedList from './DReportedList';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default function DNewlyReportedList(props) {

    const currentYear = new Date().getFullYear();
    const firstDay = new Date(currentYear, 0, 1);
    const lastDay = new Date(currentYear, 11, 31);

    const [reportData, setReportData] = useState([]);
    const [actionModalOpen, setActionModalOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const aldersgateContext = useContext(AldersgateContext);


    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: (!smMatches) ? '80%' : 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    useEffect(() => {
        SearchWithFilter();
    }, []);

    function ProcessEvents(reports) {
        let reportList = [];
        let count = 0;

        reports.forEach(report => {
            let ownerName = null;
            if(report !== undefined && report.event !== undefined && report.event.user !== undefined) {
                let name = [];
                if(report?.event?.user?.fName !== undefined && report?.event?.user?.fName !== null)
                    name.push(report?.event?.user?.fName);
                if(report?.event?.user?.lName !== undefined && report?.event?.user?.lName !== null)
                    name.push(report?.event?.user?.lName);

                ownerName = name.join(' ');

                if(ownerName === '') 
                    ownerName = 'Unknown';
            }

            let location = 'Unknown';
            if(report !== undefined && report.event !== undefined && report.event !== null) {
                let loc = [];

                if(report.event.city !== undefined && report.event.city !== null && report.event.city !== '')   
                    loc.push(report.event.city);
                if(report.event.state !== undefined && report.event.state !== null && report.event.state !== '')
                    loc.push(report.event.state);
                if(report.event.countryID !== undefined && report.event.countryID !== null && report.event.countryID !== '')
                    loc.push(report.event.countryID);

                if(loc.length > 0) {
                    location = loc.join(', ');
                } else {
                    location = 'Unknown';
                }
            }

            reportList.push({
                id: report.adID,
                eventID: report?.event?.eventID,
                elementID: count++,
                ownerName: ownerName,
                eventName: report.event.eventName,
                eventType: report.event.eventType,
                eventMedium: report.event.eventMedium,
                location: location,
                countryID: report.event.countryID,
                state: report.event.state,
                city: report.event.city,
                startingDate: report.event.startingDate,
                endingDate: report.event.endingDate,
                salesforceID: report.event.salesforceID,
                createdAt: report.createdAt,
                ingestToSFButtonEnabled: true,
                completedParticipants: report.completedParticipants,
                participants: report.event.participants,
                refreshCallback: SearchWithFilter

            });
        });

        console.log("ReportList: " + count);

        return reportList;
    }

    const viewEventDetails = (event) => {
        let eventID = event;
        console.log('viewEventDetails: ' + eventID);

        //find the event in the reportData
        let eventItem = reportData.find((item) => item.id === eventID);

        console.log('eventItem: ' + JSON.stringify(eventItem, null, 4));

        setSelectedEvent(eventItem);

        setActionModalOpen(true);

    }

    function SearchWithFilter() {

        aldersgateContext.GetFromAPI('reports/getlist/newreportedevents', null, 1).then((success, err) => {
            if(success) {
                setReportData(ProcessEvents(success));
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/event/getlist/eventquery/s?&api-key=foo' + query, {

    }

    const handelModalClose = () => {
        setActionModalOpen(false);
    }

    return (
        <Paper>
                <Box>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Reported Events
                    </Typography>
                </Box>
                <DReportedList assignedReports={reportData} setEventItemData={setReportData} onRowClick={viewEventDetails} />
                <Modal
                open={actionModalOpen}
                onClose={handelModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <SelectedEventModalView eventData={selectedEvent} closeModal={()=>{setActionModalOpen(false)}}/>
                    </Box>
                </Modal>
        </Paper>
    );
}