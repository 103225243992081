import React, { useState, useEffect } from 'react';
import { CSVLink } from "react-csv";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DReportEventListItemAdmin from './DReportEventListItemAdmin';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export default function DReportEventListAdmin(props) {
    const {  eventData, onRowClick, SetApprovedEvent, SetAllApproved } = props;

    
    const [eventList, setEventList] = useState([]);
    const [sortBy, setSortBy] = useState('lName');
    const [sortDirection, setSortDirection] = useState('desc');

    const [dropDownState, setDropDownState] = useState(0);

    const [currentPage, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [displayRows, setDisplayRows] = useState([]);

    let fontSizeTitle = 14;

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: (!smMatches) ? '80%' : 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    
    useEffect(() => {

        if(eventData === undefined || eventData === null)
            return;

        let sortData = [...eventData];

        let i = 0;
        let rows = [];
        sortData.sort(handleSortBy);
        sortData.forEach(person => {
            let processedRow = {...person, elementID:i, onRowClick:onRowClick};
            i++;
            rows.push(processedRow);
        });

        setEventList(rows);
    }, [eventData, sortBy, sortDirection]);

    useEffect(() => {
        let page = currentPage ? currentPage : 0;
        let count = eventList.length;
        let start = page * rowsPerPage;
        let end = start + rowsPerPage;

        if(end > count)
            end = count;
        
        let rows = eventList.slice(start, end);
        setDisplayRows(rows);
    }, [eventList, currentPage, rowsPerPage]);

    useEffect(() => {
        if(smMatches) {
            setRowsPerPage(15);
        } else {
            setRowsPerPage(5);
        }

        setPage(0);
    }, [smMatches]);
    

    const handleSortBy = (a, b) => {
        if(sortDirection === 'asc') {
            if(a[sortBy] < b[sortBy]) {
                return -1;
            }
            if(a[sortBy] > b[sortBy]) {
                return 1;
            }
            return 0;
        } else {
            if(a[sortBy] < b[sortBy]) {
                return 1;
            }
            if(a[sortBy] > b[sortBy]) {
                return -1;
            }
            return 0;
        }
    }

    const handleApproveAllToggle = (event) => {
        SetAllApproved(true);
    }


    function ChangeSort (event, data) {
        let { fieldID, direction } = data;
        console.log('ChangeSort: ' + fieldID + ' ' + direction);

        setSortBy(fieldID);
        setSortDirection(direction);
    }

    const RenderSortArrow = (arrowProps) => {
        const { fieldID } = arrowProps;
        if(sortBy === fieldID) {
            if(sortDirection === 'asc') {
                let parameter = { fieldID: fieldID, direction: 'desc' }
                return (
                    <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#bbbbbb', textAlign:'left' }}>
                        <IconButton onClick={(event) => ChangeSort(event, parameter)}>
                            <ArrowDropUpIcon  sx={{color:'#000000'}}/>
                        </IconButton>
                    </Box>
                )
            } else if(sortDirection === 'desc') {
                let parameter = { fieldID: 'updateAt', direction: 'desc' }
                return (
                    <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                        <IconButton onClick={(event) => ChangeSort(event, parameter)}>
                            <ArrowDropDownIcon sx={{color:'#000000'}}/>
                        </IconButton>
                    </Box>
                )
            }
        } else {
            let parameter = { fieldID: fieldID, direction: 'asc' }
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#bbbbbb', textAlign:'left' }}>
                    <IconButton onClick={(event) => ChangeSort(event, parameter)}>
                        <ArrowDropDownIcon  sx={{color:'#bbbbbb'}}/>
                    </IconButton>
                </Box>
            )
        }

    }

    const RenderHeader = () => {
        return (
            <Box>
                <Grid container p={1} sx={{backgroundColor:"#ffffff"}}>
                    <Grid item xs={4} sm={3}>

                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                            <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                Event Name
                            </Box>
                            <RenderSortArrow  fieldID='fName'/>
                        </Box>
      
                    </Grid>
                        
                    <Grid item xs={4} sm={1} sx={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center'}}>
                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                            <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                Type
                            </Box>
                            <RenderSortArrow  fieldID='lName'/>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={2} sx={{width:'100%', height:'100%', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', alignContent:'center'}}>

                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                            <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                Method
                            </Box>
                            <RenderSortArrow  fieldID='blabla'/>
                        </Box>

                    </Grid>

                    <Grid item xs={12} sm={1} sx={{width:'100%', height:'100%', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', alignContent:'center'}}>

                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                            <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                Country
                            </Box>
                            <RenderSortArrow  fieldID='blabla'/>
                        </Box>

                    </Grid>

                    <Grid item xs={12} sm={2} sx={{width:'100%', height:'100%', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', alignContent:'center'}}>

                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                            <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                Dates
                            </Box>
                            <RenderSortArrow  fieldID='createdAt'/>
                        </Box>

                    </Grid>

                    <Grid item xs={12} sm={1} sx={{width:'100%', height:'100%', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', alignContent:'center'}}>
                    
                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                            <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                #
                            </Box>
                            <RenderSortArrow  fieldID='createdAt'/>
                        </Box>
                        
                    </Grid>

                    <Grid item xs={12} sm={1} sx={{width:'100%', height:'100%', display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center', alignContent:'center'}}>
                        
                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                            <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                Status
                            </Box>
                            <RenderSortArrow  fieldID='eventStatus'/>
                        </Box>

                    </Grid>

                    <Grid item xs={12} sm={1}>
                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignContent:'center', alignItems:'center', height:'100%'}} onClick={handleApproveAllToggle}>
                            <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center', height:'100%'}}>
                                Approved
                            </Box>
                        </Box>
                    </Grid>

                </Grid>
            </Box>
        );
    }

    const handleDropdownChange = (event) => {
        setDropDownState(event.target.value);
        switch(event.target.value) {
            case 0:
                setSortBy('updatedAt');
                setSortDirection('desc');
                break;
            case 1:
                setSortBy('eventName');
                setSortDirection('asc');
                break;
            case 2:
                setSortBy('eventType');
                setSortDirection('asc');
                break;
            default:
                break;
        }
    }

    const RenderFooter = () => {

        let page = currentPage ? currentPage : 0;
        let count = eventList.length;
        let start = page * rowsPerPage;
        let end = start + rowsPerPage;

        if(end > count)
            end = count;
        
        const RenderTools = () => {
            if(displayRows.length <= 0) //if no rows, don't show tools
                return (<Box></Box>);

            return (
                    <Box>
                        {smMatches ? (
                        <Box></Box>
                    ) : (
                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                            <Box>
                                <FormControl sx={{ m: 2, minWidth: 120 }} size="small">
                                    <InputLabel id="demo-select-small-label">Sort By</InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={dropDownState}
                                        label="Sort By"
                                        onChange={handleDropdownChange}
                                    >
                                        <MenuItem value={0}>Date</MenuItem>
                                        <MenuItem value={1}>Event Name</MenuItem>
                                        <MenuItem value={2}>Event type</MenuItem>
                                        
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    )}
                </Box>
            );
        }

        //footer with pagination on right side
        return (
            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', alignContent:'center'}} >
                 
                 {<RenderTools />}

                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center', alignContent:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left', color:'bbbbbb' }}>
                        {start+1}-{end} of {count}
                    </Box>
                    <Box>
                        <IconButton onClick={SetPageBackward}>
                            <KeyboardArrowLeftIcon  sx={{color:'#bbbbbb'}} />
                        </IconButton>
                    </Box>
                    <Box>
                        <IconButton onClick={SetPageForward}>
                            <KeyboardArrowRightIcon  sx={{color:'#bbbbbb'}} />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        )
    }

    const SetPageForward = () => {
        setPage( prev => {
            let newPage = currentPage + 1;

            if(newPage * rowsPerPage > eventList.length)
                return prev;
    
            if(newPage < 0)
                return prev;

                return newPage;
            }
        );
    }

    const SetPageBackward = () => {
        setPage( prev => {
            let newPage = currentPage - 1;

            if(newPage * rowsPerPage > eventList.length)
                return prev;
    
            if(newPage < 0)
                return prev;

                return newPage;
            }
        );
    }
    

    return (
        <Box mb={1}>
            {smMatches ? (<RenderHeader />)
                : (null)
            }
            <Box>
                {(displayRows.length > 0) ?  displayRows.map((rowItemData) => (
                    <DReportEventListItemAdmin key={rowItemData.id} rowData={rowItemData} SetApprovedEvent={SetApprovedEvent} />
                )) : (<Box><Box pt={3}>No Event Reports</Box></Box>)}
            </Box>

            <RenderFooter />
        </Box>
    )
}