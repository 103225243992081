import React, {useEffect, useState, useContext} from  'react';
import { Link, useHistory } from 'react-router-dom';
import { AldersgateContext } from '../../context/aldersgate';
import EventFilterOptions from './EventFilterOptions';
import EventsViewDatagrid from './EventsViewDatagrid';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import useToken from './../auth/useToken';


function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default function GroupEvents(props) {
    const history = useHistory();
    const { token } = useToken();
    
    const currentYear = new Date().getFullYear();
    const firstDay = new Date(currentYear, 0, 1);
    const lastDay = new Date(currentYear, 11, 31);
    
    const [filterData, setFilterData] = useState({eventType: 'All', eventStatus: 'All', startDate: firstDay, endDate: lastDay, name: ''});
    const [eventData, setEventData] = useState([]);
    const [value, setValue] = useState(0);
    const aldersgateContext = useContext(AldersgateContext); 
    
    useEffect(() => {
        SearchWithFilter();
    }, [value]);

    function ProcessEvents(events) {
        let eventList = [];
        events.forEach(event => {
            eventList.push({
                id: event.eventID,
                eventName: event.eventName,
                eventType: event.eventType,
                eventMedium: event.eventMedium,
                startingDate: event.startingDate,
                endingDate: event.endingDate,
                eventStatus: event.eventStatus
            });
        });

        return eventList;
    }

    function HandleFilterOnChange(parameter, filterData) {
        setFilterData(prevState => {
            return {...prevState, [parameter]: filterData};
        });
    }

    function SearchWithFilter() {
        let query = '';
        if(filterData.eventType !== 'All')
            query += '&eventType=' + filterData.eventType;
        if(filterData.eventStatus !== 'All')
            query += '&eventStatus=' + filterData.eventStatus;
        if(filterData.startDate !== '')
            query += '&startDateRange=' + filterData.startDate;
        if(filterData.endDate !== '') {
            let endDate = new Date(filterData.endDate);
            endDate.setDate(endDate.getDate() + 1); // Add 1 day to include the last day
            query += '&endDateRange=' + endDate;
        }

        if(filterData.name !== '')
            query += '&name=' + filterData.name;
        
        query+= '&groupID=fqDf1B5ZYp';

        console.log(query);

        aldersgateContext.GetFromAPI('event/getlist/eventquery/s', query).then((success, err) => {

            if(success) {
                console.log(JSON.stringify(success));
                setEventData(ProcessEvents(success));
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/event/getlist/eventquery/s?api-key=foo' + query, {

    }

    return (

        <Grid container spacing={3}>
            <Grid item xs={12}>
                <EventFilterOptions search={SearchWithFilter} setFilterData={setFilterData}/>
                <EventsViewDatagrid eventData={eventData} />
            </Grid>
            <Grid item xs={12}>
                <Grid container justify="flex-start">
                    <Grid item>
                        <Button component={Link} to="event/new" size="medium" variant="contained" color="primary" startIcon={<AddIcon />}>Create Event</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    );
}