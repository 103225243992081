import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

export default function FVideoElement(props) {
    const { elementData } = props;

     //get initials
     const GetInitials = (fName, lName) => {
        let initials = fName.charAt(0) + lName.charAt(0);
        return initials.toUpperCase();
    }

    //get color based on initials
    const GetInitialsColor = (initials) => {
        let color = '#000000';

        // Generate a pseudo-random number based on two letters of the alphabet
        let charCode1 = initials.charCodeAt(0); // Convert first letter to char code (A = 0, B = 1, etc.)
        let charCode2 = initials.charCodeAt(1); // Convert second letter to char code (A = 0, B = 1, etc.)

        //make sure they arent zero or nan
        if(charCode1 === 0 || isNaN(charCode1)) {
            charCode1 = 1;
        }

        if(charCode2 === 0 || isNaN(charCode2)) {
            charCode2 = charCode1 * 15;
        }

        let randomNumber1 = (charCode1 * 26 + charCode2) % 200; // Generate a number between 0 and 255
        let randomNumber2 = (charCode2 * 11 + charCode1) % 200; // Generate a number between 0 and 255
        let randomNumber3 = (charCode1 * charCode2) % 200; // Generate a number between 0 and 255

        //convert to hex
        let hex1 = randomNumber1.toString(16);
        let hex2 = randomNumber2.toString(16);
        let hex3 = randomNumber3.toString(16);

        if(hex1.length === 1)
            hex1 = "0" + hex1;
        if(hex2.length === 1)
            hex2 = "0" + hex2;
        if(hex3.length === 1)
            hex3 = "0" + hex3;

        color = '#' + hex1 + hex2 + hex3;

        return color;
    }

    //pretty date format
    const GetPrettyDate = (date) => {
        let prettyDate = "";

        let today = new Date();
        let yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);

        let dateObj = new Date(date);

        if(dateObj.toDateString() === today.toDateString()) {
            prettyDate = "Today";
        } else if(dateObj.toDateString() === yesterday.toDateString()) {
            prettyDate = "Yesterday";
        } else {
            let twoWeeksAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 14);
            if(dateObj < twoWeeksAgo) {
                prettyDate = "";
            } else {
                prettyDate = dateObj.toLocaleDateString();
            }
        }

        return prettyDate;
    }

    if(elementData === undefined) {
        return (<div></div>);
    }

    let initials = GetInitials(elementData.author.fName, elementData.author.lName);
    let initialsColor = GetInitialsColor(initials);

    if(initials === "ILI") {
        initialsColor = "#3573b9";
    }


    let videoLink = elementData.link;
    
    let vimeo = false;
    //check if its a vimeo link or youtube
    if(videoLink.includes('vimeo')) {
        let vimeoID = videoLink.substring(videoLink.lastIndexOf('/') + 1);
        

        videoLink = "https://player.vimeo.com/video/" + vimeoID;
        vimeo = true;
    } else if(videoLink.includes('youtu')) {
        let youtubeID = videoLink.substring(videoLink.lastIndexOf('=') + 1);
        videoLink = "https://www.youtube.com/embed/" + youtubeID;
    } else {
        return null;
    }


    let iframeHTML = "";
    if(vimeo) {
        //vimeo embed
        iframeHTML = "<iframe src=\"" + videoLink + "\" width=\"100%\" height=\"100%\" loading=\"lazy\" frameborder=\"0\" allow=\"fullscreen; picture-in-picture\" allowfullscreen></iframe>";
    } else {
        //youtube embed
        iframeHTML = '<iframe width="100%" height="100%" loading="lazy" src="' + videoLink + '" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
    }
        

    return (
        <Paper key={elementData.adID}>
            <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', overflow:'auto'}}>
                <Box sx={{width:"100%", minHeight:'70px', display:'flex', flexDirection:'row', justifyContent:'flex-start', backgroundColor:'ButtonHighlight', overflow:'auto'}}>
                <Box m={1} sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignContent:'center'}}><Avatar sx={{ bgcolor:'#3573b9' }} aria-label="article"><OndemandVideoIcon /></Avatar></Box>
                    <Box sx={{width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignContent:'center'}}>
                        <Typography variant="h6" color="text.primary" >{elementData.title}</Typography>
                    </Box>
                    </Box>
                <Box>
                    <Box dangerouslySetInnerHTML={{__html: iframeHTML}} sx={{aspectRatio: "16/9", height:"100%", width:"100%"}} />
                </Box>
                
                {elementData.preview !== undefined && elementData.preview !== null && elementData.preview !== "" ? (
                    <Box>
                        <Typography variant="body2" color="text.secondary" sx={{textAlign:'left'}} pt={2} pl={1} pr={1} dangerouslySetInnerHTML={{__html: elementData.preview}} />
                    </Box>
                ) : null}
            </Box>
            <Box sx={{padding:'6px', overflow:'auto'}}>
                <Box sx={{width:"100%", display:'flex', flexDirection:'row', justifyContent:'space-between'}} mt={4}>
                    <Box><Typography variant="body2" color="text.secondary" sx={{textAlign:'left'}}>{elementData.author.fName} {elementData.author.lName}</Typography></Box>
                    <Box><Typography variant="body2" color="text.secondary" sx={{textAlign:'left'}}>{GetPrettyDate(elementData.date)}</Typography></Box>
                </Box>
            </Box>
        </Paper>
    );       
}