import React, {useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import UserLogin from "../components/user/UserLogin";
import { useHistory } from 'react-router-dom';
import SophConstants from '../constants/SophConstants';
import { AldersgateContext } from '../context/aldersgate';
import { NavBreadcrumbs } from '../components';

import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';

import IconedTextButton from '../components/IconedTextButton';

function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
      }
    }
));

const PageElement = (props) => {
    let elements = [];
    for (var key in props) {
        elements.push(<div key={key}>{props[key].pageIdentifier}</div>);
    }
    return elements;
}


export default function TranslateDashboard(props) {    
    const classes = useStyles();
    const history = useHistory();

    const [value, setValue] = useState(0);
    const [originalData, setOriginalData] = useState([]);
    const [hasOriginal, sethasOriginal] = useState(false);

    const [formDisabled, setFormDisabled] = useState(true);
    const [formData, setFormData] = useState({language:'en', pageIdentifier: '', adID: '', originalID:null});

    const authContext = useContext(AuthContext);
    const aldersgateContext = useContext(AldersgateContext);

    const [aState, setAState] = React.useState({loggedIn:false, adID:null, level:0, token:null, expiry:null});
    
    React.useEffect(() => {
      setAState(prev => {
          return({...authContext.accountState});
      });
    }, [authContext]);

    const language = props.match.params.lang;

    const handleAddPage = (event, name) => {
        console.log(event);

        setFormDisabled(false);
    }

    const handleClosePageForm = (event, name) => {
        setFormDisabled(true);
    }

    const handleTextChange = (event) => {
        console.log(event.target.id + " " + event.target.value);
        let value = {[event.target.id]: event.target.value};
        console.log(JSON.stringify(value));
        setFormData(prevState => {
            return {...prevState, ...value};
        });
    }

    const handleSubmit = (event) => {
        console.log(event);

        aldersgateContext.PostToAPI('translation/addpage', formData, null, 1).then((success, err) => {
            if(success) {
                setOriginalData((prevState => {
                    let current = [...prevState];
                    current.push(formData);
                    return [...current];
                }));
                
                setFormData({language:'en', pageIdentifier: '', adID: '', originalID:null});
    
                setValue((prevState => {
                    return (prevState+1);
                }));
                
                handleClosePageForm();
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/translation/addpage?api-key=foo', {


    }

    const NewPageElement = (props) => {
        if(props == false) {
            return (
                <TableRow
                    key="newRow"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row"><TextField id="pageIdentifier" label="Page Name" defaultValue="" variant="standard" onChange={handleTextChange}/></TableCell>
                    <TableCell align="right"><TextField id="adID" label="Database ID" defaultValue="en-" variant="standard" onChange={handleTextChange}/></TableCell>
                    <TableCell align="right"><Button variant="outlined" color="success" onClick={handleSubmit}>Save</Button> <Button variant="outlined" onClick={handleClosePageForm} color="error">Cancel</Button></TableCell>
                </TableRow> 
            )
        } else {
            return null;
        }
    }

    //const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        if(!hasOriginal) {

            aldersgateContext.GetFromAPI('translation/get/en').then((success, err) => {

                if(success) {
                    setOriginalData(success);
                    sethasOriginal(true);
                }
        
                if(err) {
                    console.log("Error: " + err);
                }
        
            }).catch((error) => {
                console.log("Error: " + error);
            });

            //fetch(SophConstants.API_ADDR + '/api/translation/get/en?api-key=foo', {
             
        }

    }, [value]);


    //check for token, block if not logged in
    if(aState.loggedIn === false) {
        return <UserLogin />
    }


    return (
    <div className={classes.root}>
        <Box m={1.5} bgcolor="background.paper" sx={{
        '& .MuiTextField-root': { width: '100%' },
      }}>
            <Grid container >
                <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                </Grid>
                <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <NavBreadcrumbs crumbs={[]} currentPage="Translate Dashboard" />
                            
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 250 }} aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell><b>Page</b></TableCell>
                                        <TableCell align="right"><b>ID</b></TableCell>
                                        <TableCell align="right"><b></b></TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {originalData.map((row) => (
                                            <TableRow
                                            key={row.adID}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                            <TableCell component="th" scope="row">
                                                {row.pageIdentifier}
                                            </TableCell>
                                            <TableCell align="right">{row.adID}</TableCell>
                                            <TableCell align="right"><Button variant="outlined" onClick={() => { history.push('/' + language + '/translate/admin/page/' + row.pageIdentifier); }}>Edit</Button></TableCell>
                                            </TableRow>
                                        ))}

                                        {NewPageElement(formDisabled)}

                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Grid>
                    </Grid>
                    <div class="font-icon-wrapper" onClick={handleAddPage}><IconedTextButton  icon={<Avatar><AddIcon /></Avatar>} title="Add Page" /></div>
                </Grid>
                <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                </Grid>
            </Grid>
        </Box>
    </div>
    );
}