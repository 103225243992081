import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SophConstants from '../../../constants/SophConstants';
import localizer from '../../../localization/localizer';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


export default function ViewReportDetails(props) {
    const { reportData } = props;
    const [signupLink, setSignupLink] = useState('');
    const [shortLink, setShortLink] = useState('');


    useEffect(() => {
        let lang = localizer.getLanguage();
        if(lang)
            lang = '/' + lang;
        
        setSignupLink(SophConstants.PUBLIC_ADDR + lang + '/reports/view/' + reportData.adID);
        setShortLink('/reports/view/' + reportData.adID);
    }, [reportData]);

    const history = useHistory();

    const GetStatusText = (status) => {
        switch (status) {
            case -1:
                return (<Typography variant="subtitle1" color={'red'}>Rejected</Typography>);
            case 0:
                return (<Typography variant="subtitle1">Assigned</Typography>);
            case 1:
                return (<Typography variant="subtitle1" color={'orange'}>In-Progress</Typography>);
            case 2:
                return (<Typography variant="subtitle1" color={'blue'}>Submitted</Typography>);
            case 3:
                return (<Typography variant="subtitle1" color={'green'}>Approved</Typography>);
            default:
                return "Unknown";
        }
    }

    const OnCloseClick = () => {
        
    }


    return (
        <Box sx={{display:'flex', flexDirection:'column'}}>
            <Box>
                <Typography variant="h5" gutterBottom>
                {reportData.fName} {reportData.lName} - Q{reportData.quarter} {reportData.year}
                </Typography>
            </Box>
            <Box>
                <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle1" gutterBottom>Status:</Typography>  {GetStatusText(reportData.status)}
                </Stack>
            </Box>
            <Box>
                <Typography variant="subtitle1" gutterBottom>
                    <Stack direction="row" spacing={1}>
                        <Box>
                            Share Link:
                        </Box>
                        <Link href={shortLink}>{signupLink}</Link>
                        <IconButton aria-label="copy link" onClick={() => {navigator.clipboard.writeText(signupLink)}}>
                            <ContentCopyIcon />
                        </IconButton>
                    </Stack>
                </Typography>
            </Box>
            <Box>
                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', gap:'10px'}} mt={4}>
                    <Box>
                        <Button variant="contained" onClick={() => {history.push('/reports/admin/' + reportData.adID)}}>Admin View</Button>
                    </Box>
                    <Box>
                        <Button variant="contained" color="error" onClick={OnCloseClick}>Close</Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}