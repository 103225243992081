import React from 'react';
import CertificatesDataGrid from './CertificatesDataGrid';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

export default function CertificateOverview(props) {
    const { eventData, isLoading } = props;

    if(eventData?.certificateEnabled === false) {
        return (
            <Paper elevation={1} style={{padding: '20px'}}>
            <Grid container spacing={2} p={5}>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" disabled>
                        Please contact your national coordinator for certificate information
                    </Button>
                </Grid>
            </Grid>
            </Paper>
        );
    }

    return (
        <Grid container spacing={2}>

            <Grid item xs={12}>
                <CertificatesDataGrid eventData={eventData}/>
            </Grid>
        </Grid>
    );

}