import React, {useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { AldersgateContext } from '../context/aldersgate';
import UserLogin from "../components/user/UserLogin";
import SophConstants from '../constants/SophConstants';
import { NavBreadcrumbs } from '../components';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';
import OriginalPageEdit from '../components/translation/OriginalPageEdit';
import OriginalPageViewTranslations from '../components/translation/OriginalPageViewTranslations';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Typography from '@mui/material/Typography';

function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
      }
    }
));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TranslateDashboard(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [translationData, setTranslationData] = useState({translations:[]});
    const [hasOriginal, sethasOriginal] = useState(false);

    const authContext = React.useContext(AuthContext);
    const [aState, setAState] = React.useState({loggedIn:false, adID:null, level:0, token:null, expiry:null});
    const aldersgateContext = useContext(AldersgateContext);
    
    React.useEffect(() => {
      setAState(prev => {
          return({...authContext.accountState});
      });
    }, [authContext]);

    //load page data from server
    useEffect(() => {

        if(!hasOriginal) {
            aldersgateContext.GetFromAPI('translation/get/en/' + viewPage, null, 1).then((success, err) => {
                if(success) {
                    CreateTranslationData(success).then((resolve, reject) => {
                        sethasOriginal(true);
    
                    }).catch((err) => {
    
                    });
                }
        
                if(err) {
                    console.log("Error: " + err);
                }
        
            }).catch((error) => {
                console.log("Error: " + error);
            });

            //fetch(SophConstants.API_ADDR + '/api/translation/get/en/' + viewPage + '?api-key=foo', {
        }

    }, [value]);

    //create page data object from server data
    var CreateTranslationData = (data) => {
        return new Promise ((resolve, reject) => {
            let translationObject = {...data};
            translationObject.originalID = data.adID;

            translationObject.translations = [];
            
            let index = 0;
            data.translations.forEach(function(value) {
            let translationInput = {...value};
                translationInput.id = index;
                translationInput.changed = false;

                translationObject.translations.push(translationInput);
                index++;
            });
        
            console.log(JSON.stringify(translationObject));
            setTranslationData(translationObject);
            
            resolve(true);
        });
    }


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const viewPage = props.match.params.id;
    
    //check for token, block if not logged in
    if(aState.loggedIn === false) {
        return <UserLogin />
    }


    return (
        <div className={classes.root}>
            <Box m={1.5} bgcolor="background.paper" sx={{
            '& .MuiTextField-root': { width: '100%' },
        }}>
                <Grid container >
                    <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                    </Grid>

                    <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                        <Grid container>
                            <Grid item xs={12}>
                                <NavBreadcrumbs crumbs={[{href:"/translate/admin/dashboard", text:"Translate Dashboard"}]} currentPage="Edit Page" />
                            </Grid>
                            <Grid item xs={12}>
                            <Box sx={{ width: '100%' }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                    <Box>
                                        <Typography component="h2" variant="h5" color="textSecondary" align="center">
                                        Page: {translationData.pageIdentifier}
                                        </Typography>
                                        <Typography component="h2" variant="h6" color="textSecondary" align="center">
                                        DB ID: {translationData.adID}
                                        </Typography>
                                    </Box>
                                    </Grid>
                                </Grid>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Edit Fields" {...a11yProps(0)} />
                                    <Tab label="Languages" {...a11yProps(1)} />
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>
                                    <OriginalPageEdit viewPage={viewPage}/>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <OriginalPageViewTranslations pageIdentifier={viewPage} originalID={translationData.originalID} />
                                </TabPanel>
                                </Box>
                                
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}