import React, { useState, useEffect, useContext } from 'react';
import SophConstants from '../../../../../constants/SophConstants';
import { AldersgateContext } from '../../../../../context/aldersgate';
import EventFilterOptions from './../../../../dashboard/EventFilterOptions';

import useToken from './../../../../auth/useToken';
import ADEventListItem from './ADEventListItem';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default function ADNewEvents(props) {
    const { token } = useToken();

    const [value, setValue] = useState(0);

    //get current date
    const currentDate = new Date();

    const dateYearAgo = new Date();
    dateYearAgo.setFullYear(dateYearAgo.getFullYear() - 1);
    
    const [filterData, setFilterData] = useState({eventType: 'All', eventStatus: 'All', startDate: dateYearAgo, endDate: currentDate, name: '', hasSFID: false});
    const [eventData, setEventData] = useState([]);

    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {
        SearchWithFilter();
    }, [value]);

    function ProcessEvents(events) {
        let eventList = [];
        let count = 0;
        events.forEach(event => {
            let ownerName = null;
            if(event !== undefined && event.owner !== undefined && event.owner !== null) {
                ownerName = event.owner.fName + ' ' + event.owner.lName;
            }

            eventList.push({
                id: event.eventID,
                elementID: count++,
                ownerName: ownerName,
                eventName: event.eventName,
                eventType: event.eventType,
                eventMedium: event.eventMedium,
                countryID: event.countryID,
                state: event.state,
                city: event.city,
                startingDate: event.startingDate,
                endingDate: event.endingDate,
                eventStatus: event.eventStatus,
                createSFButtonEnabled: true,
                salesforceID: null,
                participants: [...event.participants]
            });
        });

        return eventList;
    }

    function SearchWithFilter() {
        let query = '';
        if(filterData.eventType !== 'All')
            query += '&eventType=' + filterData.eventType;
        if(filterData.eventStatus !== 'All')
            query += '&eventStatus=' + filterData.eventStatus;
        if(filterData.startDate !== '')
            query += '&startDateRange=' + filterData.startDate;
        if(filterData.endDate !== '') {
            let endDate = new Date(filterData.endDate);
            endDate.setDate(endDate.getDate() + 1); // Add 1 day to include the last day
            query += '&endDateRange=' + endDate;
        }

        if(filterData.hasSFID) {
            query += '&hasSFID=true';
        } else {
            query += '&hasSFID=false';
        }

        if(filterData.name !== '')
            query += '&name=' + filterData.name;

        query += '&includeOwner=true';

        console.log(query);

        aldersgateContext.GetFromAPI('event/getlist/eventquery/s', query, 1).then((success, err) => {
            if(success) {
                setEventData(ProcessEvents(success));
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/event/getlist/eventquery/s?&api-key=foo' + query, {

    }

    // useEffect(() => {
    //     setEventData(props.eventData);
    // }, [props.eventData]);

    return (
        <Card sx={{minWidth:155}}>
            <CardContent>
                <Box>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        New Events
                    </Typography>
                </Box>
                <ADEventListItem eventItemData={eventData} setEventItemData={setEventData} />
            
            </CardContent>
        </Card>
    );
}