import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AldersgateContext } from '../../../../context/aldersgate';
import EventGroupsView from './EventGroupsView';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import BackupIcon from '@mui/icons-material/Backup';
import AddIcon from '@mui/icons-material/Add';


export default function EventViewModal(props) {
    const { eventID, closeModal } = props;

    const [eventData, setEventData] = useState({});
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [viewParticipants, setViewParticipants] = useState(false);

    const aldersgateContext = useContext(AldersgateContext);
    const history = useHistory();

    useEffect(() => {
        //get event data
        if(eventID == undefined || eventID == null) {
            console.log("No Event ID");
        } else {
            let includeModels = "include=participants&include=owner&include=groups";
            console.log("eventID: " + JSON.stringify(eventID, null, 2));
            aldersgateContext.GetFromAPI('event/' + eventID, includeModels, 1).then((response) => {
                console.log(response);
                if(response !== undefined && response !== null) {
                    setEventData(response);
                }
            }).catch((error) => {
                console.log(error);
            });
        }}, [eventID]);


    const PrettyDate = (date) => {
        let d = new Date(date);
        let month = d.toLocaleString('default', { month: 'long' });
        let day = d.getDate();
        let year = d.getFullYear();

        return month + " " + day + ", " + year;
    }

    const GetLocationString = ()=> {
        let locationString = [];
        if(eventData.countryID !== undefined) {
            if(eventData.city !== undefined && eventData.city !== null && eventData.city !== "")
                locationString.push(eventData.city);
            if(eventData.state !== undefined && eventData.state !== null && eventData.state !== "")
                locationString.push(eventData.state);
            if(eventData.countryID !== undefined && eventData.countryID !== null && eventData.countryID !== "")
                locationString.push(eventData.countryID);
        }

        let returnString = locationString.join(", ");
        if(returnString === "") {
            return "Unknown Location";
        } else {
            return (
                <Link href={"https://www.google.com/maps/search/" + returnString} target="_blank" rel="noreferrer">
                    {returnString}
                </Link>
            );
        }


    }

    const OnAcceptReport = () => {
        console.log("Accept Report");

        // Call API to accept report
        aldersgateContext.PostToAPI('admin/conference/report', {adID:eventData.id, region:selectedRegion, starfishTeam:selectedGroup, astatus:1}, null, 1).then((response) => {
            console.log(response);
            eventData?.refreshCallback();
        }).catch((error) => {
            console.log(error);
            eventData?.refreshCallback();
        });

    }

    const OnRejectReport = () => {
        console.log("Reject Report");
        // Call API to accept report
        aldersgateContext.PostToAPI('admin/conference/report', {adID:eventData.id, astatus:2}, null, 1).then((response) => {
            console.log(response);
            //refresh the list
            eventData?.refreshCallback();
            closeModal();
        }).catch((error) => {
            console.log(error);
            eventData?.refreshCallback();
            closeModal();
        });
    }

    const OnViewEvent = () => {
        history.push("/event/view/" + eventData.eventID);
    }

    if(eventData === undefined) {
        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4" component="h4">
                            No Event Data
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    const DisplayParticipants = () => {
        if(viewParticipants) {
            return (
                <Paper style={{maxHeight: 200, overflow: 'auto', padding:5}}>
                    {eventData?.participants?.length === 0 ? <Typography variant="body1" component="p">No Participants Registered</Typography> : 
                    <List>
                        {eventData?.participants?.map((participant, index) => (
                            <Box key={index}>
                                <Typography variant="body1" component="p">
                                    {participant.fName} {participant.lName} - {participant.completed ? "Completed" : "Not Completed"}
                                </Typography>
                            </Box>
                        ))}
                    </List>
                    }
                </Paper>
            );
        } else {
            return null;
        }
    } 

    const SetParticipantsVisibility = (visible) => {
        console.log('SetParticipantsVisibility: ', visible);
        setViewParticipants(visible);
    }

    const VisibilityOffBtn = () => {
        return (
            <IconButton aria-label="delete" size="large" onClick={() =>SetParticipantsVisibility(false)}>
                <VisibilityIcon />
            </IconButton>
        );
    }

    const VisibilityOnBtn = () => {
        return (
            <IconButton aria-label="delete" size="large" onClick={() =>SetParticipantsVisibility(true)}>
                <VisibilityOffIcon />
            </IconButton>
        );
    }

    const SalesforceIDLink = () => {
        if(eventData.salesforceID !== undefined && eventData.salesforceID !== null) {
            return (
                <Link href={"https://iliteam.lightning.force.com/lightning/r/Conference__c/" + eventData.salesforceID + "/view"} target="_blank" rel="noreferrer">
                    {eventData.salesforceID}
                </Link>
            );
        } else {
            return (
                <Box>
                    Not Ingested
                </Box>
            );
        }
    }

    const GetLeaderName = () => {
        let name = "Unknown";
        if(eventData?.user?.fName) {
            name = eventData?.user?.fName
        }

        if(eventData?.user?.lName) {
            name += " " + eventData?.user?.lName;
        }

        return name;
    }

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5" component="h5">
                        {eventData?.eventName}
                    </Typography>
                    <hr />
                    <Typography variant="body1" component="p">
                        {PrettyDate(eventData?.startingDate)} - {PrettyDate(eventData?.endingDate)}
                    </Typography>
                    <Typography variant="body1" component="p">
                        {GetLocationString()}
                    </Typography>
                    <Typography variant="body1" component="p">
                        {eventData.eventType} - {eventData.eventMedium}
                    </Typography>

                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                        Leader
                    </Typography>
                    <Typography variant="body1" component="p">
                        {GetLeaderName()}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                        Groups
                    </Typography>
                    <EventGroupsView groupData={eventData?.groups} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                        Salesforce ID
                    </Typography>
                    <Typography variant="body1" component="p">
                        {<SalesforceIDLink />}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                        Report
                    </Typography>
                    <Typography variant="body1" component="p">
                        {eventData?.report?.createdAt ? PrettyDate(eventData?.createdAt) : "Not Submitted"}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <Typography variant="h6" component="h6">
                            Participants
                        </Typography>
                        <Typography variant="body1" component="p">
                            {eventData?.report?.completedParticipants ? eventData?.report?.completedParticipants : 0 } Completed Participants
                        </Typography>
                        <Paper style={{maxHeight: 200, overflow: 'auto', padding:5}}>
                        <Typography variant="p" component="p" sx={{fontWeight: 600}}>
                            Participant List {eventData?.participants?.length ? '(' + eventData?.participants?.length + ')' : null}:
                            {viewParticipants ? <VisibilityOffBtn /> : <VisibilityOnBtn />}
                        
                        {eventData?.participants?.length === 0 ? <Typography variant="body1" component="p">No Participants Registered</Typography> : 
                            <DisplayParticipants />
                        }

                        </Typography>
                    </Paper>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{display:'flex', flexDirection:'row', gap:'10px'}}>
                    <Button variant="contained" color="error" onClick={OnRejectReport}>
                        Ignore Report
                    </Button>
                    <Button variant="contained" color="primary" onClick={OnViewEvent}>
                        View Event
                    </Button>
                    <Button variant="contained" color="primary" onClick={OnAcceptReport}>
                        Ingest to Salesforce
                    </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}