import React, { useState, useEffect } from 'react';
import DynamicCheckInListRow from './DCheckInListRow';
import DynamicCheckInListCard from './DCheckInListCard';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function DCheckInListItem(props) {
    const { rowData, signupID } = props;

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Box>
            {smMatches ? (<DynamicCheckInListRow key={rowData.adID} rowItemData={rowData} signupID={signupID} />)
                : (<DynamicCheckInListCard key={rowData.adID} rowItemData={rowData} signupID={signupID} />)
            }
        </Box>
    );
}