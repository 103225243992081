import React, {useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../context/aldersgate';
import AddEventToReport from './AddEventToReport';
import DReportEventList from './DReportEventList/DReportEventList';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ChatView from '../Chatbox/ChatView';
import Paper from '@mui/material/Paper';
import ReportingViewDatagrid from './ReportingViewDatagrid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import NativeSelect from '@mui/material/NativeSelect';
import AddIcon from '@mui/icons-material/Add';
import Box  from '@mui/material/Box';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Modal from '@mui/material/Modal';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


export default function ViewAssignedReport(props) {
    const { reportData, setFinalized, isLoading } = props;

    const [formDisabled, setFormDisabled] = useState(true);
    const [events, setEvents] = useState([...reportData.events]);
    const [value, setValue] = useState(0);
    const [sumbitStatus, setSubmitStatus] = useState(0);
    const [submitForm, setSubmitForm] = useState({confirm:false, userName:''});
    const aldersgateContext = useContext(AldersgateContext);

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    let assignedName  = "";
    let groupMembers = [];
    let groupOwner = {};
    let assignedReportID = undefined;

    let titleSubtext = "Reporting";
    let dateRange = "N/A";

    if(reportData !== undefined) {
        assignedName = reportData.fName + " " + reportData.lName;

        titleSubtext = "Reporting - Q" + reportData.quarter + " " + reportData.year;

        if(reportData.quarter !== undefined) {
            //get date range from quarter
            let dateRangeStart = new Date(reportData.year, (reportData.quarter - 1) * 3, 1);
            let dateRangeEnd = new Date(reportData.year, (reportData.quarter - 1) * 3 + 3, 0);

            dateRange = dateRangeStart.toLocaleDateString() + " - " + dateRangeEnd.toLocaleDateString();
        }

        groupOwner = reportData.owner;
        assignedReportID = reportData.adID;
    }

    const handleEventButtonToggle = (event, name) => {
        setFormDisabled(false);
    }

    const handleCloseForm = () => {
        setFormDisabled(true);
    }

    const addEventForm = (disableForm) => {
            const modalStyle = {
                width: (!smMatches) ? '80%' : '70%',
                minHeight: '50vh', maxHeight: '80vh',
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
                overflow:'scroll'
              };

            return (
                <Modal
                    open={!disableForm}
                    onClose={handleCloseForm}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ display:'flex', alignItems:'center',justifyContent:'center'}}
                >
                    <Box sx={modalStyle}>
                        <AddEventToReport reportData={reportData} setEvents={setEvents} handleCloseForm={handleCloseForm}/>
                    </Box>
                </Modal>
            );

    }

    const OnSubmitReport = () => {
        if(submitForm.confirm === false) {
            return;
        }
        
        setSubmitStatus(1);

        aldersgateContext.PostToAPI('reports/assigned/finalize/' + reportData.adID, null, null, 1).then((success, err) => {
            if(success) {
                setSubmitStatus(2);
            
                if(success.finalized === true) {
                    setFinalized(true);
                }
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/reports/assigned/finalize/' + reportData.adID + '?api-key=foo', {

    }

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setSubmitForm(prev => {
            return {...prev, [name]: value};
        });
    }

    const handleCheckBoxChange = (event) => {
        const { name, checked } = event.target;
        setSubmitForm(prev => {
            return {...prev, [name]: checked};
        });
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography component="h2" variant="h4" color="textSecondary">{assignedName}</Typography>
                <Typography variant="body1"  color="textSecondary">{titleSubtext}</Typography>
                <Typography variant="body1"  color="textSecondary">{dateRange}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={2}>
                <Box sx={{ display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', width:'100%' }}>
                    <Box sx={{width:'100%'}}>
                        <DReportEventList eventData={events} />
                    </Box>
                    <Box mb={2} mt={1}>
                        { reportData.finalized ? null :<Button onClick={handleEventButtonToggle} size="medium" variant="contained" color="primary" startIcon={<AddIcon />}>Add Event</Button>}
                    </Box>
                    </Box>
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <Box sx={{width:'100%', background:'#f1f1f1'}}>
                { reportData.finalized ? (
                    <Box sx={{ display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', alignContent:'center',  padding:'10px', gap:'10px' }}>
                        <Box>
                            <Typography variant="h6" color="textSecondary">Report Complete</Typography>
                            <Typography mb={2} variant="body1" color="textSecondary">This report has been submitted</Typography>
                        </Box>

                    </Box>
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', alignContent:'center',  padding:'10px', gap:'10px' }}>
                            <Box>
                                <Typography variant="h6" color="textSecondary">Complete Report</Typography>
                                <Typography mb={2} variant="body1" color="textSecondary">After adding all events, please complete the following information to submit your report</Typography>
                            </Box>
                            <Box>
                            <FormControlLabel control={<Checkbox name='confirm' value={submitForm.confirm} onChange={handleCheckBoxChange} />} label="The information provided is correct to the best of my knowledge" />
                            </Box>
                            <Box sx={{width:'90%'}}>
                                <TextField name='userName' fullWidth size="small" required id="outlined-basic" label="Type your name" variant="outlined" value={submitForm.userName} onChange={handleFormChange} />
                            </Box>
                            <Box mt={2}>
                                <Button disabled={(sumbitStatus < 1 && submitForm.confirm && submitForm.userName != '') ? false : true} size="large" variant="contained" color="success" onClick={OnSubmitReport} startIcon={<CheckCircleOutlineIcon />}>Submit</Button>
                            </Box>
                        </Box>
                )}
                </Box>
            </Grid>

            <Grid item xs={12}>
                    {addEventForm(formDisabled)}
            </Grid>
        </Grid>
    );

}