import React, { useState, useEffect } from 'react';
import { CSVLink } from "react-csv";
import { useTheme } from '@mui/material/styles';
import SophConstants from '../../../constants/SophConstants';
import localizer from '../../../localization/localizer';
import useMediaQuery from '@mui/material/useMediaQuery';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DCheckInListItem from './DCheckInListItem';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

export default function DCheckInList(props) {
    const { peopleListData, onRowClick, eventName, signupID, eventID } = props;

    
    const [peopleList, setPeopleList] = useState([]);
    const [sortBy, setSortBy] = useState('lName');
    const [sortDirection, setSortDirection] = useState('asc');
    const [lNameSortState, setLNameSortState] = useState(1);
    const [emailSortState, setEmailSortState] = useState(0);
    const [fNameSortState, setFNameSortState] = useState(0);
    const [dateSortState, setDateSortState] = useState(0);

    const [dropDownState, setDropDownState] = useState(2);

    const [currentPage, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [displayRows, setDisplayRows] = useState([]);


    
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [signupLink, setSignupLink] = useState('');


    useEffect(() => {
        let lang = localizer.getLanguage();
        if(lang)
            lang = '/' + lang;
        
        setSignupLink(SophConstants.PUBLIC_ADDR + lang + '/reg/' + signupID);
    }, [signupID]);

    let fontSizeTitle = 14;

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: (!smMatches) ? '80%' : 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    
    useEffect(() => {

        if(peopleListData === undefined || peopleListData === null)
            return;

        let sortData = [...peopleListData];

        let i = 0;
        let rows = [];
        sortData.sort(handleSortBy);
        sortData.forEach(person => {
            let processedRow = {...person, elementID:i, onRowClick:onRowClick};
            i++;
            rows.push(processedRow);
        });

        setPeopleList(rows);
    }, [peopleListData, sortBy, sortDirection]);

    useEffect(() => {
        let page = currentPage ? currentPage : 0;
        let count = peopleList.length;
        let start = page * rowsPerPage;
        let end = start + rowsPerPage;

        if(end > count)
            end = count;
        
        let rows = peopleList.slice(start, end);
        setDisplayRows(rows);
    }, [peopleList, currentPage, rowsPerPage]);

    useEffect(() => {
        if(smMatches) {
            setRowsPerPage(15);
        } else {
            setRowsPerPage(5);
        }

        setPage(0);
    }, [smMatches]);
    

    const handleSortBy = (a, b) => {
        if(sortDirection === 'asc') {
            if(a[sortBy] < b[sortBy]) {
                return -1;
            }
            if(a[sortBy] > b[sortBy]) {
                return 1;
            }
            return 0;
        } else {
            if(a[sortBy] < b[sortBy]) {
                return 1;
            }
            if(a[sortBy] > b[sortBy]) {
                return -1;
            }
            return 0;
        }
    }

    const FirstNameSort = direction => () => {
        setSortBy('fName');
        setSortDirection(direction);
        setFNameSortState(prev => {
            let newState = prev +1;

            if(newState > 2)
                newState = 0;

            return newState;
        });

        setLNameSortState(0);
        setEmailSortState(0);
        setDateSortState(0);
        setDropDownState(1);
    }

    const LastNameSort = direction => () => {
        setSortBy('lName');
        setSortDirection(direction);
        setLNameSortState(prev => {
            let newState = prev +1;

            if(newState > 2)
                newState = 0;

            return newState;
        });

        setFNameSortState(0);
        setEmailSortState(0);
        setDateSortState(0);
        setDropDownState(2);
    }

    const EmailSort = direction => () => {
        setSortBy('checkedIn');
        setSortDirection(direction);
        setEmailSortState(prev => {
            let newState = prev +1;

            if(newState > 2)
                newState = 0;

            return newState;
        });

        setFNameSortState(0);
        setLNameSortState(0);
        setDateSortState(0);
    }

    const DateSort = direction => () => {
        setSortBy('signupDate');
        setSortDirection(direction);
        setFNameSortState(0);
        setLNameSortState(0);
        setEmailSortState(0);
        setDateSortState(0);
        setDropDownState(0);
    }


    const RenderFNameSortArrow = () => {
        if(fNameSortState === 0) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#bbbbbb', textAlign:'left' }}>
                    <IconButton onClick={FirstNameSort('asc')}>
                        <ArrowDropDownIcon  sx={{color:'#bbbbbb'}}/>
                    </IconButton>
                </Box>
            )
        }

        if(fNameSortState === 1) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                    <IconButton onClick={FirstNameSort('desc')}>
                        <ArrowDropDownIcon sx={{color:'#000000'}}/>
                    </IconButton>
                </Box>
            )
        }

        if(fNameSortState === 2) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                    <IconButton onClick={DateSort('desc')}>
                        <ArrowDropUpIcon  sx={{color:'#000000'}} />
                    </IconButton>
                </Box>
            )
        }
    }

    const RenderLNameSortArrow = () => {
        if(lNameSortState === 0) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#bbbbbb', textAlign:'left' }}>
                    <IconButton onClick={LastNameSort('asc')}>
                        <ArrowDropDownIcon  sx={{color:'#bbbbbb'}}/>
                    </IconButton>
                </Box>
            )
        }

        if(lNameSortState === 1) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                    <IconButton onClick={LastNameSort('desc')}>
                        <ArrowDropDownIcon sx={{color:'#000000'}}/>
                    </IconButton>
                </Box>
            )
        }

        if(lNameSortState === 2) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                    <IconButton onClick={DateSort('desc')}>
                        <ArrowDropUpIcon  sx={{color:'#000000'}} />
                    </IconButton>
                </Box>
            )
        }
    }

    const RenderEmailSortArrow = () => {
        if(emailSortState === 0) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#bbbbbb', textAlign:'left' }}>
                    <IconButton onClick={EmailSort('asc')}>
                        <ArrowDropDownIcon  sx={{color:'#bbbbbb'}}/>
                    </IconButton>
                </Box>
            )
        }

        if(emailSortState === 1) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                    <IconButton onClick={EmailSort('desc')}>
                        <ArrowDropDownIcon sx={{color:'#000000'}}/>
                    </IconButton>
                </Box>
            )
        }

        if(emailSortState === 2) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                    <IconButton onClick={DateSort('desc')}>
                        <ArrowDropUpIcon  sx={{color:'#000000'}} />
                    </IconButton>
                </Box>
            )
        }
    }

    const RenderPageButtons = () => {
        return (
            <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                <IconButton onClick={DateSort('desc')}>
                    <KeyboardArrowLeftIcon  sx={{color:'#000000'}} />
                </IconButton>
                <IconButton onClick={DateSort('desc')}>
                    <KeyboardArrowRightIcon  sx={{color:'#000000'}} />
                </IconButton>
            </Box>
        )
    }

    const RenderHeader = () => {
        return (
            <Box>
                <Grid container p={1} sx={{backgroundColor:"#ffffff"}}>
                    <Grid item xs={4} sm={3}>
                        <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                            <Box>
                                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                    <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                                        <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                            First
                                        </Box>
                                        <RenderFNameSortArrow />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                        
                    <Grid item xs={4} sm={3} sx={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center'}}>
                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                            <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                Last
                            </Box>
                            <RenderLNameSortArrow />
                        </Box>
                    </Grid>

                    <Grid item xs={4} sm={3} sx={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center'}}>
                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                            <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                Location
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={3} sx={{width:'100%', height:'100%', display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center', alignContent:'center'}}>
                        <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                            <Box>
                                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                                    <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                        Check In
                                    </Box>
                                    <RenderEmailSortArrow />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                </Grid>
            </Box>
        );
    }

    const handleDropdownChange = (event) => {
        setDropDownState(event.target.value);
        switch(event.target.value) {
            case 0:
                setSortBy('checkedIn');
                setSortDirection('asc');
                setFNameSortState(0);
                setLNameSortState(0);
                setEmailSortState(1);
                setDateSortState(0);
                break;
            case 1:
                setSortBy('fName');
                setSortDirection('asc');
                setDateSortState(0);
                setFNameSortState(1);
                setLNameSortState(0);
                setEmailSortState(0);
                break;
            case 2:
                setSortBy('lName');
                setSortDirection('asc');
                setDateSortState(0);
                setFNameSortState(0);
                setLNameSortState(1);
                setEmailSortState(0);
                break;
            default:
                break;
        }
    }

    const RenderFooter = () => {

        let page = currentPage ? currentPage : 0;
        let count = peopleList.length;
        let start = page * rowsPerPage;
        let end = start + rowsPerPage;

        if(end > count)
            end = count;
        
        const RenderTools = () => {
            if(displayRows.length <= 0) //if no rows, don't show tools
                return (<Box></Box>);

            return (
                    <Box>
                        {smMatches ? (
                        <Box>
                            
                        </Box>
                    ) : (
                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                            <Box>
                                <FormControl sx={{ m: 2, minWidth: 120 }} size="small">
                                    <InputLabel id="demo-select-small-label">Sort By</InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={dropDownState}
                                        label="Sort By"
                                        onChange={handleDropdownChange}
                                    >
                                        <MenuItem value={0}>Not Checked In</MenuItem>
                                        <MenuItem value={1}>First Name</MenuItem>
                                        <MenuItem value={2}>Last Name</MenuItem>
                                        
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    )}
                </Box>
            );
        }

        //footer with pagination on right side
        return (
            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', alignContent:'center'}} >
                 
                 {<RenderTools />}

                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center', alignContent:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left', color:'bbbbbb' }}>
                        {start+1}-{end} of {count}
                    </Box>
                    <Box>
                        <IconButton onClick={SetPageBackward}>
                            <KeyboardArrowLeftIcon  sx={{color:'#bbbbbb'}} />
                        </IconButton>
                    </Box>
                    <Box>
                        <IconButton onClick={SetPageForward}>
                            <KeyboardArrowRightIcon  sx={{color:'#bbbbbb'}} />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        )
    }

    const SetPageForward = () => {
        setPage( prev => {
            let newPage = currentPage + 1;

            if(newPage * rowsPerPage > peopleList.length)
                return prev;
    
            if(newPage < 0)
                return prev;

                return newPage;
            }
        );
    }

    const SetPageBackward = () => {
        setPage( prev => {
            let newPage = currentPage - 1;

            if(newPage * rowsPerPage > peopleList.length)
                return prev;
    
            if(newPage < 0)
                return prev;

                return newPage;
            }
        );
    }

    return (
        <Box mb={1}>
            {smMatches ? (<RenderHeader />)
                : (null)
            }
            <Box>
                {(displayRows.length > 0) ?  displayRows.map((rowItemData) => (
                    <DCheckInListItem key={rowItemData.id} rowData={rowItemData} signupID={signupID}/>
                )) : (<Box><Box pt={3}>No Participants found</Box><Box><Link href={signupLink}>{signupLink}</Link></Box></Box>)}
            </Box>

            <RenderFooter />
        </Box>
    )
}