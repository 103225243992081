import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AldersgateContext } from '../../../context/aldersgate';
import DAssignedReportList from './DAssignedRList/DAssignedReportList';
import ViewReportDetails from './ViewReportDetails';
import NewReportAssignment from './NewReportAssignment';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';

export default function RegionReporting(props) {
    const { groupID } = props;

    const [searchQuery, setSearchQuery] = useState({year:2023, quarter:1});
    const [assignedReports, setAssignedReports] = useState([]);
    const [addAssigmentOpen, setAssignmnetOpen] = useState(false);
    const [viewReportOpen, setViewReportOpen] = useState(false);
    const [selectedReport, setSelectedReport] = useState({});
    const aldersgateContext = useContext(AldersgateContext);

    const history = useHistory();

    useEffect(() => {
        //get current year
        let currentYear = new Date().getFullYear();

        //get current quarter
        let currentQuarter = Math.floor((new Date().getMonth() + 3) / 3);

        setSearchQuery({year:currentYear, quarter:currentQuarter});
    }, []);

    useEffect(() => {
        QueryReports();
    }, [searchQuery]);

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#ffffff',
        border: '2px solid #000',
        overflow:'scroll',
        boxShadow: 24,
        p: 4,
        width:(smMatches) ? '50%' : '85%',
      };

    const QueryReports = () => {
        let query = '&groupID=' + groupID + '&year=' + searchQuery.year + '&quarter=' + searchQuery.quarter;
        query += '&adID=WA21N8-qG2NBQF1';

        aldersgateContext.GetFromAPI('reports/getlist/reports', query, 1).then((success, err) => {
            if(success) {
                setAssignedReports(success);
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/reports/getlist/reports/?api-key=foo' + query, {
    }

    const RenderModal = () => {
        return (
            <Modal
              open={addAssigmentOpen}
              onClose={() => setAssignmnetOpen(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              <NewReportAssignment setModalOpen={setAssignmnetOpen} refreshData={QueryReports} groupID={groupID}/>
            </Box>
          </Modal>
        );
    }

    const RenderViewReportModal = () => {
        return (
            <Modal
              open={viewReportOpen}
              onClose={() => setViewReportOpen(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              <ViewReportDetails reportData={selectedReport}/>
            </Box>
          </Modal>
        );
    }


    const RenderYearQuarterSelection = () => {
        let currentYear = new Date().getFullYear();

        let yearOptions = [];
        for(let i = 0; i < 3; i++) {
            let year = currentYear - i;
            yearOptions.push(<MenuItem key={year} value={year}>{year}</MenuItem>);
        }

        let quarterOptions = [];
        for(let i = 1; i <= 4; i++) {
            quarterOptions.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
        }

        let assignBoxCss = (smMatches) ? {display:'flex', flexDirection:'row', justifyContent:'flex-start', alignContent:'center', alignItems:'center'} : {display:'flex', flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center'};
        let queryBoxCss = (smMatches) ? {display:'flex', flexDirection:'row', justifyContent:'flex-end', alignContent:'center', alignItems:'center'} : {display:'flex', flexDirection:'row', justifyContent:'center', alignContent:'center', alignItems:'center'};
        return (
            <Grid container spacing={2} sx={{marginBottom:2}}>
                <Grid item xs={12} md={6}>
                    <Box sx={assignBoxCss}>
                        <Button variant="contained" onClick={() => {setAssignmnetOpen(true)}} sx={{marginRight:2}}>Assign Report</Button>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={queryBoxCss}>
                        <Box>
                            <FormControl sx={{ m: 1, minWidth: 150 }}>
                                <InputLabel id="year-select-label">Year</InputLabel>
                                <Select
                                    labelId="year-select-label"
                                    id="year-select"
                                    value={searchQuery.year}
                                    label="Year"
                                    size="small"
                                    onChange={(e) => setSearchQuery({...searchQuery, year:e.target.value})}
                                >
                                    {yearOptions}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl sx={{ m: 1, minWidth: 80 }}>
                                <InputLabel id="quarter-select-label">Quarter</InputLabel>
                                <Select
                                    labelId="quarter-select-label"
                                    id="quarter-select"
                                    value={searchQuery.quarter}
                                    label="Quarter"
                                    size="small"
                                    onChange={(e) => setSearchQuery({...searchQuery, quarter:e.target.value})}
                                >
                                    {quarterOptions}
                                </Select>
                            </FormControl>
                        </Box>

                    </Box>
                </Grid>
            </Grid>
        );

    }

    const OnRowClick = (onClickParams) => {
        let report = assignedReports.find((report) => {
            return report.adID === onClickParams;
        });

        setSelectedReport(report);
        
        setViewReportOpen(true);

        return;

        //find the report in assignedReports


        //if the report is finalized, go to the admin view page
        if(report.finalized === true) {
            history.push("reports/admin/" + onClickParams);
        } else {
            history.push("reports/view/" + onClickParams);
        }
    }

    return (
        <Box>
            <RenderModal />
            <RenderViewReportModal />
            <RenderYearQuarterSelection />
            <DAssignedReportList assignedReports={assignedReports} onRowClick={OnRowClick}/>
        </Box>
    );
}