import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import DateRangeIcon from '@mui/icons-material/DateRange';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DataUsageIcon from '@mui/icons-material/DataUsage';


export default function ReportTabs(props) {

  const { changeTabCallback } = props;

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(changeTabCallback !== undefined)
      changeTabCallback(newValue);
  };

  return (
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="icon label tabs example"
      >
        <Tab icon={<DateRangeIcon />} label="Events" />
        <Tab icon={<FactCheckIcon />} label="Reports" />
        <Tab icon={<AssignmentIcon />} label="Planning" />
        <Tab icon={<DataUsageIcon />} label="Analytics" />
      </Tabs>
  );
}