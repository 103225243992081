import React, { useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../../context/aldersgate';
import localizer from '../../../localization/localizer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LineChart } from '@mui/x-charts/LineChart';

export default function HistoricalEventsCount(props) {
    const { groupID, year } = props;

    const [yearData, setYearData] = useState([]);
    const [eventCountData, setEventCountData] = useState([]);
    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {
        GetHistoricalEventsCount();
    }, [year]);


    const GetHistoricalEventsCount = () => {
        if(groupID === undefined || groupID === null)
            return;

        let query = '&groupID=' + groupID;

        if(year !== undefined && year !== null) {
            query += '&year=' + year;
            query += '&history=10';
        }

        aldersgateContext.GetFromAPI('stats/year/eventcount', query, 1).then((success, err) => {
            if(success) {
                ParseData(success);
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });
        
        //fetch(SophConstants.API_ADDR + '/api/stats/year/eventcount/?api-key=foo' + query, {

    }

    const ParseData = (jsonData) => {
        let yearData = [];
        let eventCountData = [];

        for(let i = 0; i < jsonData.length; i++) {
            let year = new Date(jsonData[i].year, 0, 1);
            yearData.push(year);
            eventCountData.push(parseInt(jsonData[i].count) || 0);
        }

        setYearData(yearData);
        setEventCountData(eventCountData);
    }

    const valueFormatter = (value) => `${value} leaders`;

    if(eventCountData.length === 0)
        return null;

    const chartSetting = {
        height: 300,
        margin: { top: 50 },
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
            <LineChart
                xAxis={[{ id: 'Years', data: yearData,
                    scaleType: 'time',
                    valueFormatter: (date) => date.getFullYear(), }]}
                series={[
                    {
                    id:'Events', data: eventCountData, label: 'Historical Events',
                    showMark: false,
                    color: '#fdb462'
                    },
                ]}

                {...chartSetting}
            />
            </Box>
        </Box>
    );
}
