import React, { useState, useEffect, useContext } from 'react';
import SophConstants from '../../constants/SophConstants';
import { AldersgateContext } from '../../context/aldersgate';
import localizer from '../../localization/localizer';
import CenteredLoading from '../CenteredLoading';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LineChart } from '@mui/x-charts/LineChart';
import Title from './../Title';

export default function LeadersTrainedGraph(props) {
    const { groupID, year } = props;

    const [monthChartData, setMonthChartData] = useState([]);
    const [eventChartCountData, setEventChartCountData] = useState([]);
    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {
        GetHistoricalEventsCount();
    }, [year]);


    const GetHistoricalEventsCount = () => {

        let query = '';

        if(year !== undefined && year !== null) {
            query += 'year=' + year;
            query += '&history=10';
        }

        aldersgateContext.GetFromAPI('stats/month/participantcount', query).then((success, err) => {

            if(success) {
                ParseData(success);
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });
        
        //fetch(SophConstants.API_ADDR + '/api/stats/month/participantcount/?api-key=foo' + query, {
    }

    const ParseData = (jsonData) => {
        let monthData = [];
        let eventCountData = [];

        for(let i = 0; i < jsonData.length; i++) {
            monthData.push(jsonData[i].month);
            eventCountData.push(parseInt(jsonData[i].count) || 0);
        }

        setMonthChartData(monthData);
        setEventChartCountData(eventCountData);
    }


    if(eventChartCountData.length === 0)
        return <CenteredLoading />;

    const chartSetting = {
        height: 255,
        margin: { top: 10 },
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{height: 280}}>
                <Box>
                    <Title>{localizer.t("dashboard", "Leaders Trained")} </Title>
                </Box>
                <LineChart
                    xAxis={[{ id: 'Month', data: monthChartData,
                        },
                    ]}
                    series={[
                        {
                        id:'Events', data: eventChartCountData,
                        showMark: false,
                        color: '#3573b9'
                        },
                    ]}

                    {...chartSetting}
                />
            </Box>
        </Box>
    );
}
//leaders-trained-title