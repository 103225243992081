import React, { useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../../context/aldersgate';
import localizer from '../../../localization/localizer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { BarChart } from '@mui/x-charts/BarChart';

export default function QuarterlyEvents(props) {
    const { groupID, year } = props;

    const [quarterlyData, setQuarterlyData] = useState([]);
    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {
        GetQuarterlyEvents();
    }, [year]);


    const GetQuarterlyEvents = () => {
        if(groupID === undefined || groupID === null)
            return;

        let query = '&groupID=' + groupID;

        if(year !== undefined && year !== null) {
            query += '&year=' + year;
        }

        aldersgateContext.GetFromAPI('stats/year/alumni', query, 1).then((success, err) => {
            if(success) {
                setQuarterlyData(success);
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/stats/year/alumni/?api-key=foo' + query, {
    }

    const chartSetting = {
        xAxis: [
          {
            label: 'Leaders Trained',
          },
        ],

        height: 300,
        margin: { top: 50 },
      };

    const valueFormatter = (value) => `${value} leaders`;

    if(quarterlyData.length === 0)
        return null;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
            <BarChart
                dataset={quarterlyData}
                yAxis={[{ scaleType: 'band', dataKey: 'quarter' }]}
                series={[{ dataKey: 'count', label: 'Leaders Trained ' + year, valueFormatter }]}
                layout="horizontal"
                {...chartSetting}
            />
            </Box>
        </Box>
    );
}
