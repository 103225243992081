import React, { useEffect, useState, useContext } from 'react';
import { AldersgateContext } from '../../../../context/aldersgate';
import GroupsAutocomplete from './GroupsAutocomplete';
import Box from '@mui/material/Box';
import GroupDisplay from './GroupDisplay';
import { GroupSharp } from '@material-ui/icons';
import { Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

export default function PeopleGroupManagement(props) {
    const { user } = props;

    const [groups, setGroups] = useState([]);
    const [userData, setUserData] = useState({});
    const [addGroupOpen, setAddGroupOpen] = useState(false);
    const aldersgateContext = useContext(AldersgateContext);
    const [addNewGroup, setAddNewGroup] = useState({adID:null});

    useEffect(() => {
        let groupData = [];
        if(user && user.groups) {
            let count = 0;
            user.groups.forEach(group => {
                groupData.push({...group, id: count++});
            });

            setGroups(groupData);
        }

        setUserData(user);
    }, [user]);

    const SetGroupData = (groupPrarams) => {
        const {changedGroup} = groupPrarams;

        //loop through groups
        let newGroups = [];
        let unsetDefault = changedGroup.groupmember.isDefault;

        groups.forEach(group => {
            if(group.id === changedGroup.id) {
                newGroups.push(changedGroup);
            } else {
                if(unsetDefault)
                    group.groupmember.isDefault = false;

                newGroups.push(group);
            }
        });

        setGroups(newGroups);

        UpdateDataToServer(changedGroup);

    }

    const UpdateDataToServer = (group) => {
        console.log('UpdateDataToServer: ', group);

        aldersgateContext.PostToAPI('admin/user/groupmember', {userADID:userData.adID, groupmemberdata:group.groupmember}, null, 1).then((response) => {
            console.log(response);

        }).catch((error) => {
            console.log(error);
        });

    }

    const ToggleAddGroupOpen = () => {
        setAddGroupOpen(!addGroupOpen);
    }

    const AddGroupSelected = (groupID) => {
        setAddNewGroup({adID:groupID});
    }

    const ConfirmAddGroup = () => {
        setAddGroupOpen(!addGroupOpen);

        if(addNewGroup.adID) {

            aldersgateContext.PostToAPI('admin/user/addtogroup', {userADID:userData.adID, groupADID:addNewGroup.adID}, null, 1).then((response) => {
                console.log(response);

                if(response) {
                    JSON.stringify(response, null, 2);

                    let newGroups = [];
                    let count = 0;
                    for(let i = 0; i < response.length; i++) {
                        newGroups.push({...response[i], id: count++});
                        count++;
                    }

                    setGroups(newGroups);
                }

            }).catch((error) => {
                console.log(error);
            });
        }
    }

    const DeleteGroupMembership = (groupID) => {
        aldersgateContext.PostToAPI('admin/user/removefromgroup', {userADID:userData.adID, groupmemberADID:groupID}, null, 1).then((response) => {
            console.log(response);

            if(response) {
                JSON.stringify("deleted response: " + response, null, 2);
                
                let newGroups = [];
                let count = 0;

                groups.forEach(group => {
                    if(group.groupmember.adID !== groupID)
                        newGroups.push({...group, id: count++});
                });

                setGroups(newGroups);

            }

        }).catch((error) => {
            console.log(error);
        });
    }

    if(!groups || groups.length === 0) {
        return (
            <Stack direction="row" spacing={1}>
                <Typography variant="h5">No Groups</Typography>
                {!addGroupOpen ?
                <IconButton aria-label="add" onClick={ToggleAddGroupOpen}>
                    <AddCircleIcon />
                </IconButton>
            :<Box>
                <Stack direction="row" spacing={1}>
                    <IconButton aria-label="add" color='error' onClick={ToggleAddGroupOpen}>
                        <CancelIcon />
                    </IconButton>
                    <Box><GroupsAutocomplete groupSelected={AddGroupSelected} /></Box>
                    <IconButton aria-label="add" color='success' onClick={ConfirmAddGroup}>
                        <AddCircleIcon />
                    </IconButton>
                </Stack>
            </Box> 
            }
            </Stack>
        );
    }

    

    return (
        <Box>
            <Box>
            <Stack direction="row" spacing={1}>
                <Typography variant="h5">Groups</Typography>
                {!addGroupOpen ?
                <IconButton aria-label="add" onClick={ToggleAddGroupOpen}>
                    <AddCircleIcon />
                </IconButton>
            :<Box>
                <Stack direction="row" spacing={1}>
                    <IconButton aria-label="add" color='error' onClick={ToggleAddGroupOpen}>
                        <CancelIcon />
                    </IconButton>
                    <Box><GroupsAutocomplete groupSelected={AddGroupSelected} /></Box>
                    <IconButton aria-label="add" color='success' onClick={ConfirmAddGroup}>
                        <AddCircleIcon />
                    </IconButton>
                </Stack>
            </Box> 
            }
            </Stack>
            </Box>
            <Box>
                {groups.map((group) => (
                    <GroupDisplay key={group.id} groupData={group} setGroupData={SetGroupData} deleteGroupMember={DeleteGroupMembership} />
                ))}
            </Box>
        </Box>
    );
}