import React, {useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SophConstants from "../../constants/SophConstants";
import localizer from '../../localization/localizer';
import CreateCertificate from './CreateCertificate';
import { makeStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Box  from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import IconedTextButton from '../IconedTextButton';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import IconButton from '@mui/material/IconButton';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

export default function CertificatesDataGrid(props) {
    const {updateInformation, eventData } = props;
    const { participants, eventID } = eventData;

    const [formDisabled, setFormDisabled] = useState(true);
    const [facilitatorFormDisabled, setFacilitatorFormDisabled] = useState(true);
    const [formData, setFormData] = useState({id:0, adID:"", fName:"", lName:"", alias:"", email:"", phoneNumber:"", dropped:false, completed:false, language:"en", country:"", state:"", city:"", });
    const [value, setValue] = useState(0);

    const history = useHistory();

    useEffect(() => {
        
        
    }, [value]);

    const RenderCertificateDownloadElement = (paramData) => {
        const { rowData } = paramData;
        let language = localizer.getLanguage();

        return (
            <Box>
                <IconButton variant="contained" color="primary" href={SophConstants.API_ADDR + '/api/certificate/cert/' + eventID + '/' + rowData.adID + '/' + language + '/letter?api-key=foo'} size="large"><DownloadForOfflineIcon fontSize="large" /></IconButton>
            </Box>
        );
    }

    const RenderIsComplete = (paramData) => {
        const { rowData } = paramData;

        if(rowData.completed) {
            return (
                <Box>
                    <Typography align="left" variant="body2" color="action"><CheckIcon /></Typography>
                </Box>
            );
        } else {
            return (
                <Box>
                    <Typography align="left" variant="body2" color="error"><ClearIcon /></Typography>
                </Box>
            );
        }
    }

    //"/api/certificate/mcert/Test/Tester/type/5-4-2023/Carrollton/USA/en?api-key=foo"
    const columns = [
        { field: 'fName', headerName: 'First Name', flex: 1,editable: false },
        { field: 'lName', headerName: 'Last Name', flex: 1,editable: false },
        { field: 'email', headerName: 'Complete', type: 'boolean', flex: 0.5, editable: false, renderCell: (params) => {
            return (
                <RenderIsComplete rowData={params.row} />
            )
         }},
         { field: 'completed', headerName: 'Certificate', type: 'boolean', flex: 0.5, editable: false, renderCell: (params) => {
            return (
                <RenderCertificateDownloadElement rowData={params.row} />
            )
         }}
    ]; 

    function handleInvite(event, name) {
        setFacilitatorFormDisabled(false);
    }

    const closeAddFacilitatorForm = () => {
        setFacilitatorFormDisabled(true);
    }

    const handleCloseForm = () => {
        setFormDisabled(true);
    }

    const CreateCertificateModal = (propsData) => {
        return (
            <Modal
                open={!propsData.disableForm}
                onClose={closeAddFacilitatorForm}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <CreateCertificate closeForm={closeAddFacilitatorForm} eventData={eventData} eventID={eventID}/>
  
            </Modal>
        );
    }

    const handleRowClick = (params) => {
        setFormData(params.row);
        setFormDisabled(false);
      };
    
    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <CreateCertificateModal disableForm={facilitatorFormDisabled} />
            <Paper elevation={1}>
                <DataGrid onRowClick={handleRowClick} rows={participants} columns={columns} 
                    rowsPerPageOptions={[25, 50, 75]}
                    pagination
                    autoHeight
                />
            </Paper>
            
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                bgcolor: 'background.paper',
                borderRadius: 1,
              }}
            >
              <Box mr={2} sx={{display:'flex', flexDirection:'row', alignContent:'flex-start', alignItems:'center', justifyContent:'flex-start'}} onClick={handleInvite}><Box><IconedTextButton  icon={<Avatar><NoteAddIcon /></Avatar>} /></Box><Box sx={{alignSelf:'center'}}><Typography align="left" variant="body2" color="action">Create Certificate</Typography></Box></Box>
            </Box>  
        </Box>
    )

}