import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';

export default function ReportsFilterPeople(props) {
    const { setFilterData, search } = props;
    const [year, setYear] = React.useState('2022');
    const [quarter, setQuarter] = React.useState('Q2');

    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: 240 }}>
                <TextField id="name-filter" label="Name" variant="outlined" size="small"
                    onChange={(newValue) =>{
                            console.log(newValue.target.value);
                            setFilterData(prevState => {
                                return {...prevState, ['name']: newValue.target.value};
                            });
                        }
                    }
                />
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="group-type-filter">Year</InputLabel>
                <Select
                labelId="year-filter"
                id="year-filter-select"
                value={year}
                label="Year"
                size="small"
                onChange={(newValue) =>{
                        setFilterData(prevState => {
                            return {...prevState, ['yearSelect']: newValue.target.value};
                        });
                        setYear(newValue.target.value);
                    }
                }
                >
                <MenuItem value="2022"><em>2022</em></MenuItem>
                <MenuItem value="2023">2023</MenuItem>
                <MenuItem value="2021">2021</MenuItem>
                <MenuItem value="2020">2020</MenuItem>
                <MenuItem value="2019">2019</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="group-type-filter">Quarter</InputLabel>
                <Select
                labelId="quarter-filter"
                id="quarter-filter-select"
                value={quarter}
                label="Quarter"
                size="small"
                onChange={(newValue) =>{
                        setFilterData(prevState => {
                            return {...prevState, ['quarterSelect']: newValue.target.value};
                        });
                        setQuarter(newValue.target.value);
                    }
                }
                >
                <MenuItem value="Q1">Q1</MenuItem>
                <MenuItem value="Q2">Q2</MenuItem>
                <MenuItem value="Q3">Q3</MenuItem>
                <MenuItem value="Q4">Q4</MenuItem>
                <MenuItem value="Full">Year Report</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Button variant="outlined" size="large" onClick={search}>Search</Button>
            </FormControl>
    </div>
    )
}