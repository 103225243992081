import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {CardActionArea, CardActions } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import GroupIcon from '@mui/icons-material/Group';
import StreetviewIcon from '@mui/icons-material/Streetview';
import Checkbox from '@mui/material/Checkbox';


function GetMediumIcon(medium) {
    switch (medium) {
      case "Online":
        return <TapAndPlayIcon />;
        case "In-Person":
          return <GroupIcon />;
          case "Hybrid":
            return <StreetviewIcon />;
      default:
        break;
    }
  }

export default function DReportEventListCardAdmin(props) {
    const { rowItemData, SetApprovedEvent } = props;
    const [value, setValue] = useState(0);
    const [buttonEnabled, setButtonEnabled ] = useState(true);
    const [submitStatus, setSubmitting] = useState({status:0});

    const history = useHistory();

    useEffect(() => {
        setButtonEnabled(rowItemData.createSFButtonEnabled);
    }, [rowItemData]);

    useEffect(() => {

        if(rowItemData.salesforceID !== undefined && rowItemData.salesforceID !== null) {
            setSubmitting({status:2});
        } else {
            if(submitStatus.status !== 1)
                setSubmitting({status:0});
        }
        
    }, [rowItemData]);


    const viewEventDetails = (event) => {
        let eventID = event;
        console.log('viewEventDetails: ' + eventID);
        history.push("/event/view/" + eventID);
    }

    const options = {month: 'numeric', day: 'numeric' }
    let bgColor = '#FFFFFF';
    if(rowItemData !== undefined && rowItemData.elementID !== undefined) {
        bgColor = (rowItemData.elementID % 2 === 0) ? '#F5F5F5' : '#FFFFFF';
    }

    const handleCheckBoxChange = (event) => {
        const { name, checked } = event.target;
        SetApprovedEvent(name, checked);
    }

    return (
        <Card square={true} sx={{ border: 0, boxShadow: 0, backgroundColor:bgColor, ':hover': {backgroundColor:'#E0E0E0'}}}>
            <CardActionArea onClick={() => viewEventDetails(rowItemData.id)}> 
                <CardContent>
                    <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'flex-start', gap:'10px'}}>
                        <Box sx={{width:'100%'}}>
                            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start'}}>
                                <Box mr={2}><Checkbox name={rowItemData.eventID} onChange={handleCheckBoxChange} checked={rowItemData.validEvent} label="Approve" /></Box>
                                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
                                    <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                            <Box>
                                                <Typography color="text.primary" sx={{ fontSize: 16, textAlign:'left'}}>
                                                    {rowItemData.eventName}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography color="text.secondary" sx={{ fontSize: 12, textAlign:'left'}}>
                                                {new Date(rowItemData.startingDate).toLocaleDateString(undefined, options)} - {new Date(rowItemData.endingDate).toLocaleDateString(undefined, options)}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography color="text.secondary" sx={{ fontSize: 12, textAlign:'left'}}>
                                                {rowItemData.city}, {rowItemData.countryID}
                                                </Typography>
                                            </Box>
                                    </Box>
                                    <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-end'}}>
                                        <Box>
                                            <Typography color="text.secondary" sx={{ fontSize: 12, textAlign:'right'}}>
                                                {rowItemData.eventType}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography color="text.secondary" sx={{ fontSize: 12, textAlign:'right'}}>
                                                {rowItemData.eventMedium}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography color="text.secondary" sx={{ fontSize: 12, textAlign:'right'}}>
                                                {rowItemData.eventStatus}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}