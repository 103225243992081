import React, { useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../../context/aldersgate';
import localizer from '../../../localization/localizer';
import { PieChart, pieArcClasses } from '@mui/x-charts/PieChart';

export default function CreatedVsCompletedPie(props) {
    const { groupID, year } = props;

    const [dateData, setDateData] = useState([]);
    const [displayChart, setDisplayChart] = useState(false);
    const [chartData, setChartData] = useState([{id:0,value:20, label:'Created'},{id:1,value:10, label:'Completed'}]);
    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {
        GetHistoricalEventsCount();
    }, [year]);

    useEffect(() => {
        if(chartData.length > 0)
            setDisplayChart(true);
    }, [chartData]);


    const GetHistoricalEventsCount = () => {
        if(groupID === undefined || groupID === null)
            return;

        let query = '&groupID=' + groupID;

        if(year !== undefined && year !== null) {
            query += '&year=' + year;
            query += '&history=10';
        }

        aldersgateContext.GetFromAPI('stats/month/eventcount', query, 1).then((success, err) => {
            if(success) {
                ParseData(success);
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });
    }

    const ParseData = (jsonData) => {
        let timeData = [];
        let eventCreatedCount = [];
        let eventCompletedCount = [];
        let createdCount = 0;
        let completedCount = 0;

        for(let i = 0; i < jsonData.length; i++) {
            let date = new Date(jsonData[i].year, jsonData[i].month, 1);
            timeData.push(date);
            eventCreatedCount.push(parseInt(jsonData[i].countCreated) || 0);
            eventCompletedCount.push(parseInt(jsonData[i].countCompleted) || 0);
            createdCount += parseInt(jsonData[i].countCreated) || 0;
            completedCount += parseInt(jsonData[i].countCompleted) || 0;
        }
        

        let data = [];
        let created = {id:0,value:createdCount, label:'Created'};
        let completed = {id:1,value:completedCount, label:'Completed'};
        data.push(created);
        data.push(completed);

        setChartData([...data]);


    }

    const valueFormatter = (value) => `${value} leaders`;

    if(!displayChart)
        return null;

    const chartSetting = {
        height: 300,
        margin: { top: 50 },
        slotProps: {
            legend: {
              hidden: true,
            }
        }
    };

    const data = [...chartData];
    return (
        
        <PieChart
        series={[
            {
                data,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30 },
            },
          ]}
          sx={{
            [`& .${pieArcClasses.faded}`]: {
              fill: 'gray',
            },
          }}
        {...chartSetting}
      />
            

    );
}
