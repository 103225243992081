import React, { useState, useEffect } from 'react';
import DReportEventListRowAdmin from './DReportEventListRowAdmin';
import DReportEventListCardAdmin from './DReportEventListCardAdmin';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function DReportEventListItem(props) {
    const { rowData, SetApprovedEvent } = props;

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Box>
            {smMatches ? (<DReportEventListRowAdmin key={rowData.adID} rowItemData={rowData} SetApprovedEvent={SetApprovedEvent} />)
                : (<DReportEventListCardAdmin key={rowData.adID} rowItemData={rowData} />)
            }
        </Box>
    );
}