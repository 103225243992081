import React, { useState, useEffect, useContext } from 'react';
import SophConstants from '../../constants/SophConstants';
import { AldersgateContext } from '../../context/aldersgate';
import TotalTrainedCard from './TotalTrainedCard';

export function GlobalTrainedCount(props) {
    const [totalCount, setTotalCount] = useState(0);
    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {
        requestData(0);
    }, []);

    function requestData(retryCount) {

        aldersgateContext.GetFromAPI('stats/globalcount').then((success, err) => {
            if(success) {
                setTotalCount(success.totalCount);
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });


        //fetch(SophConstants.API_ADDR + '/api/stats/globalcount?api-key=foo', {

    }

    return (
        <div>
            <TotalTrainedCard totalCount={totalCount} />
        </div>
    );
}

export default GlobalTrainedCount;
