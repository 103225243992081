import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';



export default function IconedTextButton(props) {
    return (
            <Grid container >
                <Grid item align="left" alignItems="left" style={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton aria-label="delete">
                    {props.icon}
                </IconButton>
                <Grid container>
                        <Grid item xs={12} align="left" style={{ display: "flex", alignItems: "center" }}>
                            <Box ml={1} >
                            <Typography component="h2" variant="h6" color="textSecondary" align="left">
                                {props.title}
                            </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    );
}

//https://smartdevpreneur.com/the-complete-guide-to-material-ui-grid-align-items/