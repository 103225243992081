import React, {useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SophConstants from '../../constants/SophConstants';
import { AldersgateContext } from '../../context/aldersgate';
import AddFacilitator from './AddFacilitator';
import ViewFacilitator from './ViewFacilitator';
import DFacilitatorList from './DFacilitatorList/DFacilitatorList';
import DSessionsList from './DSessionsList/DSessionsList';
import ViewSession from './ViewSession';
import { makeStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Box  from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import IconedTextButton from '../IconedTextButton';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs'

export default function SessionsDataGrid(props) {
    const {updateInformation } = props;
    const {updateData, setSnackbarMessage} = updateInformation;
    const { facilitators, fsessions, eventID, startingDate } = props.eventData;

    const [formDisabled, setFormDisabled] = useState(true);
    const [facilitatorList, setFacilitatorList] = useState([{id:0, adID:'', fName:"None", lName:""}]);
    const [formData, setFormData] = useState({eventID:null, name:'butt', sessionStart:dayjs(new Date(startingDate)), time:dayjs(new Date()), sessionLength:45, facilitatorID:''});
    const [sessionList, setSessionList] = useState([]);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [value, setValue] = useState(0);
    const aldersgateContext = useContext(AldersgateContext);

    const history = useHistory();
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    const editSessionModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#ffffff',
        border: '2px solid #000',
        overflow:'scroll',
        boxShadow: 24,
        p: 4,
        width:(smMatches) ? '50%' : '85%'
    };

    useEffect(() => {
        
        
    }, [value]);

    useEffect(() => {
        ParseFacilitators();
    }, [facilitators]);

    useEffect(() => {
        ParseSessions();
    }, [fsessions]);

    function ParseSessions() {
        let sessionListBuilder = [];

        let facilitatorLookup = new Map();
        facilitators.forEach((facilitator) => {
            facilitatorLookup.set(facilitator.adID, facilitator);
        });

        let count = 0;
        fsessions.forEach((session) => {
            let facilitator = facilitatorLookup.get(session?.facilitator?.adID);
            let facilitatorName = (facilitator?.fName || facilitator?.lName) ? facilitator.lName + " " + facilitator.fName : "*None*";
            let sessionData = {...session, id:count, facilitatorName, facilitator:facilitatorLookup.get(session?.facilitator?.adID)};
            sessionListBuilder.push(sessionData);
            count++;
        });

        setSessionList(sessionListBuilder);
    }

    function ParseFacilitators() {
        let facilitatorListBuilder = [];

        let count = 0;
        facilitators.forEach((facilitator) => {
            let facilitatorData = {id:count, adID:facilitator.adID, fName:facilitator.fName, lName:facilitator.lName};
            facilitatorListBuilder.push(facilitatorData);
            count++;
        });

        facilitatorListBuilder.push({id:count, adID:'null', fName:"None", lName:""})

        setFacilitatorList(facilitatorListBuilder);

    }

    const EditSessionDataForm = (modalParams) => {
        const {sessionData} = modalParams;
        return (
          <div>
            <Modal
                open={openEditModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={editSessionModalStyle}>
                    <ViewSession eventID={eventID} sessionData={sessionData} facilitators={facilitatorList} closeForm={handleCloseModal} updateInformation={updateInformation} />
                </Box>
            </Modal></div>
        );
    }

    const handleCloseModal = () => {
        setOpenEditModal(false);
    }

    const handleCloseForm = () => {
        setFormDisabled(true);
    }

    const handleOpenForm = () => {
        setFormDisabled(false);
    }

    const handleRowClick = (params) => {
        setFormData(prev => ({...prev, adID:params.adID, eventID:eventID, name:params.name, sessionStart:params.sessionStart, time:params.time, sessionLength:params.sessionLength, facilitatorID:params?.facilitator?.adID}));
        setOpenEditModal(true);
      };

    const RenderAddSession = ()=> {
        const [newSessionData, setNewSessionData] = useState({eventID:null, sessionName:'', sessionStart:dayjs(new Date(startingDate)), time:dayjs(new Date()), sessionLength:45, facilitatorID:''});

        useEffect(() => {
            setNewSessionData(prev => ({...prev, eventID:eventID}));
        }, [eventID]);

        const handleSessionNameChange = (sessionNameData) => {
            setNewSessionData(prev => ({...prev, sessionName:sessionNameData.target.value}));
        }
        
        const handleFacilitatorSelect = (facilitatorSelectData) => {
            setNewSessionData(prev => ({...prev, facilitatorID:facilitatorSelectData.target.value}));
        }

        const handelDateChange = (date) => {
            setNewSessionData(prev => ({...prev, date:date}));
        }

        const handelTimeChange = (newValue) => {
            setNewSessionData(prev => ({...prev, time:newValue}));
        }

        const handelLengthChange = (lengthValue) => {
            setNewSessionData(prev => ({...prev, sessionLength:lengthValue.target.value}));
        }

        const handleAddSessionSubmit = () => {

            if(newSessionData.eventID === null || newSessionData.name === '') {
                return;
            }

            //adjust the date to the time
            let sessionStart = new Date(newSessionData.sessionStart);
            sessionStart.setHours(newSessionData.time.hour());
            sessionStart.setMinutes(newSessionData.time.minute());
            sessionStart.setSeconds(0);

            let sessionSubmitData = {...newSessionData, sessionStart:sessionStart};

            aldersgateContext.PostToAPI('event/' + eventID + '/session', sessionSubmitData, null, 1).then((success, err) => {
                if(success) {
                    updateData(prevState => {
                        let newState = prevState;
                        newState = newState+1;
                        return {...newState};
                    });
                }
        
                if(err) {
                    console.log("Error: " + err);
                }
        
            }).catch((error) => {
                console.log("Error: " + error);
            });

            //fetch(SophConstants.API_ADDR + '/api/event/' + eventID + '/session/' + '?api-key=foo', {
        }

        return (
            <Box>
                <Grid container mt={4} spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h5" component="h5" gutterBottom>
                            Add Session
                        </Typography>
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth><TextField id="sessionName" label="Session Name" variant="outlined" value={newSessionData.sessionName} onChange={handleSessionNameChange} /></FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="facilitator-select">Session Facilitator</InputLabel>
                            <Select
                            labelId="facilitator-select"
                            id="simple-facilitator-select"
                            value={newSessionData.facilitatorID}
                            label="Session Facilitator"
                            onChange={handleFacilitatorSelect}
                            >

                            {facilitatorList.map((facilitator) => {
                                return <MenuItem key={facilitator.id} value={facilitator.adID}>{facilitator.fName + ' ' + facilitator.lName}</MenuItem>
                            })}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                                id="sessionDate"
                                label="Session Date"
                                inputFormat="MM/dd/yyyy"
                                value={newSessionData.sessionStart}
                                onChange={handelDateChange}
                                renderInput={(params) => <TextField  sx={{  width: '100%' }} {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker id="date-picker-input-id" label="Start Time" value={newSessionData.time} onChange={handelTimeChange} renderInput={(props) => <TextField  {...props} helperText={null} sx={{width:'100%'}} />}/>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="length-select">Session Length</InputLabel>
                            <Select
                            labelId="length-select"
                            id="simple-length-select"
                            value={newSessionData.sessionLength}
                            label="Session Length"
                            onChange={handelLengthChange}
                            >
                            <MenuItem value={10}>10 Minutes</MenuItem>
                            <MenuItem value={15}>15 Minutes</MenuItem>
                            <MenuItem value={20}>20 Minutes</MenuItem>
                            <MenuItem value={25}>25 Minutes</MenuItem>
                            <MenuItem value={30}>30 Minutes</MenuItem>
                            <MenuItem value={45}>45 Minutes</MenuItem>
                            <MenuItem value={60}>60 Minutes</MenuItem>
                            <MenuItem value={75}>75 Minutes</MenuItem>
                            <MenuItem value={90}>90 Minutes</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', alignContent:'center', width:'100%', gap:'15px'}}>
                    <Box>
                        <Button variant="contained" color="error" onClick={handleCloseForm} sx={{mt:2, mb:2}}>Cancel</Button>
                    </Box>
                    <Box>
                        <Button variant="contained" color="primary" onClick={handleAddSessionSubmit} sx={{mt:2, mb:2}}>Add Session</Button>
                    </Box>
                </Box>
            </Box>
        )
    }

    const RenderAddSessionBtn = () => {
        return (
            <Box mr={2} mt={2} sx={{display:'flex', flexDirection:'row', alignContent:'flex-start', alignItems:'center', justifyContent:'flex-start'}} onClick={handleOpenForm}>
                <Box>
                    <IconedTextButton  icon={<Avatar><AddIcon /></Avatar>} />
                </Box>
                <Box sx={{alignSelf:'center'}}>
                    <Typography align="left" variant="body2" color="action">Add Session</Typography>
                </Box>
            </Box>
        );
    }
    
    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <Paper elevation={1}>
                <DSessionsList sessionListData={sessionList} onRowClick={handleRowClick} />
            </Paper>
            
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                bgcolor: 'background.paper',
                borderRadius: 1,
              }}
            >
                <Box sx={{display:'flex', flexDirection:'column', alignContent:'flex-start', width:'100%'}}>

                    {formDisabled ? <RenderAddSessionBtn /> : <RenderAddSession /> }

                </Box>

                <EditSessionDataForm sessionData={formData}/>
            </Box>
        </Box>
    )
}