//import detectBrowserLanguage from 'detect-browser-language'
var SophConstants = require('../constants/SophConstants');

const getStoredLanguage = () => {
    const langString = sessionStorage.getItem('lang'); //localStorage
    const userLanguage = JSON.parse(langString);
    console.log("saved token: " + userLanguage);

    if(userLanguage === null) {
        return "en";
    }

    return userLanguage;
};

let initialized = false;
var language = getStoredLanguage();
var languageContainer  = [];
let languageChangeCallbacks = [];
let redirectCallbacks = [];
let supportedLanguages = ["en", "es", "pt", "fr", "ru"];

SetTextDirection();
initialized = true;

ingestTranslations(language);

const storeLanguage = userLanguage => {
    console.log("saving token: " + userLanguage);
    sessionStorage.setItem('lang', JSON.stringify(userLanguage)); //localStorage
};


function getLanguage() {
    if(!initialized) {
        language = getStoredLanguage();
        initialized = true;
    }

    return language;
}

function setLanguage(newlang) {
    if(language !== newlang) {

        language = newlang;
        ingestTranslations(newlang);
        storeLanguage(newlang);
    }
}

function SetTextDirection() {
    
    if(language == 'ar') {
        if(document.dir != 'rtl') {
            document.dir = 'rtl';

            redirectCallbacks.forEach(function(callback) {
                callback(language);
            });
        }
    } else {
        if(document.dir != 'ltr') {
            document.dir = 'ltr';
            
            redirectCallbacks.forEach(function(callback) {
                callback(language);
            });
        }   
    }
}

function IsSupportedLanguage(lang) {
    return supportedLanguages.includes(lang);
}

function GetSupportedLanguages() {
    return supportedLanguages;
}

function AddLanguageChangeCallback(callback) {
    languageChangeCallbacks.push(callback);
}

function AddRedirectCallback(callback) {
    redirectCallbacks.push(callback);
}

function ingestTranslations(newLanguage) {
    let getLanguage = newLanguage || language;

    console.log("*****Fetching translations for " + getLanguage);

    fetch(SophConstants.API_ADDR  + '/api/translation/get/' + getLanguage + '/dashboard?api-key=foo', {
        method: 'get',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
        },
        body: null
    }).then((res) => {
        return res.json();
    }).then((jsonData, status) => {
        let page = jsonData.pageIdentifier;

        if(languageContainer[getLanguage] === undefined) {
            languageContainer[getLanguage] = [];
        }

        insertTranslations(page, jsonData);
        
        languageChangeCallbacks.forEach(function(callback) {
            callback(language);
        });

        SetTextDirection();
    }).catch((err) => {
        console.log(err);
    });
}

function t(page, key, fallback, params) {
    if(languageContainer[language] !== undefined && languageContainer[language][page] !== undefined) {
        return languageContainer[language][page][key] || (fallback || key);
    } else {
        return (fallback || key);
    }
}

function insertTranslations(page, jsonData) {
    languageContainer[jsonData.languageID][page] = [];
    jsonData.translations.forEach(function(tData) {
        languageContainer[jsonData.languageID][page][tData.adID] = tData.translation;
    });
}

module.exports.t = t;
module.exports.setLanguage = setLanguage;
module.exports.getLanguage = getLanguage;
module.exports.ingestTranslations = ingestTranslations;
module.exports.AddLanguageChangeCallback = AddLanguageChangeCallback;
module.exports.IsSupportedLanguage = IsSupportedLanguage;
module.exports.GetSupportedLanguages = GetSupportedLanguages;
module.exports.AddRedirectCallback = AddRedirectCallback;