import React, {useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

export default function QRResultsDisplay(props) {
    const { icon, isLoading } = props;

    //render Icon on top and props.children on bottom
    return (
        <Box sx={{width:'100%', height:'100%', background:'#eeeeee'}}>
            <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>
                <Box>
                    {!isLoading ? icon : <CircularProgress size={50}/>}
                </Box>
                <Box>
                    {props.children}
                </Box>
            </Box>
        </Box>
    );

}