import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';

export default function ReportsEventsDatagrid(props) {
    const { eventData } = props;

    const history = useHistory();

    /*
          { field: 'eventID', headerName: '', width: 100,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              history.push("event/view/" + params.value);
            }}
            style={{ marginLeft: 16 }}
          >
            View
          </Button>
        </strong> )},*/
    const options = {month: 'numeric', day: 'numeric' }
        
    const columns = [

      { field: 'eventName', headerName: 'Event', flex: 1, editable: false },
      { field: 'fName', headerName: 'Leader', flex: 1, editable: false,
        renderCell: (params) => {
        return (<div>{params.row.fName} {params.row.lName}</div>);
        } },
      { field: 'countryID', headerName: 'Location', flex: 1, editable: false },
      { field: 'startingDate', headerName: 'Date', type: 'date', 
        renderCell: (params) => (
          <div>
            {new Date(params.row.startingDate).toLocaleDateString(undefined, options)} - {new Date(params.row.endingDate).toLocaleDateString(undefined, options)}
            </div> ),
      flex: 1, editable: false },
      { field: 'eventStatus', headerName: 'Status', flex: 1, editable: false },
  ];

  const handleRowClick = (params, event) => {
    history.push("event/view/" + params.row.eventID);
  };
  
  return (
    <div>
      <div style={{ height: 350, width: '100%' }}>
        <DataGrid onCellClick={handleRowClick} columns={columns} rows={eventData} />
      </div>
    </div>
  );
}