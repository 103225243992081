import React, { useState, useEffect, useContext } from 'react';
import SophConstants from '../../../constants/SophConstants';
import { AldersgateContext } from '../../../context/aldersgate';
import localizer from '../../../localization/localizer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';

export default function YearEventTypes(props) {
    const { groupID, year } = props;

    const [analyticsData, setAnalyticsData] = useState([]);
    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {
        GetQuarterlyEvents();
    }, [year]);


    const GetQuarterlyEvents = () => {
        if(groupID === undefined || groupID === null)
            return;

        let query = '&groupID=' + groupID;

        if(year !== undefined && year !== null) {
            query += '&year=' + year;
        }

        aldersgateContext.GetFromAPI('stats/year/eventtype', query, 1).then((success, err) => {
            if(success) {
                setAnalyticsData(success);
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/stats/year/eventtype/?api-key=foo' + query, {

    }

    const chartSetting = {
        yAxis: [
          {
            label: 'Events',
          },
        ],

        height: 300,
        margin: { top: 50 },
      };

    const valueFormatter = (value) => `${value} events`;

    if(analyticsData.length === 0)
        return null;
        

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
            <BarChart
                dataset={analyticsData}
                xAxis={[{ scaleType: 'band', dataKey: 'trainingType' }]}
                series={[{ dataKey: 'count', label: 'Events ' + year, valueFormatter }]}
                colors={['#3f51b5']}
                {...chartSetting}
            />
            </Box>
        </Box>
    );
}
