import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';

export default function EventFilterOptions(props) {
    const currentYear = new Date().getFullYear();
    const firstDay = new Date(currentYear, 0, 1);
    const lastDay = new Date(currentYear, 11, 31);

    const { setFilterData, search } = props;
    const [startDate, setStartDate] = React.useState(firstDay);
    const [endDate, setEndDate] = React.useState(lastDay);
    const [eventType, setEventType] = React.useState('All');

    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: 240 }}>
                <TextField id="name-filter" label="Event Name" variant="outlined" size="small"
                    onChange={(newValue) =>{
                            console.log(newValue.target.value);
                            setFilterData(prevState => {
                                return {...prevState, ['name']: newValue.target.value};
                            });
                        }
                    }
                />
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="event-type-filter">Event Type</InputLabel>
                <Select
                labelId="event-type-filter"
                id="event-type-filter-select"
                value={eventType}
                label="Event Type"
                size="small"
                onChange={(newValue) =>{
                        setFilterData(prevState => {
                            return {...prevState, ['eventType']: newValue.target.value};
                        });
                        setEventType(newValue.target.value);
                    }
                }
                >
                <MenuItem value="All">
                    <em>All</em>
                </MenuItem>
                <MenuItem value="Module 1">Module 1</MenuItem>
                <MenuItem value="Module 2">Module 2</MenuItem>
                <MenuItem value="Journey">HMJ</MenuItem>
                <MenuItem value="Flight">Flight School</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 100, height:10 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                    id="start-date-filter-select"
                    label="Start Date"
                    size="small"
                    value={startDate}
                    onChange={(newValue) => {
                        setFilterData(prevState => {
                            return {...prevState, ['startDate']: newValue};
                        });
                        setStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField size="small" {...params} />}
                    />
                </LocalizationProvider>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 100 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                id="end-date-filter-select"
                label="End Date"
                size="small"
                value={endDate}
                onChange={(newValue) => {
                    setFilterData(prevState => {
                        return {...prevState, ['endDate']: newValue};
                    });
                    setEndDate(newValue);
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
                />
            </LocalizationProvider>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Button variant="outlined" size="large" onClick={search}>Search</Button>
            </FormControl>
    </div>
    )
}