import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

export default function TrnaslationInput(props) {
    const { onTranslationChange, translationData } = props;

    return (
        <Grid item sm={12} md={12} lg={12} xl={12}>
            <Box p={2}>
                <Grid container sm={12} md={12} lg={12} xl={12}>
                    <Grid item xs={12}>
                    <Grid container spacing={3}>
                            <Grid item xs={12}  md={6}>
                            <div style={{width:"100%", display:"flex", alignItems:"flex-start"}}>
                            <Typography component="p" variant="p" color="textSecondary" align="left">
                                id: {translationData.adID}
                            </Typography>
                            </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                            <div style={{width:"100%", display:"flex", alignItems:"flex-start"}}>
                            <Typography component="p" variant="p" color="textSecondary" align="left">
                                Translation
                            </Typography>
                            </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}  md={6}>
                                <Paper variant="outlined" style={{height: "100%", margin: "auto"}}>
                                    <div style={{width:"100%", height:"100%", display:"flex", alignItems:"flex-start" }}>
                                        {translationData.original}
                                    </div>
                                </Paper>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <div style={{width:"100%", display:"flex", alignItems:"flex-start"}}>
                                <TextField
                                    id="outlined-multiline-static"
                                    multiline
                                    placeholder="Enter Translation"
                                    defaultValue={(translationData) ? translationData.translation : ""}
                                    onChange={onTranslationChange(translationData.adID)}
                                    sx={{ width: "100%", fontSize:16 }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
}