import React, {useState} from 'react';
import UserLoginForm from './UserLoginForm';
import AuthContext from '../../context/AuthContext';
import { SaveAuthToken } from '../auth/LoadAuthToken';
import { Link, useHistory } from 'react-router-dom';

export default function UserLogin(props) {
    const {redirectPage} = props;
    
    const {accountData, setAccountData} = useState({loggedIn:false, token:null});
    const authContext = React.useContext(AuthContext);
    const history = useHistory();

    const onLogin = (userData) => {
        console.log("UserLogin: " + JSON.stringify(userData));

        if (userData) {
            let updatedData = {...userData};
            SaveAuthToken(updatedData);

            updatedData.loggedIn = true;
            authContext.setAccountState(updatedData);

            if(redirectPage !== undefined || redirectPage !== null) {
                history.push(redirectPage);
            }
            // setAccountData({...updatedData});
        }

        //wait 1 second and redirect
        //console.log("UserLogin: seting timeout");
        //setTimeout(redirect, 1000);

    }

    const redirect = () => {
        console.log("UserLogin: redirecting to dashboard");
        //authContext.setAccountState(accountData);
        //history.push("../dashboard");
        
    }

    return (
        <div>
            <UserLoginForm onLogin={onLogin} />
        </div>
    );
}
