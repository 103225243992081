import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import ReportTabs from './ReportTabs';
import ReportsEventTabView from './ReportsEventTabView';
import ReportsAssignedReportTabView from './ReportsAssignedReportTabView';
import ReportsEventsDatagrid from './ReportsEventsDatagrid';

export default function EventTabView(props) {
    const { filter } = props;

    const [tabName, setTabName] = useState({currentTab: 0});

    function changeTabCallback(newTabName) {
        setTabName({currentTab: newTabName});
    }

    function DispalyTab(value) {
        const { tabValue } = value;

        switch(tabValue) {
            case 0:
                return <ReportsEventTabView filter={filter}/>;
            case 1:
                return <ReportsAssignedReportTabView filter={filter}/>;
            default:
                return <ReportsEventsDatagrid eventData={props.eventData}/>;
        }
    }

    return (
            <Grid container >
                <Grid item xs={12}>
                    <ReportTabs changeTabCallback={changeTabCallback}/>
                </Grid>
                <Grid item xs={12}>
                    <DispalyTab tabValue={tabName.currentTab}/>
                </Grid>
            </Grid>
    );
}