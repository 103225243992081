import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

export default function RegFieldTextInput(props) {
    const { fieldName, size, placeholderText, label, fieldChange, defaultValue } = props.data;
    const { fieldKey } = props;
    const disabled = props.disabled;

    return (
        <Grid key={fieldKey+"-text-input"} item xs={12} sm={12} md={size}>
            <TextField
            id={fieldKey}
            disabled={disabled}
            placeholder={placeholderText}
            label={label}
            onChange={fieldChange(fieldName)}
            defaultValue={defaultValue}
            margin="none"
            size="small"
            fullWidth
            variant="standard"
            />
        </Grid>
    )
}