import React, { useState, useEffect } from 'react';
import CheckInListDataGrid from './CheckInListDataGrid';
import QRCheckIn from './qr/QRCheckIn';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

const modalBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 500,
    bgcolor: 'background.paper',
    border: '2px solid #555',
    boxShadow: 24,
    p: 4,
  };

export default function CheckInOverview(props) {
    const { eventData, isLoading } = props;
    const [modalOpen, setModalOpen] = useState(false);

    const CloseModal = () => {
        setModalOpen(false);
    }

    const OpenModal = () => {
        setModalOpen(true);
    }

    const RenderModal = (modalParams) => {
        const { signupID } = modalParams;

        return (
            <Modal
                open={modalOpen}
                onClose={CloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalBoxStyle}>
                    <QRCheckIn eventSignupID={signupID}/>
                </Box> 
                
            </Modal>
        )
    }

    if(eventData?.report !== undefined && eventData?.report != null) {
        return (
            <Paper elevation={3} sx={{p:2}}>
                <Grid container spacing={2}>
                <Grid item xs={12} mt={2}>
                    <Typography variant="h6">Event Completed</Typography>
                    <Typography variant="body1">Event has been completed.  No further check-ins are allowed.</Typography>
                </Grid>
            </Grid>
        </Paper>
        )
    }

    return (
        <Paper elevation={3} sx={{p:2}}>
            <Grid container spacing={2}>

                <Grid item xs={12} mt={2}>
                    {modalOpen ? <RenderModal signupID={eventData.signupID} /> : <Button variant="contained" onClick={OpenModal} startIcon={<QrCodeScannerIcon />}>Use QR Check In</Button>}
                </Grid>

                <Grid item xs={12}>
                    <CheckInListDataGrid eventData={eventData} />
                </Grid>

            </Grid>
        </Paper>
    );
}
