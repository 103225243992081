import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { deepOrange, deepPurple } from '@mui/material/colors';


export default function MemberBubbles(props) {
    const { groupMembers } = props;

    return (
        <Grid container spacing={0} justify="flex-start">
            <Grid item xs={12}>
                <Box sx={{ width: '100%' }} display="flex" justifyContent="flex-start">
                    <Typography variant="body1" gutterBottom color="textSecondary">Members</Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                    {groupMembers.map((member) => (
                        <Avatar key={member.adID} sx={{ bgcolor: deepOrange[500] }} alt={member.fName + " " + member.lName}>{member.fName.charAt(0) + member.lName.charAt(0)}</Avatar>
                    ))}
                </Stack>
            </Grid>
        </Grid>
    );
}
