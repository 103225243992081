import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function TOSView(props) {

    //default privacy policy for website use
    return (
        <Box>
            <Typography variant="h5" component="h2" color="textSecondary" gutterBottom>Terms of Service</Typography>
            <Typography variant="h5" component="h2" color="textSecondary" align="left" mt={3}>ACCEPTANCE OF TERMS</Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            The following terms and conditions govern all use of the International Leadership Institute website and all content, services, and products available at or through the website. The website is owned and operated by International Leadership Institute. The website is offered subject to your acceptance without modification of all of the terms and conditions contained herein and all other operating rules, policies (including, without limitation, International Leadership Institute's Privacy Policy) and procedures that may be published from time to time on this site by International Leadership Institute (collectively, the "Agreement").
            </Typography>

            <Typography variant="h5" component="h2" color="textSecondary" align="left" mt={3}>1. Introduction</Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            These Terms of Service ("Terms") govern your access to and use of the Training.ILITeam.org website (the "Website") and the services provided by International Leadership Institute ("we," "us," or "our"). By accessing or using the Website, you agree to be bound by these Terms. If you do not agree to all of these Terms, you are not permitted to use the Website.
            </Typography>
            <Typography variant="h5" component="h2" color="textSecondary" align="left" mt={3}>2. User Accounts</Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            2.1 You may be required to create an account ("Account") to access certain features of the Website. You are responsible for maintaining the confidentiality of your Account information, including your password. You are also responsible for all activity that occurs under your Account.
            </Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            2.2 You agree to:
            </Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            <ol>
            <li>Provide true, accurate, current, and complete information about yourself when creating your Account.</li>
            <li>Maintain and update the accuracy of your information.</li>
            <li>Not access or attempt to access another user's Account without authorization.</li>
            </ol>
            </Typography>
            <Typography variant="h5" component="h2" color="textSecondary" align="left" mt={3}>3. User Conduct</Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            3.1 You agree to use the Website only for lawful purposes and in accordance with these Terms.
            </Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            3.2 You agree not to:
            </Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            <ol>
                <li>Use the Website in any way that violates any applicable laws or regulations.</li>
                <li>Infringe on the intellectual property rights of others.</li>
                <li>Send unsolicited commercial email (“spam”).</li>
                <li>Damage, disable, or overburden the Website.</li>
                <li>Collect or store personal information about other users without their express consent.</li>
                <li>Attempt to gain unauthorized access to the Website or any systems, networks, or servers connected to the Website.</li>
            </ol>
            </Typography>
            <Typography variant="h5" component="h2" color="textSecondary" align="left" mt={3}>4. Data Privacy</Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            4.1 We are committed to protecting the privacy of our users. We collect, use, and disclose information in accordance with our Privacy Policy, which is available on the Website Training.ILITeam.org/privacy.
            </Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            4.2 You agree that we may access, use, and disclose your information to the extent necessary to:
            </Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            <ol>
                <li>Provide the services offered by the Website.</li>
                <li>Comply with the law.</li>
                <li>Protect the rights and safety of ourselves and others.</li>
            </ol>
            </Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            4.3 You further agree that we will destroy any downloaded information collected through event sign-up forms as soon as the event is completed, unless otherwise required by law or with your explicit consent.
            </Typography>
            <Typography variant="h5" component="h2" color="textSecondary" align="left" mt={3}>5. Disclaimer of Warranties</Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            THE WEBSITE AND ALL SERVICES PROVIDED THEREON ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR COURSE OF DEALING. WE DO NOT WARRANT THAT THE WEBSITE WILL BE UNINTERRUPTED, ERROR-FREE, OR VIRUS-FREE.
            </Typography>
            <Typography variant="h5" component="h2" color="textSecondary" align="left" mt={3}>6. Limitation of Liability</Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            WE SHALL NOT BE LIABLE FOR ANY DAMAGES ARISING OUT OF OR RELATED TO YOUR USE OF THE WEBSITE, INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </Typography>
            <Typography variant="h5" component="h2" color="textSecondary" align="left" mt={3}>7. Termination</Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            We may terminate your access to the Website for any reason, at any time, without notice.
            </Typography>
            <Typography variant="h5" component="h2" color="textSecondary" align="left" mt={3}>8. Governing Law and Jurisdiction</Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            These Terms shall be governed by and construed in accordance with the laws of the State of Georgia, without regard to its conflict of laws provisions. Any dispute arising out of or relating to these Terms shall be subject to the exclusive jurisdiction of the courts located in Georgia.
            </Typography>
            <Typography variant="h5" component="h2" color="textSecondary" align="left" mt={3}>9. Entire Agreement</Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            These Terms constitute the entire agreement between you and us regarding your use of the Website.
            </Typography>
            <Typography variant="h5" component="h2" color="textSecondary" align="left" mt={3}>10. Changes to these Terms</Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            We may revise these Terms at any time by posting the revised Terms on the Website. The revised Terms will be effective immediately upon posting. Your continued use of the Website following the posting of revised Terms constitutes your acceptance of the revised Terms.
            </Typography>
            <Typography variant="h5" component="h2" color="textSecondary" align="left" mt={3}>11. Contact Us</Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            If you have any questions about these Terms, please contact us at ILITeam@ILITeam.org.
            </Typography>

            <Typography variant="p" component="p" color="textSecondary" align="left" mt={1}>
                Terms of Service last updated on March 1, 2024.
            </Typography>
        </Box>
    );

}