import React, { useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../context/aldersgate';
import RegisterProcess from '../components/EventRegistration/RegisterProcess';
import UserRegistration from '../components/user/UserRegistration';
import RegHeader from '../components/EventRegistration/RegHeader';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}


export default function ParticipantRegistration(props) {

    const [eventData, setEventData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [showEventError, setShowEventError] = useState(false);
    const [userRegistration, setUserRegistration] = useState({open:false, fName:null, lName:null, email:null, phone:null, countryID:null, state:null, city:null});
    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {
        if(props.match.params && props.match.params.id) {

            aldersgateContext.GetFromAPI('event/reg/' + props.match.params.id).then((success, err) => {

                if(success) {
                    setEventData(success);
                    setIsLoading(false);
                    setShowEventError(false);
                }

                if(err) {
                    console.log("Error: " + err);
                    setShowEventError(true);
                }

            }).catch((error) => {
                console.log("Error: " + error);
                setShowEventError(true);
            });
        }
    }, []);

    const RenderParticipantRegistration = () => {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <RegHeader isLoaded={!isLoading} eventData={eventData} defautWhite={0} />
                </Grid>
                <Grid item xs={12}  md={12}>
                    <RegisterProcess isLoading={isLoading} eventData={eventData} userRegistration={setUserRegistration}/>
                </Grid>
            </Grid>
        )
    }

    const RenderUserRegistration = () => {
        return (
            <UserRegistration userRegistrationData={userRegistration} />
        )
    }

    if(showEventError) {
        return (
            <Box m={1.5} bgcolor="background.paper" >
                <Grid container >
                    <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                        
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                        <Box m={2}>
                            <Typography variant="h4" color="error">Error: Event not found</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }


    return (
        <Box m={1.5} bgcolor="background.paper" >
        <Grid container >
            <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                {userRegistration.open ? <RenderUserRegistration /> : <RenderParticipantRegistration />}
            </Grid>
        </Grid>
    </Box>
    );
}