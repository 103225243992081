import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Title from './../Title';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CenteredLoading from './../CenteredLoading';
import localizer from '../../localization/localizer';

function preventDefault(event) {
  event.preventDefault();
}

export default function TotalTrainedCard(props) {

  return (
    <React.Fragment>
        <Paper>
            <Title>{localizer.t("dashboard", "Global Trained")}</Title>
            {(!props.totalCount) ? (
                <CenteredLoading />
            ) : (
            <Grid container sx={{height:240}}>
                <Grid item xs={12}>
                    <Grid container spacing={0}  sx={{height:200, display:'flex', flexDirection:'column', justifyContent:'center'}}>
                        <Grid item>
                            <Typography component="p" variant="h3" color="primary">
                                {(props.totalCount) ? (props.totalCount) : (0)}
                            </Typography>
                            <Typography color="textSecondary">
                            {localizer.t("dashboard", "leaders trained")} 
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box alignSelf="flex-end">
                      <Link color="primary" href="#" onClick={preventDefault}>
                      {localizer.t("dashboard", "view more")}
                      </Link>
                    </Box>
                </Grid>
            </Grid>
            )}

        </Paper>
    </React.Fragment>
  );
}

//leaders-trained-title-lower