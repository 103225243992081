import React, {useEffect, useState, useContext} from  'react';
import { Link, useHistory } from 'react-router-dom';
import { AldersgateContext } from '../../../context/aldersgate';
import ReportsFilterPeople from './ReportsFilterPeople';
import ReportsLeaderDatagrid from './ReportsLeaderDatagrid';
import ReportTabs from './ReportTabs';
import ReportsTabView from './ReportsTabView';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ReportAssignForm from './ReportAssignForm';
//import SimplePieChart from '../SimplePieChart';
import Typography from '@mui/material/Typography';


function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default function ReportsView(props) {
    const currentYear = new Date().getFullYear();
    const firstDay = new Date(currentYear, 0, 1);
    const lastDay = new Date(currentYear, 11, 31);

    const [value, setValue] = useState(0);
    const [filterData, setFilterData] = useState({ name: ''});
    const [groupData, setGroupData] = useState([]);
    const [assignOpen, setAssignOpen] = useState(false);
    const aldersgateContext = useContext(AldersgateContext);
    
    useEffect(() => {
        SearchWithFilter();

    }, [value]);

    function ProcessGroups(reportAssignments) {
        let reportList = [...reportAssignments]
        
        for(let i = 0; i < reportList.length; i++) {

            if(reportList[i].adID === null) {
                reportList[i].id = i;
            } else {
                reportList[i].id = reportList[i].adID;
            }

            reportList[i].reportStatus = GetStatusLanguage(reportList[i].status);
        }
        


        return reportList;
    }

    function GetStatusLanguage(status) {
        let statusText = '';
        switch(status) {
            case 0:
                statusText = 'Assigned';
                break;
            case 1:
                statusText = 'Submitted';
                break;
            case 2:
                statusText = 'Approved';
                break;
            default:
                statusText = 'Unknown';
                break;
        }
        return statusText;
    }

    function HandleFilterOnChange(parameter, filterData) {
        setFilterData(prevState => {
            return {...prevState, [parameter]: filterData};
        });
    }

    function SearchWithFilter() {

    }

    function GetPeopleList() {
        let query = '';
        if(filterData.name !== '')
            query += '&name=' + filterData.name;

        //console.log(query);

        aldersgateContext.GetFromAPI('reports/getlist/leaders/english-africa', query, 1).then((success, err) => {

            if(success) {
                setGroupData(ProcessGroups(success));
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/reports/getlist/leaders/english-africa?api-key=foo' + query, {

    }

    function GetEventsList() {
        let query = '';
        if(filterData.name !== '')
            query += '&name=' + filterData.name;

        //console.log(query);

        aldersgateContext.GetFromAPI('reports/getlist/leaders/english-africa', query, 1).then((success, err) => {
            if(success) {
                setGroupData(ProcessGroups(success));
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/reports/getlist/leaders/english-africa?api-key=foo' + query, {
    }

    function ShowAssignForm(showProps) {
        const {isVisible} = showProps;

        if(isVisible) {
            return(
                <Grid item xs={12}>
                    <ReportAssignForm formIsOpen={setAssignOpen}/>
                    <Box sx={{width:"100%", height:50, mt: 1}}></Box>
                </Grid>
            );
        } else {
            return (
                <Grid item xs={12}>
                    <Grid container justify="flex-start">
                        <Grid item>
                            <Button onClick={()=>{setAssignOpen(true)}} size="medium" variant="contained" color="primary" startIcon={<AddIcon />}>Assign Report</Button>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
    }

    const eventTypeData = [
        { name: 'Module 1', value: 103 },
        { name: 'Journey', value: 54 },
        { name: 'Module 2', value: 36 },
        { name: 'Flight School', value: 15 },
      ];

      const eventMediumData = [
        { name: 'In Person', value: 203 },
        { name: 'Hybrid', value: 101 },
        { name: 'Online', value: 46 },
        { name: 'On Demand', value: 25 },
      ];

      const completedReportingData = [
        { name: 'Completed', value: 8 },
        { name: 'Recieved', value: 23 },
        { name: 'Waiting', value: 5 },
      ];

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ReportsFilterPeople search={SearchWithFilter} setFilterData={setFilterData}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ReportsTabView filter={filterData}/>
                    </Grid>
                </Grid>

                <ShowAssignForm isVisible={assignOpen}/>
            </Grid>
        </div>
    );
}