import React, {useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssessmentIcon from '@mui/icons-material/Assessment';


export default function CreateRegionPost(props) {
    const {groupID } = props;

    const [postData, setPostData] = useState({title:"", content:"", groupID:groupID, public:false});
    const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
    const [cancelBtnDisabled, setCancelBtnDisabled] = useState(true);

    const handleTextChange = (event) => {
        let value = {[event.target.id]: event.target.value};
        setPostData(prevState => {
            let newState = {...prevState};
            newState[event.target.id] = event.target.value;
            return {...newState};
        });

        //if the cancel button is disabled, enable it
        if(cancelBtnDisabled)
            setCancelBtnDisabled(false);
    }

    useEffect(() => {
        if(postData.title && postData.content)
            setSubmitBtnDisabled(false);
        else
            setSubmitBtnDisabled(true);
    }, [postData]);

    const handleSubmit = () => {
    }

    const handleCancel = () => {
        setPostData({title:"", content:"", groupID:groupID, public:false});
        setCancelBtnDisabled(true);
    }

    return (
        <Paper sx={{padding:2}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h6" component="div">
                        Create a Post
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        id="title"
                        label="Title"
                        onChange={handleTextChange}
                        value={postData.title}
                        margin="normal"
                        fullWidth
                        variant='outlined'
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={6} sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start'}}>
                    <FormControlLabel control={<Checkbox defaultChecked />} label="Public post" />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        id="content"
                        label="Content"
                        onChange={handleTextChange}
                        value={postData.content}
                        margin="normal"
                        fullWidth
                        variant='outlined'
                        multiline
                        rows={4}
                    />
                </Grid>



                <Grid item xs={12} sm={12} md={12} sx={{display:'flex', justifyContent:'center', gap:2}}>
                    <Button variant="contained" onClick={handleCancel} disabled={cancelBtnDisabled} color='error'>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleSubmit} disabled={submitBtnDisabled}>
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
}