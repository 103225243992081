import React, {useEffect, useState, useContext} from  'react';
import { AldersgateContext } from '../../../context/aldersgate';
import localizer from '../../../localization/localizer';
import EventsCount from './EventsCount';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DEventList from './DEventList/DEventList';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import AlumniCount from './AlumniCount';


export default function RegionOverview(props) {

    const{groupIDs} = props;

    const [eventData, setEventData] = useState([]);
    const [statsData, setStatsData] = useState({});
    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {
        GetEventList();
    }, [groupIDs]);

    const GetEventList = () => {
        console.log('GetEventList: ', groupIDs);

        if(groupIDs === undefined || groupIDs === null || groupIDs.length === 0)
            return;

        let query = '';
        groupIDs.forEach(groupID => {
            query += 'groupIDs=' + groupID + '&';
        });

        query = 'orderByDateAdded=true&queryLimit=10';

        let groupList = groupIDs;
        //groupList.push(groupID);

        aldersgateContext.GetFromAPI('event/getlist/eventquery/s', query, 1).then((success, err) => {
            if(success) {
                setEventData(ProcessEvents(success));
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        GetGroupStats();

        //fetch(SophConstants.API_ADDR + '/api/event/getlist/eventquery/s?api-key=foo' + query, {

    }

    const GetGroupStats = () => {

        let query = '';
        groupIDs.forEach(groupID => {
            query += 'groupIDs=' + groupID + '&';
        });

        aldersgateContext.GetFromAPI('stats/group', query, 1).then((success, err) => {
            if(success) {
                console.log('GetGroupStats: ', JSON.stringify(success, null, 2));
                setStatsData(success);
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });
    }

    function ProcessEvents(events) {
        if(events === undefined || events === null)
            return;

        let eventList = [];
        events.forEach(event => {
            let participants = 0;
            if(event.participants !== undefined && event.participants !== null)
                participants = event.participants.length;

            if(event.report !== undefined && event.report !== null) {
                let reportCount = parseInt(event.report.completedParticipants) || 0;
                if(reportCount > participants)
                    participants = reportCount;
            }

            eventList.push({
                id: event.eventID,
                eventName: event.eventName,
                eventType: event.eventType,
                eventMedium: event.eventMedium,
                startingDate: event.startingDate,
                endingDate: event.endingDate,
                eventStatus: event.eventStatus,
                city: event.city,
                countryID: event.countryID,
                participantCount: participants
            });
        });

        return eventList;
    }

    //first day this year
    const firstDay = new Date(new Date().getFullYear(), 0, 1);
    //first day 5 years ago
    const firstDay5YearsAgo = new Date(new Date().getFullYear(), 0, 1);

    //last day this year
    const lastDay = new Date(new Date().getFullYear(), 11, 31);

    return (
        <Box sx={{display:'flex', flexDirection:'column'}}>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <Paper elevation={2}>
                            <EventsCount data={statsData} dataKey='totalEventDateRangeCount' groupID={null} headerText='Registered Events' startingDate={firstDay5YearsAgo} endingDate={lastDay} footerText='This Year'/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Paper elevation={2}>
                        <AlumniCount data={statsData} dataKey='trainedDateRangeCount' groupID={null} headerText='Leaders Trained' startingDate={firstDay5YearsAgo} endingDate={lastDay} footerText='This Year' />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Paper elevation={2}>
                            <EventsCount data={statsData} dataKey='totalEventCount' groupID={null}headerText='Registered Events' footerText='All Time'/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Paper elevation={2}>
                        <AlumniCount data={statsData} dataKey='totalTrained' groupID={null} headerText='Leaders Trained' footerText='All Time'/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Paper>
                            <Typography variant="h6">New Events</Typography>
                            <DEventList eventListData={eventData} />
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}