import React, {useEffect, useState, useContext} from  'react';
import AuthContext from '../../context/AuthContext';
import ViewUserEvents from './ViewUserEvents';
import GroupEvents from './GroupEvents';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';



function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default function EventsView(props) {

    const [value, setValue] = useState(0);
    const authContext = useContext(AuthContext);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const RenderTabContent = () => {
        switch(value) {
            case 0:
                return <ViewUserEvents />;
            case 1:
                return <GroupEvents />;
            default:
                return <ViewUserEvents />;
        }
    }
  
    return (
        <Box sx={{display:'flex', flexDirection:'column'}}>
            <Box>
                <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="secondary"
                textColor="secondary"
                aria-label="icon label tabs example"
                >
                    {authContext?.accountState?.authLevel > 2 ?
                    <>
                    <Tab icon={<CalendarMonthIcon aria-label="My Events" />} label="My Events" />
                    <Tab icon={<TravelExploreIcon aria-label="My Region" />} label="My Region" />
                    </>
                    : null }
                </Tabs>
            </Box>
            <Box mt={2}>
                <RenderTabContent />
            </Box>
        </Box>
    );
}