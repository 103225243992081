import React, {useEffect, useState, useContext} from  'react';
import { Link, useHistory } from 'react-router-dom';
import { AldersgateContext } from '../../../context/aldersgate';
import RegisteredEventInfo from './RegisteredEventInfo';
import AuthContext from '../../../context/AuthContext';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


export default function ViewRegisteredEvents(props) {

    const [eventData, setEventData] = useState([]);

    const aldersgateContext = useContext(AldersgateContext);
    const authContext = useContext(AuthContext);

    useEffect(() => {
            
            aldersgateContext.GetFromAPI('user/' + authContext?.accountState?.token + '/participant/events').then((success, err) => {
    
                if(success) {
                    setEventData(success);
                }
        
                if(err) {
                    console.log("Error: " + err);
                }
        
            }).catch((error) => {
                console.log("Error: " + error);
            });
    
    }, [authContext]);
    

    return (

        <Grid container>
            <Grid item xs={12}>
                {eventData?.map((event, index) => (
                    <RegisteredEventInfo key={index} eventData={event} />
                ))}
            </Grid>
        </Grid>

    );

}