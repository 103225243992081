import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export default function DSessionsListRow(props) {
    const { rowItemData } = props;
    const [buttonEnabled, setButtonEnabled ] = useState(true);
    const [submitStatus, setSubmitting] = useState({status:0});

    const history = useHistory();

    useEffect(() => {
        setButtonEnabled(rowItemData.createSFButtonEnabled);
    }, [rowItemData]);

    useEffect(() => {

        if(rowItemData.salesforceID !== undefined && rowItemData.salesforceID !== null) {
            setSubmitting({status:2});
        } else {
            if(submitStatus.status !== 1)
                setSubmitting({status:0});
        }
        
    }, [rowItemData]);

    const options = {month: 'numeric', day: 'numeric' }
    let bgColor = '#FFFFFF';
    if(rowItemData !== undefined && rowItemData.elementID !== undefined) {
        bgColor = (rowItemData.elementID % 2 === 0) ? '#F5F5F5' : '#FFFFFF';
    }

    let fontSizeTitle = 14;
    let fontSizeSubTitle = 12;

    function LocalizedDateString(date) {
        var userLang = navigator.language || navigator.userLanguage;
        let dateToPrint = new Date(date);
        let dateString = dateToPrint.toLocaleDateString(userLang);
        let timeString = dateToPrint.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
        let dateTimeString = dateString + ' ' + timeString;

        return (dateTimeString);
    }

    return (
        <Box onClick={() => rowItemData.onRowClick(rowItemData)}>
            <Grid container p={1} sx={{backgroundColor:bgColor,':hover': {backgroundColor:'#E0E0E0'}}} >
                <Grid item xs={4} sm={4}>
                    <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                        <Box>
                            <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                <Box sx={{  fontSize: fontSizeTitle, textAlign:'left' }}>
                                    {rowItemData.name}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                     
                <Grid item xs={4} sm={3} sx={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center'}}>
                    <Box sx={{ fontSize: fontSizeTitle }}>
                        {LocalizedDateString(rowItemData.sessionStart)}
                    </Box>
                </Grid>

                <Grid item xs={4} sm={1} sx={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center'}}>
                    <Box sx={{ fontSize: fontSizeTitle }}>
                        {rowItemData.sessionLength + ' minutes'}
                    </Box>
                </Grid>

                <Grid item xs={4} sm={4} sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                    <Box sx={{  width:'100%', fontSize: fontSizeTitle, display:'flex', justifyContent:'flex-end'}}>
                        {rowItemData?.facilitator?.fName + ' ' + rowItemData?.facilitator?.lName}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}