import React from 'react';
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function Home(props) {

  return (
    <Grid container mt={4}>
      <Grid item xs={0} sm={1} md={2}></Grid>

      <Grid item xs={12} sm={10} md={8}>
        <Grid container spacing={2}>
          <Grid item xs={12} m={4}>
            <Typography variant="h4" component="h1" color="textSecondary" gutterBottom>Looking for online leadership training?</Typography>
            <Typography variant="p" component="p" color="textSecondary">Click here: <a href="https://connect.iliteam.org" rel="noreferrer">https://Connect.ILITeam.org</a> to join ILI's online leader community and gain access to online leadership training and articles.</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{minHeight: 200, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
              <Typography variant="h5" component="h2" color="textSecondary" gutterBottom>Welcome to the Aldersgate leadership development system</Typography>
              <Typography variant="p" component="p" color="textSecondary">This system is currently in beta</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{minHeight: 200, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
              <Typography variant="h5" component="h2" color="textSecondary" gutterBottom>What is Aldersgate?</Typography>
              <Typography variant="p" component="p" color="textSecondary">Aldersgate is a system for managing ILI events and participants, accessing ILI resources, and administrating mobilized teams</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{minHeight: 200, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
              <Typography variant="h5" component="h2" color="textSecondary" gutterBottom>How do I use Aldersgate?</Typography>
              <Typography variant="p" component="p" color="textSecondary">Aldersgate is currently in beta, and is not available for public use</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{minHeight: 200, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
              <Typography variant="h5" component="h2" color="textSecondary" gutterBottom>How do I get access to Aldersgate?</Typography>
              <Typography variant="p" component="p" color="textSecondary">Aldersgate is currently in beta, and is not available for public use</Typography>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={0} sm={1} md={2}></Grid>
    </Grid>
  );
}
