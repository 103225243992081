import React, {useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../../context/aldersgate';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

export default function ReportAssignForm(props) {
    const { formIsOpen } = props;

    const [ assignmentData, setAssignmentData ] = useState({fName:'', lName:'', email:'', quarter:2, year:2022, pword:''});
    const [canSubmit, setCanSubmit] = useState(true);
    const aldersgateContext = useContext(AldersgateContext);

    function handleSubmit(event) {
        setCanSubmit(false);
        event.preventDefault();

        let submitData = {...assignmentData};
        submitData.assignedBy = "test12345";
        if(submitData.pword === '')
            submitData.pword = undefined;

            aldersgateContext.PostToAPI('reports/addassignment', submitData, null, 1).then((success, err) => {

                if(success) {
                    //reset form
                    setAssignmentData({fName:'', lName:'', email:'', quarter:2, year:2022, pword:''});
                    setCanSubmit(true);

                    //close the form
                    if(formIsOpen)
                        formIsOpen();
                }
        
                if(err) {
                    console.log("Error: " + err);
                }
        
            }).catch((error) => {
                console.log("Error: " + error);
                setCanSubmit(true);
            });

        //fetch(SophConstants.API_ADDR + '/api/reports/addassignment?api-key=foo', {

    }

    function handleCancel(event) {
        event.preventDefault();

        if(formIsOpen)
            formIsOpen();
    }

    function RenderSubmitBTN() {
        if(canSubmit) {
            return(
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            );
        } else {
            return(
                <LoadingButton variant="contained" color="primary">
                    Submit
                </LoadingButton>
            );
        }
    }
    

    return (
        <Paper elevation={2} style={{padding: '1em'}}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="body1" gutterBottom color="textSecondary">
                    Assign a report to an ILI leader.
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl sx={{ m: 0, minWidth: 120, width: '100%' }}>
                    <TextField
                        id="fname"
                        name='fName'
                        label="First Name"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={ (assignmentData ? assignmentData.fName : '') }
                        onChange={(newValue) =>{
                            setAssignmentData(prevState => {
                                return {...prevState, ['fName']: newValue.target.value};
                            });
                        }
                    }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl sx={{ m: 0, minWidth: 120, width: '100%' }}>
                    <TextField
                        id="lName"
                        name='LName'
                        label="Last Name"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={ (assignmentData ? assignmentData.lName : '')}
                        onChange={(newValue) =>{
                            setAssignmentData(prevState => {
                                return {...prevState, ['lName']: newValue.target.value};
                            });
                        }
                    }
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
                <FormControl sx={{ m: 0, minWidth: 120, width: '100%' }}>
                    <TextField
                        id="email"
                        name='email'
                        label="Email"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={ (assignmentData ? assignmentData.email : '')}
                        onChange={(newValue) =>{
                            setAssignmentData(prevState => {
                                return {...prevState, ['email']: newValue.target.value};
                            });
                        }
                    }
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
                
            </Grid>

            <Grid item xs={12} md={6}>
                <FormControl sx={{ m: 0, minWidth: 120, width: '100%' }}>
                    <InputLabel id="group-type-filter">Quarter</InputLabel>
                    <Select
                    labelId="quarter-filter"
                    id="quarter-filter-select"
                    value={ (assignmentData ? assignmentData.quarter : 'Q1')}
                    label="Quarter"
                    size="small"
                    fullWidth
                    onChange={(newValue) =>{
                            setAssignmentData(prevState => {
                                return {...prevState, ['quarter']: newValue.target.value};
                            });
                        }
                    }
                    >
                    <MenuItem value="1">Q1</MenuItem>
                    <MenuItem value="2">Q2</MenuItem>
                    <MenuItem value="3">Q3</MenuItem>
                    <MenuItem value="4">Q4</MenuItem>
                    <MenuItem value="5">Year Report</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            
            <Grid item xs={12} md={6}>
                <FormControl sx={{ m: 0, minWidth: 120, width: '100%' }}>
                    <InputLabel id="group-type-filter">Year</InputLabel>
                    <Select
                    labelId="year-filter"
                    id="year-filter-select"
                    value={ (assignmentData ? assignmentData.year : '2022')}
                    label="Year"
                    size="small"
                    fullWidth
                    onChange={(newValue) =>{
                        setAssignmentData(prevState => {
                                return {...prevState, ['year']: newValue.target.value};
                            });
                        }
                    }
                    >
                    <MenuItem value="2022">2022</MenuItem>
                    <MenuItem value="2021">2021</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
                
            </Grid>


            <Grid item xs={12}>
                <FormControl sx={{ m: 0, minWidth: 120, width: '100%' }}>
                    <TextField
                        id="pword"
                        name='password'
                        label="Report Password (not required)"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={ (assignmentData ? assignmentData.pword : '')}
                        onChange={(newValue) =>{
                            setAssignmentData(prevState => {
                                return {...prevState, ['pword']: newValue.target.value};
                            });
                        }
                    }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Box sx={{p:1}}>
                    <Button variant="contained" color="error" onClick={handleCancel}>
                        Cancel
                    </Button>
                </Box>
                <Box sx={{p:1}}>
                    {RenderSubmitBTN()}
                </Box>
                </Box>
            </Grid>
        </Grid>
        </Paper>
    );
}