import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

export default function CreateEventForm1(props) {
    const { values, handleChange } = props;

    return (
      <Grid container>
          <Grid item xs={12}>
          <TextField
            placeholder="Event Name"
            label="Event Name"
            onChange={handleChange('eventName')}
            defaultValue={values.eventName}
            margin="normal"
            fullWidth
            variant='standard'
          />
          </Grid>
      </Grid>
    )
}
