import React, {useContext} from 'react';
import bcrypt from 'bcryptjs';
import RequestReset from './RequestReset';
import SophConstants from '../../constants/SophConstants';
import { AldersgateContext } from '../../context/aldersgate';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

import CircularProgress from '@mui/material/CircularProgress';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export default function UserLoginForm(props) {
     const [formData, setFormData] = React.useState({
        email: '',
        password: '',
        showPassword: false,
    });

    const [loading, setLoading] = React.useState(false);

    const [formDisabled, setFormDisabled] = React.useState(false);
    const [submitting, setSubmitting] = React.useState({status:0});
    const [alertData, setAlertData] = React.useState({open:false, message:"", severity:"success"});
    const [tryCount, setTryCount] = React.useState(0);
    const [showReset, setShowReset] = React.useState(false);

    const aldersgateContext = useContext(AldersgateContext);

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleChange = prop => event => {
        setFormData({ ...formData, [prop]: event.target.value });
    }

    const handleClickShowPassword = () => {
        setFormData({ ...formData, showPassword: !formData.showPassword });
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        setFormDisabled(true);

        var hash = bcrypt.hashSync(formData.password, SophConstants.slt);

        aldersgateContext.PostToAPI('user/login', {email: formData.email, pword: hash}).then((success, err) => {
            if(success) {
                let newState = {open:true, message:"Sign in successful", severity:"success"}

                setAlertData(newState);
                setLoading(false);
                
                if(props.onLogin !== undefined && props.onLogin !== null) {
                    props.onLogin(success);
                    return;
                }
            }
    
            if(err) {
                console.log("Error: " + err);

                let newState = {open:true, message:"Invalid username or password", severity:"error"}
                setAlertData(newState);
                setFormDisabled(false);
                setLoading(false);
                setTryCount(prevState => {
                    return prevState + 1;
                });
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
            let newState = {open:true, message:"Invalid username or password", severity:"error"}
            setFormDisabled(false);
            setAlertData(newState);
            setLoading(false);
        });

        // fetch(SophConstants
        //     .API_ADDR + '/api/user/login?api-key=foo', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({email: formData.email, pword: formData.password})
        // }).then((res) => {
        //     setLoading(false);
        //     console.log("res: " + res);
        //     if (res.status === 200) {
        //         return res.json();
        //     } else {
        //         let newState = {open:true, message:"Invalid username or password", severity:"error"}
        //         setAlertData(newState);
        //         setFormDisabled(false);
        //         return null;
        //     }
        // }
        // ).then((jsonData, status) => {
        //     console.log("jsonData: " + JSON.stringify(jsonData, null, 4));
        //     console.log("status: " + status);
        //         if(jsonData) {
        //             let newState = {open:true, message:"Sign in successful", severity:"success"}
        //             setAlertData(newState);
        //             props.onLogin(jsonData);
        //         } else {
        //             let newState = {open:true, message:"Invalid username or password", severity:"error"}
        //             setFormDisabled(false);
        //             setAlertData(newState);
        //         }
            
        // }).catch((err) => {
        //     //show error
        //     setLoading(false);
        // });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlertData(prevState => {
            let newState = {...prevState};
            newState.open = false;
            return {...newState};
        });
    };

    function wait(delay){
        return new Promise((resolve) => setTimeout(resolve, delay));
    }

    function SubmitButtonIcon(props) {
        switch (props.submitStatus) {
            case 0:
                return (<SendIcon />); 
            case 1:
                return (<CircularProgress size={20}/>);
            case 2:
                wait(500).then(() => setSubmitting({status:3}));
                return (<CheckIcon />);
            default:
                return (<CheckIcon />);
        }
    }

    const handleReset = () => {
        setShowReset(true);
    }

    if(showReset) {
        return (
            <RequestReset onClose={() => setShowReset(false)} />
        );
    }

    return (
        <div>
            <Grid container >
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={1} sm={1} md={2} lg={2} xl={3}>
                        </Grid>
                        <Grid item xs={10} sm={10} md={8} lg={8} xl={6} mt={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography component="h2" variant="h5" color="textPrimary" align="left">
                                        Sign In
                                    </Typography>
                                    <Typography component="p" variant="p" color="textSecondary" align="left">
                                        Enter your details below
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField id="email" label="Email" defaultValue="" variant="outlined" size="small" disabled={formDisabled} onChange={handleChange('email')} fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                <Box sx={{mb:1}} htmlFor="outlined-adornment-password" >
                                    <InputLabel>Password</InputLabel>
                                </Box>
                                    <OutlinedInput
                                        fullWidth
                                        size="small"
                                        id="outlined-adornment-password"
                                        type={formData.showPassword ? 'text' : 'password'}
                                        value={formData.password}
                                        disabled={formDisabled}
                                        onChange={handleChange('password')}
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            >
                                            {formData.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography component="p" variant="p" color="textSecondary" align="left">
                                        By signing in, I agree to ILI's terms and conditions and privacy policy
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={handleSubmit}
                                    
                                    disabled={formDisabled}
                                    endIcon={<SubmitButtonIcon submitStatus={submitting.status}/>}>
                                        Sign In
                                </Button>
                                    
                                </Grid>


                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography component="p" variant="p" color="textSecondary" align="left">
                                        Having trouble signing in? <Link onClick={handleReset}>Reset your password.</Link>
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={1} sm={1} md={2} lg={2} xl={3}>
                        </Grid>
                    </Grid>
                    <Snackbar open={alertData.open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={alertData.severity} sx={{ width: '100%' }}>
                            {alertData.message}
                        </Alert>
                    </Snackbar>
                </Grid>
            </Grid>
        </div>
    );



}