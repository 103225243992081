import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';

export default function EventsViewDatagrid(props) {
    const { eventData } = props;

    const history = useHistory();

    const columns = [
      // { field: 'id', headerName: '', width: 100,
      // renderCell: (params) => (
      //   <strong>
      //     <Button
      //       variant="contained"
      //       color="primary"
      //       size="small"
      //       onClick={() => {
      //         history.push("event/view/" + params.value);
      //       }}
      //       style={{ marginLeft: 16 }}
      //     >
      //       View
      //     </Button>
      //   </strong> )},
      { field: 'eventName', headerName: 'Event', flex: 1, editable: false },
      { field: 'eventType', headerName: 'Type', flex: 1, editable: false },
      { field: 'eventMedium', headerName: 'Medium', flex: 1, editable: false },
      { field: 'startingDate', headerName: 'Start', type: 'date', valueGetter: ({ value }) => value && new Date(value),
      flex: 1, editable: false },
      { field: 'endingDate', headerName: 'End', type: 'date', valueGetter: ({ value }) => value && new Date(value),
      flex: 1, editable: false },
      { field: 'eventStatus', headerName: 'Status', flex: 1, editable: false }
  ];

  const handleRowClick = (params, event) => {
    //console.log(JSON.stringify(params, null, 2));
    history.push("/event/view/" + params.row.id);
  };
  
  return (
    <div>
      <div style={{ height: 350, width: '100%' }}>
      <DataGrid onRowClick={handleRowClick} columns={columns} rows={eventData} />
      </div>
    </div>
  );
}