import React, {useEffect, useState, useContext} from 'react';
import { AldersgateContext } from '../../../context/aldersgate';
import ReportsAssignedReportsDatagrid from './ReportsAssignedReportsDatagrid';

function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default function ReportsAssignedReportTabView(props) {
    const { filter } = props;

    const [refreshData, setRefreshData] = useState(1);
    const [assignedReportData, setAssignedReportData] = useState([]);
    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {
        GetEventsList();
    }, [refreshData]);

    function refreshDataCallback() {
        setRefreshData(refreshData + 1);
    }

    //get event data from server
    function GetEventsList() {
        let query = '';

        //console.log(query);

        aldersgateContext.GetFromAPI('reports/getlist/reports/test12345', query, 1).then((success, err) => {
            if(success) {
                setAssignedReportData(success);
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/reports/getlist/reports/test12345?api-key=foo' + query, {
    }


    return (
        <div>
            <ReportsAssignedReportsDatagrid assignedReports={assignedReportData}/>
        </div>
    );
}