import React, {useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AldersgateContext } from '../../context/aldersgate';
import Box  from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import IconedTextButton from '../IconedTextButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import NativeSelect from '@mui/material/NativeSelect';
import AddIcon from '@mui/icons-material/Add';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CountrySelect from '../CountrySelect';


function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

const style = {
    bgcolor: 'background.paper',
    border: '2px solid #444',
    p: 4,
    m:2,
  };

export default function AddParticipant(props) {
    const {closeForm, updateInformation, eventID } = props;
    const {updateData, setSnackbarMessage} = updateInformation;

    const [changedData, setChangedData] = useState({
        "fName": "",
        "lName": "",
        "email": "",
        "phoneNumber": "",
        "city": "",
        "state": "",
        "countryID": "",
    });
    const [value, setValue] = useState(0);

    const aldersgateContext = useContext(AldersgateContext);

    const handleCloseForm = () => {
        closeForm(true);
    }
    
    const handleTextChange = (event) => {
      let value = {[event.target.id]: event.target.value};
    
      setChangedData(prevState => {
          let newState = {...prevState};
          newState[event.target.id] = event.target.value;
          return {...newState};
      });
    }

    const handleCountryChange = (event, values) => {
        let code = "";
        if(values && values.code)
          code = values.code;
    
          setChangedData(prevState => {
            let newState = {...prevState};
            newState.countryID = code;
            return {...newState};
        });
      };
    
    const handleSubmit = () => {
      let editedData = {...changedData};
      editedData.id = undefined;

        aldersgateContext.PostToAPI('event/' + eventID + '/participant', editedData, undefined, 1).then((success, err) => {
            if(success) {
                updateData(prevState => {
                    let newState = prevState;
                    newState = newState+1;
                    return {...newState};
                  });
        
                setSnackbarMessage("Participant Added: " + (editedData.fName ? editedData.fName : "") + " " + (editedData.lName ? editedData.lName : ""));
            }

            if(err) {
                console.log("Error: " + err);
            }

        }).catch((error) => {
            console.log("Error: " + error);
        });

      //fetch(SophConstants.API_ADDR + '/api/event/' + eventID + '/participant/' + '?api-key=foo', {
    //       method: 'post',
    //       mode: 'cors', // no-cors, *cors, same-origin
    //       cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //       credentials: 'same-origin', // include, *same-origin, omit
    //       headers: {
    //           'Content-Type': 'application/json',
    //       },
    //       body: JSON.stringify(editedData)
    //   }).then((res) => {
    //       return res.json();
    //   }).then((jsonData, status) => {
    //       console.log(JSON.stringify(jsonData, null, 4));

    //       updateData(prevState => {
    //         let newState = prevState;
    //         newState = newState+1;
    //         return {...newState};
    //       });

    //       setSnackbarMessage("Participant Added: " + (editedData.fName ? editedData.fName : "") + " " + (editedData.lName ? editedData.lName : ""));
    
    //       //handleCloseForm();
    //   }).catch((err) => {
    //       console.log(err);
    //       if(value < 10)
    //           return wait(500).then(() => setValue(value+1));
    //   });
    
    }

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item sx={{textAlign:"center"}}>
                            <Typography component="h2" variant="h4" color="textSecondary">Add a Paricipant</Typography>
                            Complete the form below to add a participant to the event
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}  sx={{mt:2}}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item sx={{mr:1}}>
                            <AssignmentIcon color="action"/>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" align="left" color="textSecondary">Contact Details</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="standard"
                        label="First Name"
                        id="fName"
                        value={changedData.fName}
                        sx={{  width: '100%' }}
                        onChange={handleTextChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                        variant="standard"
                        label="Last Name"
                        id="lName"
                        value={changedData.lName}
                        sx={{  width: '100%' }}
                        onChange={handleTextChange}
                    />
                </Grid>
                
                <Grid item xs={0} sm={12} >
                </Grid>

                <Grid item xs={12} sm={6}>
                <TextField
                        variant="standard"
                        label="Email"
                        id="email"
                        value={changedData.email}
                        sx={{  width: '100%' }}
                        onChange={handleTextChange}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                <TextField
                        variant="standard"
                        label="Phone Number"
                        id="phoneNumber"
                        value={changedData.phoneNumber}
                        sx={{  width: '100%' }}
                        onChange={handleTextChange}
                    />
                </Grid>

                <Grid item xs={0} sm={12} >
                </Grid>

                <Grid item xs={12} sm={6}>
                    <CountrySelect handleCountryChange={handleCountryChange} currentCountry={changedData.countryID} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="standard"
                        label="State/Provence"
                        id="state"
                        value={changedData.state}
                        sx={{  width: '100%' }}
                        onChange={handleTextChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="standard"
                        label="City"
                        id="city"
                        value={changedData.city}
                        sx={{  width: '100%' }}
                        onChange={handleTextChange}
                    />
                </Grid>


                <Grid item xs={12}  sx={{mt:2}}>
                <Box sx={{ mt: 4 }} />
                    <Grid container direction="row" alignItems="center">
                        <Grid item sx={{mr:1}}>
                        <AssessmentIcon color="action"/>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" align="left" color="textSecondary">Event Details</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="dropped">
                        Attending
                        </InputLabel>
                        <NativeSelect
                            value={changedData.dropped}
                            onChange={handleTextChange}
                            sx={{  width: '100%' }}
                            inputProps={{
                            name: 'dropped',
                            id: 'dropped',
                            }}
                        >
                            <option value={false}>Yes</option>
                            <option value={true}>Dropped</option>
                        </NativeSelect>
                    </FormControl>
                </Grid>


                <Grid item xs={12} mt={4}>
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item>
                                <Button variant="contained" color="success" onClick={handleSubmit}>Save</Button>
                            </Grid>
                            
                            <Grid item>
                                <Button variant="contained" onClick={handleCloseForm} color="error">Cancel</Button>
                            </Grid>
                        </Grid>

                    </Grid>
            </Grid>
        </Box>
      );
}

