import React, { useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../../context/aldersgate';
import localizer from '../../../localization/localizer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';

export default function HistoricalMediumCount(props) {
    const { groupID, year } = props;

    const [yearData, setYearData] = useState([]);
    const [eventCountData, setEventCountData] = useState([]);
    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {
        GetHistoricalEventsCount();
    }, [year]);


    const GetHistoricalEventsCount = () => {
        if(groupID === undefined || groupID === null)
            return;

        let query = '&groupID=' + groupID;

        if(year !== undefined && year !== null) {
            query += '&year=' + year;
            query += '&history=5';
        }

        aldersgateContext.GetFromAPI('stats/year/eventmediumcount', query, 1).then((success, err) => {
            if(success) {
                ParseData(success);
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });    
        
        //fetch(SophConstants.API_ADDR + '/api/stats/year/eventmediumcount/?api-key=foo' + query, {
    }

    const ParseData = (jsonData) => {
        let yearData = [];
        let eventCountData = [];

        let inPerson = [];
        let online = [];
        let hybrid = [];
        for(let i = jsonData.length-1; i >= 0 ; i--) {
            let year = new Date(jsonData[i].year, 0, 1);
            yearData.push(year);

            if(jsonData[i].data !== undefined && jsonData[i].data !== null) {

                for(let j = 0; j < jsonData[i].data.length; j++) {
                    let countData = jsonData[i].data[j];
                    if(countData.type === 'In-Person') {
                        inPerson.push(parseInt(countData.count) || 0);
                    }

                    if(countData.type === 'Online') {
                        online.push(parseInt(countData.count) || 0);
                    }

                    if(countData.type === 'Hybrid') {
                        hybrid.push(parseInt(countData.count) || 0);
                    }
                }

            }
        }

        eventCountData.push({id: 'In-Person', label:'In-Person', data: inPerson, stack:'total', area:true, showMark: false});
        eventCountData.push({id: 'Online', label:'Online', data: online, stack:'total', area:true, showMark: false});
        eventCountData.push({id: 'Hybrid', label:'Hybrid', data: hybrid, stack:'total', area:true, showMark: false});


        setYearData(yearData);
        setEventCountData(eventCountData);
    }

    const valueFormatter = (value) => `${value} leaders`;

    if(eventCountData.length === 0)
        return null;

    const chartSetting = {
        height: 300,
        margin: { top: 50 },
        };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
            <BarChart
                xAxis={[{ id: 'Years', data: yearData,
                    scaleType: 'band',
                    valueFormatter: (date) => date.getFullYear(), }]}
                    series={eventCountData}
                {...chartSetting}
            />
            </Box>
        </Box>
    );
}
