import React, { useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../../context/aldersgate';
import localizer from '../../../localization/localizer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LineChart } from '@mui/x-charts/LineChart';

export default function CreatedVsCompleted(props) {
    const { groupID, year } = props;

    const [dateData, setDateData] = useState([]);
    const [createdCount, setCreatedCount] = useState([]);
    const [completedCount, setCompletedCount] = useState([]);
    const [displayChart, setDisplayChart] = useState(false);
    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {
        GetHistoricalEventsCount();
    }, [year]);

    useEffect(() => {
        if(dateData.length > 0)
            setDisplayChart(true);
    }, [dateData]);


    const GetHistoricalEventsCount = () => {
        if(groupID === undefined || groupID === null)
            return;

        let query = '&groupID=' + groupID;

        if(year !== undefined && year !== null) {
            query += '&year=' + year;
            query += '&history=10';
        }

        aldersgateContext.GetFromAPI('stats/month/eventcount', query, 1).then((success, err) => {
            if(success) {
                ParseData(success);
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });
    }

    const ParseData = (jsonData) => {
        let timeData = [];
        let eventCreatedCount = [];
        let eventCompletedCount = [];

        for(let i = 0; i < jsonData.length; i++) {
            let date = new Date(jsonData[i].year, jsonData[i].month, 1);
            timeData.push(date);
            eventCreatedCount.push(parseInt(jsonData[i].countCreated) || 0);
            eventCompletedCount.push(parseInt(jsonData[i].countCompleted) || 0);
        }

        setDateData(timeData);
        setCreatedCount(eventCreatedCount);
        setCompletedCount(eventCompletedCount);
    }

    const valueFormatter = (value) => `${value} leaders`;

    if(!displayChart)
        return null;

    const chartSetting = {
        height: 300,
        margin: { top: 50 },
    };

    let chartDateData = [...dateData];
    let chartCreatedCount = [...createdCount];
    let chartCompletedCount = [...completedCount];

    return (
        
            <LineChart
                xAxis={[
                    {
                    id: 'Years',
                    data: chartDateData,
                    scaleType: 'time',
                    valueFormatter: (date) => (date.getMonth()+1).toString(),
                    },
                ]}
                series={[
                    {
                    id: 'CreatedEvents',
                    label: 'Created',
                    data: chartCreatedCount,
                    stack: 'total',
                    area: true,
                    showMark: false,
                    },
                    {
                    id: 'CompletedEvents',
                    label: 'Completed',
                    data: chartCompletedCount,
                    stack: 'total',
                    area: true,
                    showMark: false,
                    }
                ]}
                {...chartSetting}
            />

    );
}
