import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AldersgateContext } from '../../../../context/aldersgate';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import BackupIcon from '@mui/icons-material/Backup';
import AddIcon from '@mui/icons-material/Add';


export default function EventGroupsView(props) {
    const {groupData, OnGroupAdd, OnGroupDelete } = props;

    const [groupList, setGroupList] = useState([]);

    useEffect(() => {
        setGroupList(groupData);
    }, [groupData]);

    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px'}}>
                <Typography variant="h5">Event Groups</Typography>
                <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={OnGroupAdd}>Add Group</Button>
            </Box>
            <Grid container spacing={2}>
                {groupList.map(group => (
                    <Grid item xs={12} sm={6} md={4} key={group.groupID}>
                        <Paper sx={{padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
                            <Typography variant="h6">{group.name}</Typography>
                            <Typography variant="body1">{group.description}</Typography>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Typography variant="body2">{group.members.length} Members</Typography>
                                <Box>
                                    <IconButton size="small" onClick={() => OnGroupDelete(group.groupID)}>
                                        <VisibilityOffIcon />
                                    </IconButton>
                                    <IconButton size="small">
                                        <BackupIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );

}