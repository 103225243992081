import React from 'react';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';



export default function NavBreadrumbs(props) {
    const { crumbs, currentPage } = props;

    const history = useHistory();


    const CrumbsLink = (linkProps) => {
        const { url, text } = linkProps;
        const  handleClick = (event) => {
            event.preventDefault();
    
            if(history !== undefined)
                history.push(url);
        }

        return (
            <Link onClick={handleClick} underline="hover" color="inherit">{text}</Link>
        )
    }

    const GenerateBreadcrumbs = (breadProps) => {

        let elements = [];
        for(let i=0; i < breadProps.length; i++) {
            elements.push(<CrumbsLink key={i} url={breadProps[i].href} text={breadProps[i].text} />);
        }
    
        return elements;
    }

  return (
        <Box sx={{height:'35px'}}>
            <Breadcrumbs aria-label="breadcrumbs">
                {GenerateBreadcrumbs(crumbs)}
                <Typography color="text.primary">{currentPage}</Typography>
            </Breadcrumbs>
        </Box>
  );
}
