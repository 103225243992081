import React from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';

export default function CreateEventForm2(props){

    const { values, handleChange } = props;

    return (
      <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
            <InputLabel id="eventType">Event Type</InputLabel>
            <Select
              labelId="eventType"
              id="eventType"
              value={values.eventType}
              onChange={handleChange('eventType')}
              fullWidth
              variant='standard'
            >
              <MenuItem value={'Module 1'}>Module 1</MenuItem>
              <MenuItem value={'Module 2'}>Module 2</MenuItem>
              <MenuItem value={'Journey'}>Journey</MenuItem>
              <MenuItem value={'Mobilization'}>Flight School</MenuItem>
            </Select>
          </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
            <InputLabel id="eventMedium">Training Method</InputLabel>
            <Select
              labelId="eventMedium"
              id="eventMedium"
              value={values.eventMedium}
              onChange={handleChange('eventMedium')}
              fullWidth
              variant='standard'
            >
              <MenuItem value={'In-Person'}>In-Person</MenuItem>
              <MenuItem value={'Hybrid'}>Hybrid</MenuItem>
              <MenuItem value={'Online'}>Online</MenuItem>
            </Select>
          </FormControl>
          </Grid>
        </Grid>
    );
}