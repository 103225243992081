import React, {useState, useEffect, useContext } from 'react';
import CountrySelect from '../../CountrySelect';
import { AldersgateContext } from '../../../context/aldersgate';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box  from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import NativeSelect from '@mui/material/NativeSelect';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssessmentIcon from '@mui/icons-material/Assessment';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

const style = {
    bgcolor: 'background.paper',
    border: '2px solid #444',
    p: 4,
    m:2,
  };

export default function EditEventDetails(props) {
    const { eventData, updateInformation } = props;
    const {updateData, setSnackbarMessage} = updateInformation;
    const {eventID } = eventData;
    const [formData, setFormData] = useState({...eventData});
    const [changeData, setChangeData] = useState({});
    const [buttonsDisabled, setButtonsDisabled] = useState(true);

    const aldersgateContext = useContext(AldersgateContext);

    const startingDateChange = (newValue) => {        
        setFormData(prevState => {
            let newState = {...prevState};
            newState.startingDate = newValue;
            return {...newState};
        });

        setChangeData(prevState => {
            let newState = {...prevState};
            newState.startingDate = newValue;
            return {...newState};
        });

        setButtonsDisabled(false);

        //setValue(newValue);
    };

    const endingDateChange = (newValue) => {
        setFormData(prevState => {
            let newState = {...prevState};
            newState.endingDate = newValue;
            return {...newState};
        });

        setChangeData(prevState => {
            let newState = {...prevState};
            newState.endingDate = newValue;
            return {...newState};
        });

        setButtonsDisabled(false);
    };

    const handleTextChange = (event) => {
        console.log(event.target.id + " " + event.target.value);
        let value = {[event.target.id]: event.target.value};
        console.log(JSON.stringify(value));

        setFormData(prevState => {
            let newState = {...prevState};
            newState[event.target.id] = event.target.value;
            return {...newState};
        });

        setChangeData(prevState => {
            let newState = {...prevState};
            newState[event.target.id] = event.target.value;
            return {...newState};
        });

        setButtonsDisabled(false);
    }

    const resetForm = () => {
        setFormData({...eventData});
        setChangeData({});
        setButtonsDisabled(true);
    }

    const handleCountryChange = (event, values) => {
        let code = "";
        if(values && values.code)
          code = values.code;
    
        setFormData(prevState => {
            let newState = {...prevState};
            newState.countryID = code;
            return {...newState};
        });

        setChangeData(prevState => {
            let newState = {...prevState};
            newState.countryID = code;
            return {...newState};
        });

        setButtonsDisabled(false);

      };

    const handleMaxParticipantChange = (event) => {
        let value = event.target.value;
        if(value < 0)
            value = 0;

        setFormData(prevState => {
            let newState = {...prevState};
            newState.maxParticipants = value;
            return {...newState};
        });

        setChangeData(prevState => {
            let newState = {...prevState};
            newState.maxParticipants = value;
            return {...newState};
        });

        setButtonsDisabled(false);
    }

    const handleSubmit = () => {
        let eventData = {...changeData};

        aldersgateContext.PostToAPI('event/' + eventID + '/update', eventData).then((success, err) => {
            if(success) {
                updateData(prevState => {
                    let newState = prevState;
                    newState = newState+1;
                    return {...newState};
                });
    
                setSnackbarMessage("Event Updated!");
            }
    
            if(err) {
                console.log("Error: " + err);
                setSnackbarMessage("Event update error!");
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
            setSnackbarMessage("Event update error!");
        });

        //fetch(SophConstants.API_ADDR + '/api/event/' + eventID + '/update/?api-key=foo', {

    }

    return (
        <Paper elevation={1}>
            <Grid container spacing={2} sx={{ px: 2, mt:0 }}>
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item sx={{mr:1}}>
                            <AssignmentIcon color="action"/>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" align="left" color="textSecondary">Basic Details</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            variant="standard"
                            label="Name of Event"
                            id="eventName"
                            value={formData.eventName}
                            sx={{  width: '100%' }}
                            onChange={handleTextChange}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="eventType">
                        Event Type
                        </InputLabel>
                        <NativeSelect
                            value={formData.eventType}
                            sx={{  width: '100%' }}
                            onChange={handleTextChange}
                            inputProps={{
                            name: 'eventType',
                            id: 'eventType',
                            }}
                        >
                            <option value='Module 1'>Module 1</option>
                            <option value='Module 2'>Module 2</option>
                            <option value='Journey'>History Makers Journey</option>
                            <option value='Mobilization'>Flight School</option>
                            <option value='Alumni Summit'>Alumni Summit</option>
                        </NativeSelect>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="eventMedium">
                        Training Method
                        </InputLabel>
                        <NativeSelect
                            value={formData.eventMedium}
                            onChange={handleTextChange}
                            sx={{  width: '100%' }}
                            inputProps={{
                            name: 'eventMedium',
                            id: 'eventMedium',
                            }}
                        >
                            <option value='In-Person'>In-Person</option>
                            <option value='Online'>Online</option>
                            <option value='Hybrid'>Hybrid</option>
                        </NativeSelect>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                            id="startingDate"
                            label="Starting Date"
                            inputFormat="MM/dd/yyyy"
                            value={formData.startingDate}
                            onChange={startingDateChange}
                            renderInput={(params) => <TextField variant="standard" sx={{  width: '100%' }} {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                            id="endingDate"
                            label="Ending Date"
                            inputFormat="MM/dd/yyyy"
                            value={formData.endingDate}
                            onChange={endingDateChange}
                            renderInput={(params) => <TextField variant="standard" sx={{  width: '100%' }} {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12}  sx={{mt:6}}>

                    <Grid container direction="row" alignItems="center">
                        <Grid item sx={{mr:1}}>
                            <AddLocationIcon color="action"/>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" align="left" color="textSecondary">Location</Typography>
                        </Grid>
                    </Grid>
                
                </Grid>
                <Grid item xs={12} sm={4}>
                    <CountrySelect handleCountryChange={handleCountryChange} currentCountry={formData.countryID} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="standard"
                        label="State/Provence"
                        id="state"
                        value={formData.state}
                        sx={{  width: '100%' }}
                        onChange={handleTextChange}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="standard"
                        label="City"
                        id="city"
                        value={formData.city}
                        sx={{  width: '100%' }}
                        onChange={handleTextChange}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="language">
                        Language
                        </InputLabel>
                        <NativeSelect
                            value={formData.language}
                            sx={{  width: '100%' }}
                            onChange={handleTextChange}
                            inputProps={{
                            name: 'language',
                            id: 'language',
                            }}
                        >
                            <option value='en'>English</option>
                            <option value='es'>Spanish</option>
                            <option value='fr'>French</option>
                            <option value='pt'>Portuguese</option>
                        </NativeSelect>
                    </FormControl>
                </Grid>


                <Grid item xs={12}  sx={{mt:6}}>

                    <Grid container direction="row" alignItems="center">
                        <Grid item sx={{mr:1}}>
                            <AssessmentIcon color="action"/>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" align="left" color="textSecondary">Event Status</Typography>
                        </Grid>
                    </Grid>
                
                </Grid>

                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="eventStatus">
                        Status
                        </InputLabel>
                        <NativeSelect
                            value={formData.eventStatus}
                            sx={{  width: '100%' }}
                            onChange={handleTextChange}
                            inputProps={{
                            name: 'eventStatus',
                            id: 'eventStatus',
                            }}
                        >
                            <option value='planning'>Planning</option>
                            <option value='complete'>Completed</option>
                            <option value='in-progress'>In Progress</option>
                            <option value='postponed'>Postponed</option>
                        </NativeSelect>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <TextField 
                                label="Max Participants"
                                variant="standard"
                                id="maxparticipants"
                                value={formData.maxParticipants}
                                onChange={handleMaxParticipantChange}
                                type="number"
                                onBlur={handleMaxParticipantChange}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*'}}
                                />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="eventClosed">
                        Registration
                        </InputLabel>
                        <NativeSelect
                            value={formData.eventClosed}
                            sx={{  width: '100%' }}
                            onChange={handleTextChange}
                            inputProps={{
                            name: 'eventClosed',
                            id: 'eventClosed',
                            }}
                        >
                            <option value='false'>Open</option>
                            <option value='true'>Closed</option>
                        </NativeSelect>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Box mt={5} mb={2}>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Button variant="contained" disabled={buttonsDisabled} onClick={resetForm} color="error">Cancel</Button>
                        </Grid>

                        <Grid item>
                            <Button variant="contained" disabled={buttonsDisabled} color="success" onClick={handleSubmit}>Save</Button>
                        </Grid>
                    
                    </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
        

    );
}