import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { deepOrange, deepPurple } from '@mui/material/colors';


export default function GroupLeaderCard(props) {
    const { groupOwnerData } = props;

    let groupOwnerName = "";
    let groupOwnerInitials = "";

    if(groupOwnerData !== undefined) {
        groupOwnerName = groupOwnerData.fName + " " + groupOwnerData.lName;
        let fnArray = groupOwnerData.fName.split("");
        let lnArray = groupOwnerData.lName.split("");
        groupOwnerInitials = (fnArray.length > 0 ? fnArray[0] : "") + (lnArray.length > 0 ? lnArray[0] : "");
    }

    return (
        <Grid container spacing={0} justify="flex-start">
            <Grid item xs={12}>
                <Box sx={{ width: '100%' }} display="flex" justifyContent="flex-start">
                    <Typography variant="body1" gutterBottom color="textSecondary">Leader</Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ width: '100%' }} display="flex" justifyContent="flex-start">
                    <Box sx={{ mr: 1 }}>
                    <Avatar>{groupOwnerInitials}</Avatar>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center">
                        {groupOwnerName}
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}
