import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MemberBubbles from './MemberBubbles';
import GroupLeaderCard from './GroupLeaderCard';
import ChatView from '../Chatbox/ChatView';
import Paper from '@mui/material/Paper';
import EventsView from '../dashboard/EventsView';

export default function GroupOverview(props) {
    const { groupData, isLoading } = props;

    let  groupName  = "";
    let groupMembers = [];
    let groupOwner = {};
    let groupID = undefined;

    let titleSubtext = "Training Team";

    if(groupData !== undefined) {
        groupName = groupData.name;

        if(groupData.regionalGroup === true) {
            titleSubtext = "Region";
        }

        groupOwner = groupData.owner;
        groupID = groupData.adID;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography component="h2" variant="h4" color="textSecondary">{groupName}</Typography>
                <Typography variant="body1"  color="textSecondary">{titleSubtext}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                <GroupLeaderCard groupOwnerData={groupOwner}/>
                    </Grid>
                    <Grid item xs={12}>
                <MemberBubbles groupMembers={groupData.members}/>
                </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={9}>
                <Paper elevation={2}>
                    <ChatView chatOwnerID={groupID}/>
                </Paper>
            </Grid>

            <Grid item xs={12} sx={{mt:4}}>
                <EventsView eventsData={groupData.events}/>
            </Grid>

        </Grid>
    );

}