import React, {useEffect, useState, useContext} from  'react';
import { Link, useHistory } from 'react-router-dom';
import { AldersgateContext } from '../../../../context/aldersgate';
import AddContentPost from './AddContentPost';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

export default function ADashboardPostsView(props) {
    const history = useHistory();
    
    const currentYear = new Date().getFullYear();
    const firstDay = new Date(currentYear, 0, 1);
    const lastDay = new Date(currentYear, 11, 31);

    const aldersgateContext = useContext(AldersgateContext);
    

    return (

        <Grid container spacing={3}>
            <Grid item xs={12}>
                <AddContentPost />
            </Grid>
        </Grid>

    );
}