import React, { useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../../context/aldersgate';
import SophConstants from '../../../constants/SophConstants';
import AuthContext from '../../../context/AuthContext';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';


export default function NewReportAssignment(props) {
    const {setModalOpen, refreshData, groupID} = props;

    const [formData, setFormData] = useState({fName: '', lName: '', email: '', pword: '', message:'', year: new Date().getFullYear(), quarter: Math.floor((new Date().getMonth() + 3) / 3)});

    const [buttonEnabled, setButtonEnabled ] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const authContext = React.useContext(AuthContext);

    useEffect(() => {
        if(authContext?.accountState?.adID !== undefined) {
            setFormData(prev => {
                return({...prev, assignedBy: authContext.accountState.adID});
            });
        }
      }, [authContext]);

    useEffect(() => {
        if(formData.fName !== '' && formData.lName !== '') {
            setButtonEnabled(true);
        } else {
            setButtonEnabled(false);
        }
    }, [formData]);

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        let submitData = {...formData};
        if(submitData.pword === '')
            submitData.pword = undefined;

        if(groupID !== undefined)
            submitData.groupID = groupID;

        fetch(SophConstants.API_ADDR + '/api/reports/addassignment?api-key=foo', {
            method: 'post',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(submitData)
        }).then((res) => {
            return res.json();
        }).then((jsonData, status) => {
            
            //reset form
            setFormData({fName: '', lName: '', email: '', pword: '', message:'', year: new Date().getFullYear(), quarter: Math.floor((new Date().getMonth() + 3) / 3)});
            setButtonEnabled(false);

            //close the form
            if(setModalOpen !== undefined)
                setModalOpen(false);

        }).catch((err) => {
            console.log("error: " + err);
            setButtonEnabled(false);
        });
    }

    const handleCancel = (event) => {
        event.preventDefault();

        if(setModalOpen !== undefined)
            setModalOpen(false);
    }

    const RenderQuarterSelection = () => {
        let quarterOptions = [];
        for(let i = 1; i <= 4; i++) {
            quarterOptions.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
        }

        return (
            <Box sx={{ml:2, width:'100%'}}>
                <FormControl sx={{minWidth: 80, width:'100%' }}>
                    <InputLabel id="quarter-select-label">Quarter</InputLabel>
                    <Select
                        labelId="quarter-select-label"
                        id="quarter"
                        name="quarter"
                        value={formData.quarter}
                        label="Quarter"
                        onChange={handleFormChange}
                    >
                        {quarterOptions}
                    </Select>
                </FormControl>
            </Box>
        );
    }

    const RenderYearSelection = () => {
        let currentYear = new Date().getFullYear();

        let yearOptions = [];
        for(let i = 0; i < 2; i++) {
            let year = currentYear - i;
            yearOptions.push(<MenuItem key={year} value={year}>{year}</MenuItem>);
        }

        return (
            <Box sx={{width:'100%'}}>
                <FormControl sx={{minWidth: 150, width:'100%' }}>
                    <InputLabel id="year-select-label">Year</InputLabel>
                    <Select
                        labelId="year-select-label"
                        id="year"
                        name="year"
                        value={formData.year}
                        label="Year"
                        onChange={handleFormChange}
                    >
                        {yearOptions}
                    </Select>
                </FormControl>
            </Box>
        );

    }

    return (
        <Box>
            <Typography variant="h6" gutterBottom component="div">
                Assign a New Report
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField id="fName" name="fName" label="First Name" variant="outlined" fullWidth onChange={handleFormChange} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField id="lName" name="lName" label="Last Name" variant="outlined" fullWidth onChange={handleFormChange} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField id="email" name="email" label="Email" variant="outlined" fullWidth onChange={handleFormChange} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                            <RenderYearSelection />
                            <RenderQuarterSelection />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        
                        <TextField
                        label='Report Password (optional):'
                        id="pword"
                        name="pword"
                        variant="outlined"
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        onChange={handleFormChange}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            )
                        }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="body1" gutterBottom component="div">
                            Add a message to the leader:
                        </Typography>
                        <TextField
                            id="message"
                            name="message"
                            label="Message"
                            multiline
                            rows={2}
                            maxRows={8}
                            variant="outlined"
                            fullWidth
                            onChange={handleFormChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width:'100%'}}>
                            <Box sx={{mr:1}}>
                                <Button variant="contained" color="secondary" onClick={handleCancel}>Cancel</Button>
                            </Box>
                            <Box sx={{ml:1}}>
                                <Button variant="contained" color="primary" type="submit" disabled={!buttonEnabled}>Submit</Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}

