import React, {useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../../context/aldersgate';
import HeaderPreview from './EditEventForm/HeaderPreview';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box  from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import NativeSelect from '@mui/material/NativeSelect';

const BannerOptions = [
    {value: 0, sx:{position:"relative",
        margin:"0 auto",
        backgroundImage: `url(${process.env.PUBLIC_URL + '/reghero.png'})`,
        backgroundRepeat  : 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize:"cover",
        justifyContent: "center"}, 
    label: "Image"},
    {value: 1, sx:{background: 'linear-gradient(to right bottom, #36EAEF, #6B0AC9)'}, label: "Gradient"},
    {value: 2, sx:{background: 'linear-gradient(to right bottom, #4158D0, #C850C0, #FFCC70)'}, label: "Gradient"},
    {value: 3, sx:{background: 'linear-gradient(to right bottom, #8EC5FC, #E0C3FC)'}, label: "Gradient"},
    {value: 4, sx:{background: 'linear-gradient(to right bottom, #FF3CAC, #784BA0, #2B86C5)'}, label: "Gradient"},
    {value: 5, sx:{background: 'linear-gradient(to right bottom, #FBAB7E, #F7CE68)'}, label: "Gradient"},
    {value: 6, sx:{background: 'linear-gradient(to right bottom, #FEB692, #EA5455)'}, label: "Gradient"},
    {value: 7, sx:{background: 'linear-gradient(to right bottom, #F093FB, #F5576C)'}, label: "Gradient"},
    {value: 8, sx:{background: 'linear-gradient(to right bottom, #5EE7DF, #B490CA)'}, label: "Gradient"},
    {value: 9, sx:{background: '#3573b9'}, label: "Solid"},
    {value: 10, sx:{background: '#aaaaaa'}, label: "Solid"},
    
];

const DisplayBannerOptions = (props) => {
    const {bannerID, setBannerID} = props;

    const handleChange = (event) => {
        setBannerID(event.target.id);
    }

    

    function RenderElement(elProps) {
        const {sx, id, selectedID} = elProps;

        let style = {...sx, width:"100%", height:"150px"};

        if(selectedID == id || (selectedID === undefined && id === 0)) {
            style = {...style, border:"7px solid #000000", boxSizing:"border-box"};
        }

        return (
            <Box sx={style} id={id} onClick={handleChange} />
        );
    }

    return (
        <Grid container>
            <Grid item xs={12} mt={4}>
                <Box sx={{textAlign:"center"}}>
                <Typography variant="h5">
                    Select header style
                </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} m={3}>
                <Grid container spacing={2} sx={{height:'100%'}}>
                    {BannerOptions.map((option) => (
                        <Grid key={option.value} item xs={6} sm={6} md={4} lg={3} xl={3}>
                            <RenderElement sx={option.sx}  id={option.value} selectedID={bannerID} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default function EditEventForm(props) {
    const { eventData, updateInformation } = props;
    const {updateData, setSnackbarMessage} = updateInformation;
    const {eventID } = eventData;

    const [formEdits, setFormEdits] = useState({bannerID: 0, description: ""});
    const [buttonsDisabled, setButtonsDisabled] = useState(true);
    const [openBannerSelect, setOpenBannerSelect] = useState(false);
    const [value, setValue] = React.useState(0);
    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {
        if(eventData.signupform !== undefined && eventData.signupform !== null) {
            setFormEdits({...eventData.signupform});
        } else {
            setFormEdits({bannerID: 0, description: ""});
        }
    }, [value]);

    const EditBanner = (bannerProps) => {

        if(bannerProps !== undefined && bannerProps !== null && bannerProps != formEdits.bannerID) {
            setFormEdits(prev => ({...prev, bannerID: bannerProps}));

            setButtonsDisabled(false);
        }

        setOpenBannerSelect(false);
    }

    const handleTextChange = (event) => {
        const {name, value} = event.target;

        setFormEdits(prev => ({...prev, description: value}));

        setButtonsDisabled(false);
    }


    const resetForm = () => {
        setFormEdits({...eventData.signupform});
        setButtonsDisabled(true);
    }

    const handleSubmit = (event) => {
        let formData = {formData: {...formEdits}};

        aldersgateContext.PostToAPI('event/' + eventID + '/signupform', formData, null, 1).then((success, err) => {
            if(success) {
                updateData(prevState => {
                    let newState = prevState;
                    newState = newState+1;
                    return {...newState};
                });
    
                setSnackbarMessage("Event Updated!");
            }
    
            if(err) {
                console.log("Error: " + err);
                setSnackbarMessage("Form update error!");
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
            setSnackbarMessage("Form update error!");
        });

        //fetch(SophConstants.API_ADDR + '/api/event/' + eventID + '/signupform/?api-key=foo', {
    }

    const changeBanner = (event) => {
        setOpenBannerSelect(true);
    }

    return (
        <Paper elevation={1}>
            <Grid container spacing={2} sx={{ px: 2, mt:0 }}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={0} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                            <HeaderPreview eventData={eventData} selectedBanner={formEdits.bannerID} bannerOptions={BannerOptions} changeBanner={changeBanner} />
                        </Grid>
                        <Grid item xs={0} md={2}></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={0} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                            id="outlined-multiline-static"
                            label="Event Description"
                            multiline
                            rows={4}
                            defaultValue={formEdits.description}
                            onChange={handleTextChange}
                            sx={{width:"100%"}}
                            />
                        </Grid>
                        <Grid item xs={0} md={2}></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box mb={2}>
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item>
                                <Button variant="contained" disabled={buttonsDisabled} color="success" onClick={handleSubmit}>Save</Button>
                            </Grid>
                            
                            <Grid item>
                                <Button variant="contained" disabled={buttonsDisabled} onClick={resetForm} color="error">Cancel</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

            </Grid>
        <Modal
              open={openBannerSelect}
              onClose={() => setOpenBannerSelect(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{position:'absolute',
              top:'0%',
              left:'0%',
              overflow:'scroll',
              height:'100%',
              display:'block'}}
          >
            <Paper>
                <DisplayBannerOptions bannerID={formEdits.bannerID} setBannerID={EditBanner} />
            </Paper>
          </Modal>
        </Paper>
    );
}

//<DisplayBannerOptions bannerID={formEdits.bannerID} setBannerID={EditBanner} />