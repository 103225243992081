import React, {useState, useEffect } from 'react';
import DCheckInList from './DCheckInList/DCheckInList';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Box  from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

export default function CheckInListDataGrid(props) {
    const { eventData } = props;
    const { participants, eventID } = eventData;
    

    

    const RenderCheckInField = (paramData) => {
        const { rowData } = paramData;
        const [confirm, setConfirm] = useState(false);

        const HandleFirstCheckAction = () => {
            setConfirm(true);
        }
    
        const HandleConfirmAction = () => {
            setConfirm(false);
        }
    
        const HandleCancelAction = () => {
            setConfirm(false);
        }

        if(rowData.checkedIn) {
            return (
            <Box mr={1} sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', width:'100%'}}>
                <CheckIcon color="primary" />
            </Box>
            );
        } else {
            if(!confirm) {
                return (
                    <Box mr={1} sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', width:'100%'}}>
                        <Button onClick={HandleFirstCheckAction} variant="contained" color="primary" size="large">Check In</Button>
                    </Box>
                );
            } else {
                return (
                    <Stack direction="row" spacing={2} mr={1} sx={{ width:'100%'}}>
                        <Button onClick={HandleCancelAction} variant="contained" color="error" size="large">Cancel</Button>
                        <Button onClick={HandleConfirmAction} variant="contained" color="primary" size="large">Confirm?</Button>
                    </Stack>
                );
            }
        }
    }

    const RenderLocation = (paramData) => {
        const { rowData } = paramData;

        return (
            <Box>
                {rowData.city}, {rowData.state}, {rowData.countryID}
            </Box>
        );
        
    }

    //"/api/certificate/mcert/Test/Tester/type/5-4-2023/Carrollton/USA/en?api-key=foo"
    const columns = [
        { field: 'fName', headerName: 'First Name', flex: 1,editable: false },
        { field: 'lName', headerName: 'Last Name', flex: 1,editable: false },
        { field: 'countryID', headerName: 'Location', type: 'boolean', flex: 1, editable: false, renderCell: (params) => {
            return (
                <RenderLocation rowData={params.row} />
            )
         }},
         { field: 'checkedIn', headerName: 'Check In', type: 'boolean', flex: 1, editable: false, renderCell: (params) => {
            return (
                <RenderCheckInField rowData={params.row} />
            )
         }}
    ]; 

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <Paper elevation={1}>
                <DCheckInList peopleListData={eventData.participants} signupID={eventData.signupID} />
            </Paper>
        </Box>
    )

}

/*
<DataGrid rows={participants} columns={columns} 
                    rowsPerPageOptions={[25, 50, 75]}
                    pagination
                    autoHeight
                />
                */