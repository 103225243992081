import React, {useEffect, useState} from  'react';
import SophConstants from '../../../constants/SophConstants';
import localizer from '../../../localization/localizer';
import QuarterlyEvents from './QuarterlyEvents';
import YearEventTypes from './YearEventTypes';
import HistoricalEventsCount from './HistoricalEventsCount';
import HistoricalMediumCount from './HistoricalMediumCount';
import CreatedVsCompleted from './CreatedVsCompleted';
import CreatedVsCompletedPie from './CreatedVsCompletedPie';
import EventsCount from './EventsCount';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DEventList from './DEventList/DEventList';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import AlumniCount from './AlumniCount';


export default function RegionAnalytics(props) {

    const{groupID} = props;


    //first day this year
    const firstDay = new Date(new Date().getFullYear(), 0, 1);
    //first day 5 years ago
    const firstDay5YearsAgo = new Date(new Date().getFullYear(), 0, 1);

    //last day this year
    const lastDay = new Date(new Date().getFullYear(), 11, 31);

    return (
        <Box sx={{display:'flex', flexDirection:'column'}}>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Paper>
                            <QuarterlyEvents groupID={groupID} year={2023}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper>
                            <YearEventTypes groupID={groupID} year={2023}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper>
                            <HistoricalEventsCount groupID={groupID} year={2023}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper>
                            <HistoricalMediumCount groupID={groupID} year={2023}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper>
                            <CreatedVsCompleted groupID={groupID} year={2023}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper>
                            <CreatedVsCompletedPie groupID={groupID} year={2023}/>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

/*
                    <Grid item xs={12} md={3}>
                        <Paper elevation={2}>
                            <EventsCount groupID={groupADID}headerText='Registered Events' startingDate={firstDay5YearsAgo} endingDate={lastDay} footerText='This Year'/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Paper elevation={2}>
                        <AlumniCount groupID={groupADID} headerText='Leaders Trained' startingDate={firstDay5YearsAgo} endingDate={lastDay} footerText='This Year' />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Paper elevation={2}>
                            <EventsCount groupID={groupADID}headerText='Registered Events' footerText='All Time'/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Paper elevation={2}>
                        <AlumniCount groupID={groupADID} headerText='Leaders Trained' footerText='All Time'/>
                        </Paper>
                    </Grid>
*/