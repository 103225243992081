import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TOSView from '../components/legal/TOSView';


export default function TOS(props) {

    //default privacy policy for website use
    return (
            <Box m={1.5} bgcolor="background.paper" >
            <Grid container >
            <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
            
                <TOSView />

            </Grid>
            <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
            </Grid>
        </Grid>
        </Box>
    );

}