import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import CheckIcon from '@mui/icons-material/Check';
import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import GroupIcon from '@mui/icons-material/Group';
import StreetviewIcon from '@mui/icons-material/Streetview';
import CircularProgress from '@mui/material/CircularProgress';

function GetMediumIcon(medium) {
    switch (medium) {
      case "Online":
        return <TapAndPlayIcon />;
        case "In-Person":
          return <GroupIcon />;
          case "Hybrid":
            return <StreetviewIcon />;
      default:
        break;
    }
  }

function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default function ADUserItemRow(props) {
    const { userItemData, actionHandler } = props;
    const [buttonEnabled, setButtonEnabled ] = useState(true);
    const [submitStatus, setSubmitting] = useState({status:0});

    useEffect(() => {
        setButtonEnabled(userItemData.confirmButtonEnabled);
    }, [userItemData]);

    useEffect(() => {

        if(userItemData.authLevel !== undefined && userItemData.authLevel !== null && userItemData.authLevel !== 0) {
            setSubmitting({status:2});
        } else {
            if(submitStatus.status !== 1)
                setSubmitting({status:0});
        }
        
    }, [userItemData]);

    const options = {month: 'numeric', day: 'numeric' }
    let bgColor = '#FFFFFF';
    if(userItemData.elementID !== undefined) {
        bgColor = (userItemData.elementID % 2 === 0) ? '#F5F5F5' : '#FFFFFF';
    }

    let fontSizeTitle = 14;
    let fontSizeSubTitle = 12;

    const handleCreateInSF = (parameter) => (event) => {
        let userID = event.target.dataset.onclickparam;
        setSubmitting({status:1});
        actionHandler(userID);
    }

    const viewEventDetails = (event) => {
        //console.log('viewEventDetails: ' + JSON.stringify(event.target.dataset, null, 2));
        let eventID = event;

        console.log('viewEventDetails: ' + eventID);
    }

    function SubmitButtonIcon(props) {
        
        switch (props.submitStatus) {
            case 0:
                return null; 
            case 1:
                return (<CircularProgress size={20}/>);
            case 2:
                return (<CheckIcon />);
            default:
                return null;
        }
    }

    return (
        <Box onClick={() => viewEventDetails(userItemData.id)}>
            <Grid container p={1} sx={{backgroundColor:bgColor}}>
                <Grid item xs={4} sm={3}>
                    <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                        <Box>
                            
                        </Box>
                        <Box>
                            <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                <Box sx={{  fontSize: fontSizeTitle, textAlign:'left' }}>
                                    {userItemData.fName} {userItemData.lName}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                     
                <Grid item xs={4} sm={1} sx={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'center', alignItems:'center'}}>
                    <Box sx={{ fontSize: fontSizeTitle }}>
                        {userItemData.authLevel}
                    </Box>
                </Grid>

                <Grid item xs={12} sm={1} sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle }}>
                        {userItemData.trainingLevel}
                    </Box>
                </Grid>


                <Grid item xs={4} sm={1} sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle }}>
                        {userItemData.country}
                    </Box>
                </Grid>

                <Grid item xs={4} sm={2} sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle }}>
                    {new Date(userItemData.createdDate).toLocaleDateString(undefined, options)}
                    </Box>
                </Grid>


                <Grid item xs={4} sm={2} sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle }}>
                        New
                    </Box>
                </Grid>

                <Grid item xs={12} sm={2} sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                        <Box>
                            <Button size="small" variant="contained" color="primary" disabled={!buttonEnabled} data-onclickparam={userItemData.id} onClick={handleCreateInSF(userItemData.id)} endIcon={<SubmitButtonIcon submitStatus={submitStatus.status}/>}>Grant Access</Button>
                        </Box>
                    </Box>
                </Grid>

            </Grid>

        </Box>
    );
}