import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AldersgateContext } from '../../../context/aldersgate';
import CreateRegionPost from './CreateRegionPost';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import SchoolIcon from '@mui/icons-material/School';
import SettingsIcon from '@mui/icons-material/Settings';

export default function RegionTeams(props) {
    const { groupID } = props;

    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {
        GetTeamList();
    }, [groupID]);

    const GetTeamList = () => {
        aldersgateContext.GetFromAPI('group/getlist/childgroups/'+ groupID, null, 1).then((success, err) => {
            if(success) {
                
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/group/getlist/childgroups/'+ groupID + '?api-key=foo', {
        //     method: 'get',
        //     mode: 'cors', // no-cors, *cors, same-origin
        //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //     credentials: 'same-origin', // include, *same-origin, omit
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: null
        // }).then((res) => {
        //     return res.json();
        // }).then((jsonData, status) => {
        //     console.log(jsonData);

        // }).catch((err) => {
        //     console.log("TregionTeams: " + err);
        // });
    }

    return (
        <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', width:'100%'}}>
                <Box sx={{width:'100%', display:'flex', justifyContent:'center', flexDirection:'row', alignItems:'center',  gap:1}}>
                    <Box>
                        <Button variant="contained" startIcon={<PostAddIcon />}>
                            Create Post
                        </Button>
                    </Box>
                    <Box>
                    <Button variant="contained" startIcon={<ListAltIcon />}>
                        View Events
                    </Button>
                    </Box>
                    <Box>
                    <Button variant="contained" startIcon={<Diversity3Icon />}>
                        View Members
                    </Button>
                    </Box>
                    <Box>
                    <Button variant="contained" startIcon={<SchoolIcon />}>
                        View Alumni
                    </Button>
                    </Box>
                    <Box>
                    <Button variant="contained" startIcon={<SettingsIcon />}>
                    Manage Team
                    </Button>
                    </Box>
                </Box>
                <Box mt={2}>
                    <CreateRegionPost groupID={groupID}/>
                </Box>

        </Box>
    )
}