import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

export default function ChatItem(props) {
    const { adID, fName, lName, createDate, message } = props;
    let initials = "";
    let title = "";
    let messageText = "";

    if(fName !== undefined && lName !== undefined) {
        let fnArray = fName.split("");
        let lnArray = lName.split("");
        initials = (fnArray.length > 0 ? fnArray[0] : "") + (lnArray.length > 0 ? lnArray[0] : "");
        title = fName + " " + lName;
    }

    if(message !== undefined) {
        messageText = message;
    }

    if(createDate !== undefined) {
        if(title !== "") {
            title += " - " + createDate;
        } else {
            title = createDate;
        }
    }

    return (
        <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Remy Sharp">{initials}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {title}
              </Typography>
            </React.Fragment>
          }
          
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body1"
                color="text.primary"
              >
                {messageText}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
    );
}