import React, { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';

export default function PeopleFilter(props) {
    const currentYear = new Date().getFullYear();
    const firstDay = new Date(currentYear, 0, 1);
    const lastDay = new Date(currentYear, 11, 31);

    const { setFilterData, search } = props;

    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: 240 }}>
                <TextField id="name-filter" label="Name/Email" variant="outlined" size="small"
                    onChange={(newValue) =>{
                            setFilterData(prevState => {
                                return {...prevState, ['name']: newValue.target.value};
                            });
                        }
                    }
                />
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Button variant="outlined" size="large" onClick={search}>Search</Button>
            </FormControl>
    </div>
    )
}