
// This file is used to load the token from the session storage
const LoadAuthToken = () => {
    const tokenString = sessionStorage.getItem('ses_token'); //localStorage

    if(tokenString === null) {
        return {loggedIn:false, token:null, level:0, adID:null, expiry:null};
    }

    let userToken = JSON.parse(tokenString);
    userToken.loggedIn = true;
    console.log("LoadAuthToken: " + tokenString);
    return userToken;
}

const SaveAuthToken = (userToken) => {
    console.log("saving token: " + userToken);
    sessionStorage.setItem('ses_token', JSON.stringify(userToken)); //localStorage
}

const ClearAuthToken = () => {
    sessionStorage.removeItem('ses_token'); //localStorage
}

export { LoadAuthToken, SaveAuthToken, ClearAuthToken };