import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

export default function CenteredLoading(props) {

  let height = "240";

  if(props.height)
    height = props.height;

  return (
    <Grid container style={{height}}>
        <Grid item xs={12}>
            <Grid container spacing={0} align="center" justifyContent="center" direction="column" sx={{height:200, display:'flex', flexDirection:'column', justifyContent:'center'}}>
                <Grid item>
                    <CircularProgress />
                </Grid>
            </Grid>
        </Grid>
    </Grid>  
  );
}