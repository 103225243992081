import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import GroupIcon from '@mui/icons-material/Group';
import StreetviewIcon from '@mui/icons-material/Streetview';

function GetMediumIcon(medium) {
    switch (medium) {
      case "Online":
        return <TapAndPlayIcon />;
        case "In-Person":
          return <GroupIcon />;
          case "Hybrid":
            return <StreetviewIcon />;
      default:
        break;
    }
  }

export default function DReportEventListRow(props) {
    const { rowItemData } = props;

    console.log("***" + JSON.stringify(rowItemData, null, 2));

    const history = useHistory();

    const options = {month: 'numeric', day: 'numeric' }
    let bgColor = '#FFFFFF';
    if(rowItemData !== undefined && rowItemData.elementID !== undefined) {
        bgColor = (rowItemData.elementID % 2 === 0) ? '#F5F5F5' : '#FFFFFF';
    }

    let fontSizeTitle = 14;
    let fontSizeSubTitle = 12;

    const viewEventDetails = (event) => {
        let eventID = event;
        history.push("/event/view/" + eventID);
    }

    let trainedCount = 0;
    if(rowItemData.report) {
        trainedCount = rowItemData.report.completedParticipants;
    }

    return (
        <Box onClick={() => rowItemData.onRowClick(rowItemData.adID)}>
            <Grid container p={1} sx={{backgroundColor:bgColor,':hover': {backgroundColor:'#E0E0E0'}}} >
                <Grid item xs={3} sm={3}>
                    <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                        <Box>
                            <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                <Box sx={{  fontSize: fontSizeTitle, textAlign:'left' }}>
                                    {rowItemData.eventName}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                     
                <Grid item xs={3} sm={1} sx={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center'}}>
                    <Box sx={{ fontSize: fontSizeTitle }}>
                        {rowItemData.eventType}
                    </Box>
                </Grid>

                <Grid item xs={12} sm={2} sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle }}>
                        {rowItemData.eventMedium}
                    </Box>
                </Grid>

                <Grid item xs={12} sm={1} sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle }}>
                        {rowItemData.countryID}
                    </Box>
                </Grid>

                <Grid item xs={4} sm={2} sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle }}>
                    {new Date(rowItemData.startingDate).toLocaleDateString(undefined, options)} - {new Date(rowItemData.endingDate).toLocaleDateString(undefined, options)}
                    </Box>
                </Grid>

                <Grid item xs={4} sm={1} sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle }}>
                        {trainedCount}
                    </Box>
                </Grid>


                <Grid item xs={4} sm={2} sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle }}>
                        {rowItemData.eventStatus}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}