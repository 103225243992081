import React, {useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Box  from '@mui/material/Box';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import FilterIcon from '@mui/icons-material/Filter';
import DescriptionIcon from '@mui/icons-material/Description';
import Typography from '@mui/material/Typography';

export default function ResourcesDataGrid(props) {
    const { eventType } = props;
    const [fileList, setFileList] = useState([]);
    const [value, setValue] = useState(0);

    useEffect(() => {

        switch(eventType) {
            case "Journey":
                setFileList(GetHMJFiles());
                break;
            case "Module 1":
                setFileList(GetModuleOneFiles());
                break;
            case "Module 2":
                setFileList(GetModuleTwoFiles());
                break;
            case "Mobilization":
                
                break;
            default:
                break;
        }
        
    }, [value]);

    const GetHMJFiles = () => {
        let fileList = [];

        fileList.push({id:1, fName:"HMJ Book.pdf", fType:"pdf", url:"https://iliteam.box.com/shared/static/n9zbg7u8kt68l8rvpw445e17ppowqwv8.pdf", updated:"3/8/2023", size:5, downloads:10, hidden:false});
        fileList.push({id:2, fName:"The Vision.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/ikky6s0ap0ikk1jx96xr9ux7qqu1f7dx.pptx", updated:"10/23/2022", size:23.1, downloads:10, hidden:false});
        fileList.push({id:3, fName:"Intimacy.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/vkza7c09mmyl0sx9gxkksq8ggm8hq4u7.pptx", updated:"6/7/2022", size:12.6, downloads:10, hidden:false});
        fileList.push({id:4, fName:"Passion for the Harvest.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/vdkwqpmemia7dj1cmkhbdbnzva5643bi.pptx", updated:"2/23/2022", size:20, downloads:10, hidden:false});
        fileList.push({id:5, fName:"Acts 1.8 Workshop.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/rz1nq873ivr0ay8wkelaxzwm3fryzcol.pptx", updated:"2/23/2022", size:6.5, downloads:10, hidden:false});
        fileList.push({id:6, fName:"Spiritual Leadership.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/4oim01be31e1aa6ib2l06t7h72fluj7d.pptx", updated:"2/23/2022", size:8.2, downloads:10, hidden:false});
        fileList.push({id:7, fName:"Servant Leadership.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/r2u4g8mwxfrn886xdsw2c7urs1e4i1pc.pptx", updated:"10/13/2022", size:20.3, downloads:10, hidden:false});
        fileList.push({id:8, fName:"Transformational Leadership.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/o8inca2x7rj07casvbklghhrr9m82kkr.pptx", updated:"10/13/2022", size:5.9, downloads:10, hidden:false});
        fileList.push({id:9, fName:"The Power of Vision.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/ohk3quu60ge3whxk7aqk9bd7ah9e7xzn.pptx", updated:"3/23/2022", size:16.1, downloads:10, hidden:false});
        fileList.push({id:10, fName:"Vision Mission Workshop 1.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/1wiez2lb2m42n26sf91eh7q9d917sbng.pptx", updated:"3/23/2022", size:4.3, downloads:10, hidden:false});
        fileList.push({id:11, fName:"Vision and Mission Workshop 2.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/djnno0drb1q8ryw8zaktwqx3dexup742.pptx", updated:"2/24/2022", size:7.4, downloads:10, hidden:false});
        fileList.push({id:12, fName:"Goalsetting.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/6o1x54mgsz32vl6pv23xib89o73yozay.pptx", updated:"3/23/2022", size:23.3, downloads:10, hidden:false});
        fileList.push({id:13, fName:"Goalsetting Workshop.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/i1a5xueudkh4fynmjwpq2epnpg8q1d6s.pptx", updated:"11/2/2022", size:11.6, downloads:10, hidden:false});
        fileList.push({id:14, fName:"Mobilization.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/60owu0876kv0n67bhf4cr3jhjf7fz1xc.pptx", updated:"2/24/2022", size:8.2, downloads:10, hidden:false});
        fileList.push({id:15, fName:"Overcoming Obstacles.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/14e5pjbcwbxjg17lncngofh9cd716z8i.pptx", updated:"2/24/2022", size:20.5, downloads:10, hidden:false});
        fileList.push({id:16, fName:"Practice of Evangelism.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/29mh3lepgkend30khidxqx49vd76g6k2.pptx", updated:"5/16/2022", size:8.5, downloads:10, hidden:false});
        fileList.push({id:17, fName:"Relevant Evangelism_ELECTIVE.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/4v1ipp9a65e3u801rkivhs6mg8jysuhd.pptx", updated:"3/23/2022", size:16.9, downloads:10, hidden:false});
        fileList.push({id:18, fName:"Digital Evangelism_ELECTIVE.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/ytjy3qu16w5r9fpalyphqtz9u5fv6hh2.pptx", updated:"2/28/2022", size:10.6, downloads:10, hidden:false});
        fileList.push({id:19, fName:"Evangelism Workshop_ELECTIVE.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/cd3e016jhzqbskuloixyuyw8myjzsdao.pptx", updated:"2/28/2022", size:12.3, downloads:10, hidden:false});
        fileList.push({id:20, fName:"Multiplication By Discipleship.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/vsoypzxvvlt441vwyqqkkoau3fitbgfv.pptx", updated:"2/28/2022", size:8.2, downloads:10, hidden:false});
        fileList.push({id:21, fName:"Multiplication by Coaching_ELECTIVE.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/av3l3wa263lujeknwjuy2ff38cxkgsm4.pptx", updated:"2/28/2022", size:23.4, downloads:10, hidden:false});
        fileList.push({id:22, fName:"Multiplication By Mentoring.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/bu7ci54qowkrdjqdg1r44tddj0e6lkna.pptx", updated:"2/28/2022", size:9.5, downloads:10, hidden:false});
        fileList.push({id:23, fName:"Multiplication Workshop.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/myqkw6g6tuzud8u8vc6wqnvna4l26mhi.pptx", updated:"10/13/2022", size:12.7, downloads:10, hidden:false});
        fileList.push({id:24, fName:"Family Priority.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/4uaqy1vnqqtde10m2ibrhgnoxi1c9333.pptx", updated:"2/28/2022", size:45.7, downloads:10, hidden:false});
        fileList.push({id:25, fName:"Growing a Godly Family_ELECTIVE.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/nb54v3pgl79fysi0ee3k7glznz7iloyd.pptx", updated:"2/28/2022", size:41.2, downloads:10, hidden:false});
        fileList.push({id:26, fName:"Family and Redemption_ELECTIVE.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/rtjf5issrhtm1g80i2uguze3ct9z6rri.pptx", updated:"2/28/2022", size:13.1, downloads:10, hidden:false});
        fileList.push({id:27, fName:"Family Priority Workshop_ELECTIVE.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/3yh2febml4syf5fkk7g42y72xe5g6bcp.pptx", updated:"6/6/2022", size:7.2, downloads:10, hidden:false});
        fileList.push({id:28, fName:"Faithful Stewardship.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/x5yrg9mhusugedbx5mt66gweu80982hu.pptx", updated:"6/7/2022", size:36.3, downloads:10, hidden:false});
        fileList.push({id:29, fName:"Stewardship of Time.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/ceudm1p56qk8968d99djbcslen63hq9j.pptx", updated:"3/1/2022", size:12.6, downloads:10, hidden:false});
        fileList.push({id:30, fName:"Spiritual Gifts_ELECTIVE.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/82p9zgn4exw6s5i0q2c4pylsxop6gjpr.pptx", updated:"3/24/2022", size:15.1, downloads:10, hidden:false});
        fileList.push({id:31, fName:"Spiritual Gifts Workshop_ELECTIVE.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/k3ekd6vrd2ceom9ga71hvnod491e7ra8.pptx", updated:"3/24/2022", size:5.4, downloads:10, hidden:false});
        fileList.push({id:32, fName:"Integrity.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/d7yatqkw16gll7c7yoqk17ujbrzha59w.pptx", updated:"3/1/2022", size:13.3, downloads:10, hidden:false});
        fileList.push({id:33, fName:"Finishing Well.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/d0bcr8mahjlb6gl07zb70u78nvp8eptb.pptx", updated:"3/2/2022", size:16.4, downloads:10, hidden:false});
        fileList.push({id:34, fName:"Name Tags.docx", fType:"docx", url:"https://iliteam.box.com/shared/static/bv2u3s2ip525bwkelri68fzss77zn1t1.docx", updated:"4/10/2022", size:0.1, downloads:10, hidden:false});
        fileList.push({id:35, fName:"HMJ_PLANNING GUIDE.docx", fType:"docx", url:"https://iliteam.box.com/shared/static/hmebgmg061lpr3y2p01qgw139ubggnhe.docx", updated:"8/16/2022", size:0.1, downloads:10, hidden:false});
    
        return fileList;
    }

    const GetModuleOneFiles = () => {
        let fileList = [];

        fileList.push({id:1, fName:"Module 1 Book.pdf", fType:"pdf", url:"https://iliteam.box.com/shared/static/v8ry3hncnbgtofvcuwl3s0q96hlls26s.pdf", updated:"3/9/2023", size:3.2, downloads:10, hidden:false});
        fileList.push({id:2, fName:"The Vision.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/ikky6s0ap0ikk1jx96xr9ux7qqu1f7dx.pptx", updated:"10/23/2022", size:23.1, downloads:10, hidden:false});
        fileList.push({id:3, fName:"Intimacy.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/vkza7c09mmyl0sx9gxkksq8ggm8hq4u7.pptx", updated:"6/7/2022", size:12.6, downloads:10, hidden:false});
        fileList.push({id:4, fName:"Passion for the Harvest.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/vdkwqpmemia7dj1cmkhbdbnzva5643bi.pptx", updated:"2/23/2022", size:20, downloads:10, hidden:false});
        fileList.push({id:6, fName:"Spiritual Leadership.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/4oim01be31e1aa6ib2l06t7h72fluj7d.pptx", updated:"2/23/2022", size:8.2, downloads:10, hidden:false});
        fileList.push({id:9, fName:"The Power of Vision.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/ohk3quu60ge3whxk7aqk9bd7ah9e7xzn.pptx", updated:"3/23/2022", size:16.1, downloads:10, hidden:false});
        fileList.push({id:10, fName:"Vision Mission Workshop 1.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/1wiez2lb2m42n26sf91eh7q9d917sbng.pptx", updated:"3/23/2022", size:4.3, downloads:10, hidden:false});
        fileList.push({id:12, fName:"Goalsetting.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/6o1x54mgsz32vl6pv23xib89o73yozay.pptx", updated:"3/23/2022", size:23.3, downloads:10, hidden:false});
        fileList.push({id:15, fName:"Overcoming Obstacles.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/14e5pjbcwbxjg17lncngofh9cd716z8i.pptx", updated:"2/24/2022", size:20.5, downloads:10, hidden:false});
        fileList.push({id:16, fName:"Practice of Evangelism.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/29mh3lepgkend30khidxqx49vd76g6k2.pptx", updated:"5/16/2022", size:8.5, downloads:10, hidden:false});
        fileList.push({id:20, fName:"Multiplication By Discipleship.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/vsoypzxvvlt441vwyqqkkoau3fitbgfv.pptx", updated:"2/28/2022", size:8.2, downloads:10, hidden:false});
        fileList.push({id:22, fName:"Multiplication By Mentoring.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/bu7ci54qowkrdjqdg1r44tddj0e6lkna.pptx", updated:"2/28/2022", size:9.5, downloads:10, hidden:false});
        fileList.push({id:23, fName:"Multiplication Workshop.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/myqkw6g6tuzud8u8vc6wqnvna4l26mhi.pptx", updated:"10/13/2022", size:12.7, downloads:10, hidden:false});
        fileList.push({id:24, fName:"Family Priority.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/4uaqy1vnqqtde10m2ibrhgnoxi1c9333.pptx", updated:"2/28/2022", size:45.7, downloads:10, hidden:false});
        fileList.push({id:28, fName:"Faithful Stewardship.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/x5yrg9mhusugedbx5mt66gweu80982hu.pptx", updated:"6/7/2022", size:36.3, downloads:10, hidden:false});
        fileList.push({id:33, fName:"Finishing Well.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/d0bcr8mahjlb6gl07zb70u78nvp8eptb.pptx", updated:"3/2/2022", size:16.4, downloads:10, hidden:false});
        fileList.push({id:34, fName:"Name Tags.docx", fType:"docx", url:"https://iliteam.box.com/shared/static/bv2u3s2ip525bwkelri68fzss77zn1t1.docx", updated:"4/10/2022", size:0.1, downloads:10, hidden:false});
        fileList.push({id:35, fName:"MODULE 1 TRAINING PLANNING GUIDE.docx", fType:"docx", url:"https://iliteam.box.com/shared/static/sllsdgp1jikr6wa8ijasuqw8ayqyc3ao.docx", updated:"8/16/2022", size:0.1, downloads:10, hidden:false});
    
        return fileList;
    }

    const GetModuleTwoFiles = () => {
        let fileList = [];

        fileList.push({id:1, fName:"Module 2 Book.pdf", fType:"pdf", url:"https://iliteam.box.com/shared/static/cf4clni1kugpp9g21qur5g6xhu7tt0cj.pdf", updated:"3/9/2023", size:3.6, downloads:10, hidden:false});
        fileList.push({id:2, fName:"The Vision Review.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/ikky6s0ap0ikk1jx96xr9ux7qqu1f7dx.pptx", updated:"10/23/2022", size:16.6, downloads:10, hidden:false});
        fileList.push({id:5, fName:"Acts 1.8 Workshop.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/rz1nq873ivr0ay8wkelaxzwm3fryzcol.pptx", updated:"2/23/2022", size:6.5, downloads:10, hidden:false});
        fileList.push({id:7, fName:"Servant Leadership.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/r2u4g8mwxfrn886xdsw2c7urs1e4i1pc.pptx", updated:"2/23/2022", size:20.3, downloads:10, hidden:false});
        fileList.push({id:8, fName:"Transformational Leadership.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/o8inca2x7rj07casvbklghhrr9m82kkr.pptx", updated:"10/13/2022", size:5.9, downloads:10, hidden:false});
        fileList.push({id:11, fName:"Vision and Mission Workshop 2.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/djnno0drb1q8ryw8zaktwqx3dexup742.pptx", updated:"2/24/2022", size:7.4, downloads:10, hidden:false});
        fileList.push({id:12, fName:"Goalsetting.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/6o1x54mgsz32vl6pv23xib89o73yozay.pptx", updated:"3/23/2022", size:23.3, downloads:10, hidden:false});
        fileList.push({id:13, fName:"Goalsetting Workshop.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/i1a5xueudkh4fynmjwpq2epnpg8q1d6s.pptx", updated:"11/2/2022", size:11.6, downloads:10, hidden:false});
        fileList.push({id:14, fName:"Mobilization.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/60owu0876kv0n67bhf4cr3jhjf7fz1xc.pptx", updated:"2/24/2022", size:8.2, downloads:10, hidden:false});
        fileList.push({id:17, fName:"Relevant Evangelism_ELECTIVE.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/4v1ipp9a65e3u801rkivhs6mg8jysuhd.pptx", updated:"3/23/2022", size:16.9, downloads:10, hidden:false});
        fileList.push({id:18, fName:"Digital Evangelism_ELECTIVE.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/ytjy3qu16w5r9fpalyphqtz9u5fv6hh2.pptx", updated:"2/28/2022", size:10.6, downloads:10, hidden:false});
        fileList.push({id:19, fName:"Evangelism Workshop_ELECTIVE.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/cd3e016jhzqbskuloixyuyw8myjzsdao.pptx", updated:"2/28/2022", size:12.3, downloads:10, hidden:false});
        fileList.push({id:21, fName:"Multiplication by Coaching_ELECTIVE.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/av3l3wa263lujeknwjuy2ff38cxkgsm4.pptx", updated:"2/28/2022", size:23.4, downloads:10, hidden:false});
        fileList.push({id:22, fName:"Multiplication By Mentoring.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/bu7ci54qowkrdjqdg1r44tddj0e6lkna.pptx", updated:"2/28/2022", size:9.5, downloads:10, hidden:false});
        fileList.push({id:23, fName:"Multiplication Workshop.pptx", fType:"ppt", url:"rhttps://iliteam.box.com/shared/static/myqkw6g6tuzud8u8vc6wqnvna4l26mhi.pptx", updated:"10/13/2022", size:12.7, downloads:10, hidden:false});
        fileList.push({id:25, fName:"Growing a Godly Family_ELECTIVE.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/nb54v3pgl79fysi0ee3k7glznz7iloyd.pptx", updated:"2/28/2022", size:41.2, downloads:10, hidden:false});
        fileList.push({id:26, fName:"Family and Redemption_ELECTIVE.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/rtjf5issrhtm1g80i2uguze3ct9z6rri.pptx", updated:"2/28/2022", size:13.1, downloads:10, hidden:false});
        fileList.push({id:27, fName:"Family Priority Workshop_ELECTIVE.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/3yh2febml4syf5fkk7g42y72xe5g6bcp.pptx", updated:"6/6/2022", size:7.2, downloads:10, hidden:false});
        fileList.push({id:29, fName:"Stewardship of Time.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/ceudm1p56qk8968d99djbcslen63hq9j.pptx", updated:"3/1/2022", size:12.6, downloads:10, hidden:false});
        fileList.push({id:30, fName:"Spiritual Gifts_ELECTIVE.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/82p9zgn4exw6s5i0q2c4pylsxop6gjpr.pptx", updated:"3/24/2022", size:15.1, downloads:10, hidden:false});
        fileList.push({id:31, fName:"Spiritual Gifts Workshop_ELECTIVE.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/k3ekd6vrd2ceom9ga71hvnod491e7ra8.pptx", updated:"3/24/2022", size:5.4, downloads:10, hidden:false});
        fileList.push({id:32, fName:"Integrity.pptx", fType:"ppt", url:"https://iliteam.box.com/shared/static/d7yatqkw16gll7c7yoqk17ujbrzha59w.pptx", updated:"3/1/2022", size:13.3, downloads:10, hidden:false});
        fileList.push({id:34, fName:"Name Tags.docx", fType:"docx", url:"https://iliteam.box.com/shared/static/bv2u3s2ip525bwkelri68fzss77zn1t1.docx", updated:"4/10/2022", size:0.1, downloads:10, hidden:false});
        fileList.push({id:35, fName:"MODULE 2 TRAINING PLANNING GUIDE.docx", fType:"docx", url:"https://iliteam.box.com/shared/static/g5xu9zzrpuqwxaygxcq6zgus3191680k.docx", updated:"8/16/2022", size:0.1, downloads:10, hidden:false});
    
        return fileList;
    }

    const RenderFilename = (paramData) => {
        const { rowData } = paramData;

        const IconRender = () => {
            switch(rowData.fType) {
                case "pdf":
                    return <MenuBookIcon sx={{ color:'blue' }} />;
                case "ppt":
                    return <FilterIcon sx={{ color:'red' }} />;
                case "docx":
                    return <DescriptionIcon sx={{ color:'lightblue' }} />;
                case "doc":
                    return <DescriptionIcon sx={{ color:'lightblue' }} />;
                default:
                    return <FilterIcon sx={{ color:'red' }} />;
            }
        }

        return (
            <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                    <IconRender />
                </Box>
                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                    <Box>
                    <a href={rowData.url} style={{ color:'black' }} target="_blank" rel="noreferrer" download>{rowData.fName}</a>
                    </Box>
                </Box>
            </Box> )

    }

    const columns = [
        { field: 'fName', headerName: 'File', flex: 3, editable: false, renderCell: (params) => (
            <RenderFilename rowData={params.row} />) },
        { field: 'updated', headerName: 'Updated', flex: 0.5,editable: false },
        { field: 'size', headerName: 'Size', flex: 0,editable: false, renderCell: (params) => (
            <Box>
                {params.row.size.toFixed(1)} MB
            </Box>
            ) }
    ];

    const handleRowClick = (params) => {


      };
    
    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <Paper elevation={1}>
                <DataGrid onRowClick={handleRowClick} rows={fileList} columns={columns} 
                    rowsPerPageOptions={[25, 50, 75]}
                    pagination
                    autoHeight
                />
            </Paper> 
        </Box>
    )

}