import React from "react";
import { makeStyles } from '@mui/styles';
import UserRegistration from "../components/user/UserRegistration";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';



const useStyles = makeStyles({
        root: {
            flexGrow: 1,
        }
    });

    export default function RegisterUser(props) {
        const classes = useStyles();

        return (
          <div className={classes.root}>
            <Box m={1.5} bgcolor="background.paper" >
                <Grid container >
                    <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                
                                <UserRegistration />

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                    </Grid>
                </Grid>
            </Box>
        </div>
      )
  }