import React, {useState, useEffect, useContext } from 'react';
import SophConstants from '../../constants/SophConstants';
import { AldersgateContext } from '../../context/aldersgate';
import AuthContext from '../../context/AuthContext';
import AddEventToReport from './AddEventToReport';
import DReportEventListAdmin from './DReportEventListAdmin/DReportEventListAdmin';
import ReportApprovalAdmin from './ReportApprovalAdmin';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ChatView from '../Chatbox/ChatView';
import Paper from '@mui/material/Paper';
import ReportingViewDatagrid from './ReportingViewDatagrid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import NativeSelect from '@mui/material/NativeSelect';
import AddIcon from '@mui/icons-material/Add';
import Box  from '@mui/material/Box';
import Modal from '@mui/material/Modal';



export default function ViewAssignedReportAdmin(props) {
    const { reportData, setFinalized, isLoading } = props;

    const [formDisabled, setFormDisabled] = useState(true);
    const [events, setEvents] = useState([...reportData.events]);
    const [value, setValue] = useState(0);
    const [sumbitStatus, setSubmitStatus] = useState(0);
    const [submitForm, setSubmitForm] = useState({confirm:false, userName:''});
    const aldersgateContext = useContext(AldersgateContext);

    const authContext = React.useContext(AuthContext);

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    let assignedName  = "";
    let groupMembers = [];
    let groupOwner = {};
    let assignedReportID = undefined;

    let titleSubtext = "Reporting";
    let dateRange = "N/A";

    const SetApprovedEvent = (eventID, approvedStatus) => {
        let newEvents = [...events];

        let eventIndex = newEvents.findIndex((event) => {
            return event.eventID === eventID;
        });

        if(eventIndex !== -1) {
            newEvents[eventIndex].validEvent = approvedStatus;
        }

        setEvents(prev => {
            return [...newEvents];
        });
    }

    const SetAllApproved = (approvedStatus) => {
        let newEvents = [...events];

        newEvents.forEach((event) => {
            event.validEvent = true;
        });

        setEvents(prev => {
            return [...newEvents];
        });
    }

    if(reportData !== undefined) {
        assignedName = reportData.fName + " " + reportData.lName;

        titleSubtext = "Reporting - Q" + reportData.quarter + " " + reportData.year;

        if(reportData.quarter !== undefined) {
            //get date range from quarter
            let dateRangeStart = new Date(reportData.year, (reportData.quarter - 1) * 3, 1);
            let dateRangeEnd = new Date(reportData.year, (reportData.quarter - 1) * 3 + 3, 0);

            dateRange = dateRangeStart.toLocaleDateString() + " - " + dateRangeEnd.toLocaleDateString();
        }

        groupOwner = reportData.owner;
        assignedReportID = reportData.adID;
    }

    const handleEventButtonToggle = (event, name) => {
        setFormDisabled(false);
    }

    const handleCloseForm = () => {
        setFormDisabled(true);
    }

    const addEventForm = (disableForm) => {
            const modalStyle = {
                width: (!smMatches) ? '80%' : '70%',
                minHeight: '50vh', maxHeight: '80vh',
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
                overflow:'scroll'
              };

            return (
                <Modal
                    open={!disableForm}
                    onClose={handleCloseForm}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ display:'flex', alignItems:'center',justifyContent:'center'}}
                >
                    <Box sx={modalStyle}>
                        <AddEventToReport reportData={reportData} setEvents={setEvents} handleCloseForm={handleCloseForm}/>
                    </Box>
                </Modal>
            );

    }

    const OnSubmitReport = () => {
        setSubmitStatus(1);

        let approvedEvents = [];
        events.forEach((event) => {
            if(event.validEvent === true) {
                approvedEvents.push(event.eventID);
            }
        });

        let submitData = {
            approvedEvents: approvedEvents,
            approveUser: authContext.accountState.adID ? authContext.accountState.adID : undefined,
        }

        aldersgateContext.PostToAPI('reports/assigned/approve/' + reportData.adID, submitData, null, 1).then((success, err) => {
            if(success) {
                setSubmitStatus(2);
            
                if(success.finalized === true) {
                    setFinalized(true);
                }
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/reports/assigned/approve/' + reportData.adID + '?api-key=foo', {

    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography component="h2" variant="h4" color="textSecondary">{assignedName}</Typography>
                <Typography variant="body1"  color="textSecondary">{titleSubtext}</Typography>
                <Typography variant="body1"  color="textSecondary">{dateRange}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={2}>
                <Box sx={{ display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', width:'100%' }}>
                    <Box sx={{width:'100%'}}>
                        <DReportEventListAdmin eventData={events} SetApprovedEvent={SetApprovedEvent} SetAllApproved={SetAllApproved}/>
                    </Box>
                    <Box mb={2} mt={1}>
                        { reportData.finalized ? null :<Button onClick={handleEventButtonToggle} size="medium" variant="contained" color="primary" startIcon={<AddIcon />}>Add Event</Button>}
                    </Box>
                    </Box>
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <Box sx={{width:'100%', background:'#f1f1f1'}}>
                { reportData.approvedDate != null ? (
                    <Box sx={{ display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', alignContent:'center',  padding:'10px', gap:'10px' }}>
                        <Box>
                            <Typography variant="h6" color="textSecondary">Report Approved</Typography>
                            <Typography mb={2} variant="body1" color="textSecondary">This report has been submitted and approved</Typography>
                        </Box>

                    </Box>
                    ) : (
                        <ReportApprovalAdmin reportData={reportData} submitReport={OnSubmitReport} />
                    )}
                </Box>
            </Grid>

            <Grid item xs={12}>
                    {addEventForm(formDisabled)}
            </Grid>
        </Grid>
    );

}