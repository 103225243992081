import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';

export default function ReportsAssignedReportsDatagrid(props) {
    const { assignedReports } = props;

    const history = useHistory();

    const columns = [
      { field: 'adID', headerName: '', width: 100,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              history.push("reports/view/" + params.value);
            }}
            style={{ marginLeft: 16 }}
          >
            View
          </Button>
        </strong> )},
      { field: 'fName', headerName: 'Leader', flex: 1, editable: false,
        renderCell: (params) => {
        return (<div>{params.row.fName} {params.row.lName}</div>);
        } },
      { field: 'countryID', headerName: 'Location', flex: 1, editable: false },
      { field: 'events', headerName: 'Events', flex: 1, editable: false },
      { field: 'reportStatus', headerName: 'Status', flex: 1, editable: false,
      renderCell: (params) => {
          let status = "";
          switch(params.row.status) {
                case 0:
                    status = "Assigned";
                    break;
                case 1:
                    status = "In Progress";
                    break;
                case 2:
                    status = "Submitted";
                    break;
                case 3:
                    status = "Accepted";
                    break;
                default:
                    status = "Unknown";
                    break;
            }
        return (<div>{status}</div>);
      } },
  ];
  
  return (
    <div>
      <div style={{ height: 250, width: '100%' }}>
      <DataGrid columns={columns} rows={assignedReports} />
      </div>
    </div>
  );
}