module.exports = Object.freeze({
    // API_ADDR: 'http://192.168.1.110:4000',
    // PUBLIC_ADDR: 'http://192.168.1.110:3000',
    // API_ADDR: 'http://localhost:4000',
    // PUBLIC_ADDR: 'http://localhost:3000',
    API_ADDR: 'https://aldersgate-api.herokuapp.com',
    PUBLIC_ADDR: 'https://Training.ILITeam.org',
    GROUP_ROLE_OWNER: 5,
    GROUP_ROLE_ADMIN: 4,
    GROUP_ROLE_NCOORDINATOR: 3,
    GROUP_ROLE_MEMBER: 2,
    GROUP_ROLE_GUEST: 1,
    GROUP_ROLE_NONE: 0,
    BANNEROPTIONS: [
        {value: 0, sx:{position:"relative",
            margin:"0 auto",
            backgroundImage: `url(${process.env.PUBLIC_URL + '/reghero.png'})`,
            backgroundRepeat  : 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize:"cover",
            justifyContent: "center"}, 
        label: "Image"},
        {value: 1, sx:{background: 'linear-gradient(to right bottom, #36EAEF, #6B0AC9)'}, label: "Gradient"},
        {value: 2, sx:{background: 'linear-gradient(to right bottom, #4158D0, #C850C0, #FFCC70)'}, label: "Gradient"},
        {value: 3, sx:{background: 'linear-gradient(to right bottom, #8EC5FC, #E0C3FC)'}, label: "Gradient"},
        {value: 4, sx:{background: 'linear-gradient(to right bottom, #FF3CAC, #784BA0, #2B86C5)'}, label: "Gradient"},
        {value: 5, sx:{background: 'linear-gradient(to right bottom, #FBAB7E, #F7CE68)'}, label: "Gradient"},
        {value: 6, sx:{background: 'linear-gradient(to right bottom, #FEB692, #EA5455)'}, label: "Gradient"},
        {value: 7, sx:{background: 'linear-gradient(to right bottom, #F093FB, #F5576C)'}, label: "Gradient"},
        {value: 8, sx:{background: 'linear-gradient(to right bottom, #5EE7DF, #B490CA)'}, label: "Gradient"},
        {value: 9, sx:{background: '#3573b9'}, label: "Solid"},
        {value: 10, sx:{background: '#aaaaaa'}, label: "Solid"},
        {value: 11, sx:{background: '#ffffff'}, label: "Solid"},
    ],
    slt: "$2a$10$Vlgl5XtUdarWg7cs3322uO"
});

//https://aldersgate-api.herokuapp.com
//http://192.168.1.110:4000
//pub: :3000

/*

Production
    API_ADDR: 'https://aldersgate-api.herokuapp.com',
    PUBLIC_ADDR: 'https://ili-training.herokuapp.com',

Development
    API_ADDR: 'http://192.168.1.110:4000',
    PUBLIC_ADDR: 'http://192.168.1.110:3000',

*/