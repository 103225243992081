import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function PrivacyView(props) {

    //default privacy policy for website use
    return (
        <Box  >
            <Typography variant="h5" component="h2" color="textSecondary" gutterBottom>Privacy Policy</Typography>
            <Typography variant="h5" component="h2" color="textSecondary" align="left" mt={3}>HOW ILI USES YOUR DATA</Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            ILI may combine the information you provide on the site with other information we may collect from you, so that we can more effectively tailor the site to your needs.  ILI may from time to time contact you and/or send information to you by telephone and/or e-mail with respect to the matters addressed by the site, in order to facilitate the sharing of information between ILI and you.
            </Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left" mt={1}>
            You may request to be removed from any future contact by ILI by notifying the site administrator by email.  ILI will take prompt steps to the best of our ability to remove you from all ILI's contact lists or just some as you request.
            </Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left" mt={1}>
            If you sign up for an event on the site as a participant or a facilitator, the information you share will be available to the person or people who created the training event signup. When giving this information, you give the person or people who created, are overseeing, or facilitating the event permission to use that information to contact you regarding the event and other leadership training opportunities. ILI will not share any personal information you provide on the site with any parties other than its advisors and affiliates, except (a) with your consent; (b) as may be required by law or court order or as requested by a law enforcement agency; (c) to protect the personal safety, security or property of our users, the public, or ourselves; or (d) to respond to an emergency. 
            </Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left" mt={1}>
            ILI may also collect, aggregate, and maintain anonymous information about the visitors to the site.  This data can then be used to tailor the site's content to deliver a better experience for our visitors.
            </Typography><Typography variant="p" component="p" color="textSecondary" align="left" mt={1}>
            Although ILI takes measures to safeguard against the unauthorized disclosure of your personal information, ILI cannot assure you that personally identifiable information will not be disclosed in a manner that is inconsistent with this privacy policy.
            </Typography>
            <Typography variant="h5" component="h2" color="textSecondary" align="left" mt={3}>COOKIES</Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            ILI may use information to understand visitor traffic patterns through the site for purposes such as enhancing our usability. Your computer also provides some information automatically each time you log on, unless you have activated blocking technology available in some browsers. When you visit ILI's site, or use one or more of the services of the site, International Leadership Institute uses an industry-wide technology called “cookies” to store certain information on your computer.  This allows us to customize your experience on the site.  Most browsers will allow you to erase cookies from your computer hard drive, block acceptance of cookies or receive a warning before a cookie is stored.
            </Typography>
            <Typography variant="h5" component="h2" color="textSecondary" align="left" mt={3}>COOKIES AND THIER PURPOSE</Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            ILI uses cookies to store information about your preferences and navigation to and from and on our site.  Cookies are used to identify you and maintain your signed-in status.  Cookies also help us understand how people use our site, such as which pages are most popular, so that we can better serve our site users.
            </Typography>
            <TableContainer mt={4}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Cookie</TableCell>
                            <TableCell>Purpose</TableCell>
                            <TableCell>Information Collected</TableCell>
                            <TableCell>How to Withdraw Consent</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>ses_token</TableCell>
                            <TableCell>To authenticate you when signed in to the Service</TableCell>
                            <TableCell>Randomly generated data allowing the server to identify you</TableCell>
                            <TableCell>Do not use our Services if you do not want to receive this cookie</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>cookie2</TableCell>
                            <TableCell>purpose2</TableCell>
                            <TableCell>information2</TableCell>
                            <TableCell>Do not use our Services if you do not want to receive this cookie</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>


            <Typography variant="h5" component="h2" color="textSecondary" align="left" mt={3}>THIRD PARTY LINKS</Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            The site may contain links to other sites.  ILI is not responsible for the privacy practices or the content of such websites.  ILI does not share any of the individual personal information you provide ILI with the sites to which ILI links, although ILI may share aggregate data with such sites (such as how many people use the site).  This privacy policy applies solely to information collected by ILI.
            </Typography>
            <Typography variant="h5" component="h2" color="textSecondary" align="left" mt={3}>SECURITY</Typography>
            <Typography variant="p" component="p" color="textSecondary" align="left">
            ILI has implemented technology and security features and strict policy guidelines to safeguard the privacy of your personally identifiable information from unauthorized access or improper use, and we will continue to enhance our security procedures as new technology becomes available.  ILI will continue to update these measures as new technology becomes available.
            </Typography>
        </Box>
    );

}