import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AldersgateContext } from '../../../../../context/aldersgate';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';



export default function SelectedEventModalView(props) {
    const { eventData, closeModal } = props;

    console.log(JSON.stringify(eventData, null, 2));

    console.log(JSON.stringify(eventData, null, 2));
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [regionList, setRegionList] = useState([]);
    const [teamList, setTeamList] = useState([]);

    const aldersgateContext = useContext(AldersgateContext);
    const history = useHistory();

    useEffect(() => {
        GetRegionList();
    }, []);

    useEffect(() => {
        if(regionList.length > 0)
            GetTeamList();

    }, [selectedRegion]);

    const GetRegionList = () => {
        aldersgateContext.GetFromAPI('group/getlist/regions', '', 1).then((success, err) => {
            if(success) {
                console.log(success);
                setRegionList(success);

                if(success.length > 0){
                    if(eventData.regionID !== undefined) {
                        setSelectedRegion(eventData.regionID);
                    } else {
                        setSelectedRegion(success[0].adID)
                    }
                }
            }

            if(err) {
                console.log("Error: " + err);
            }
        }).catch((error) => {
            console.log("Error: " + error);
        });
    }

    const GetTeamList = () => {     
        aldersgateContext.GetFromAPI('group/getlist/childgroups/' + selectedRegion, '', 1).then((success, err) => {
            if(success) {
                setTeamList(success);
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/group/getlist/childgroups/'+ selectedRegion + '?api-key=foo', {
    }

    const SetSelectedRegion = (e) => {
        setSelectedRegion(e.target.value);
    }

    const SetSelectedGroup = (e) => {
        if(e.target.value == "Region") {
            setSelectedGroup(selectedRegion);
        } else {
            setSelectedGroup(e.target.value);
        }
    }

    const PrettyDate = (date) => {
        let d = new Date(date);
        let month = d.toLocaleString('default', { month: 'long' });
        let day = d.getDate();
        let year = d.getFullYear();

        return month + " " + day + ", " + year;
    }

    const GetLocationString = ()=> {
        let locationString = [];
        if(eventData.countryID !== undefined) {
            if(eventData.city !== undefined && eventData.city !== null && eventData.city !== "")
                locationString.push(eventData.city);
            if(eventData.state !== undefined && eventData.state !== null && eventData.state !== "")
                locationString.push(eventData.state);
            if(eventData.countryID !== undefined && eventData.countryID !== null && eventData.countryID !== "")
                locationString.push(eventData.countryID);
        }

        let returnString = locationString.join(", ");
        if(returnString === "") {
            return "Unknown Location";
        } else {
            return (
                <Link href={"https://www.google.com/maps/search/" + returnString} target="_blank" rel="noreferrer">
                    {returnString}
                </Link>
            );
        }


    }

    const OnAcceptReport = () => {
        console.log("Accept Report");

        // Call API to accept report
        aldersgateContext.PostToAPI('admin/conference/report', {adID:eventData.id, region:selectedRegion, starfishTeam:selectedGroup, astatus:1}, null, 1).then((response) => {
            console.log(response);
            eventData?.refreshCallback();
        }).catch((error) => {
            console.log(error);
            eventData?.refreshCallback();
        });

    }

    const OnRejectReport = () => {
        console.log("Reject Report");
        // Call API to accept report
        aldersgateContext.PostToAPI('admin/conference/report', {adID:eventData.id, astatus:2}, null, 1).then((response) => {
            console.log(response);
            //refresh the list
            eventData?.refreshCallback();
            closeModal();
        }).catch((error) => {
            console.log(error);
            eventData?.refreshCallback();
            closeModal();
        });
    }

    const OnViewEvent = () => {
        history.push("/event/view/" + eventData.eventID);
    }

    if(eventData === undefined) {
        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4" component="h4">
                            No Event Data
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5" component="h5">
                        {eventData?.eventName}
                    </Typography>
                    <hr />
                    <Typography variant="body1" component="p">
                        {PrettyDate(eventData?.startingDate)} - {PrettyDate(eventData?.endingDate)}
                    </Typography>
                    <Typography variant="body1" component="p">
                        {GetLocationString()}
                    </Typography>
                    <Typography variant="body1" component="p">
                        {eventData.eventType} - {eventData.eventMedium}
                    </Typography>

                </Grid>
                <Grid item xs={12}>
                    <FormControl>
                        <InputLabel variant="standard" htmlFor="nativeSelect-region">
                        Region
                        </InputLabel>
                        <NativeSelect
                        value={selectedRegion}
                        inputProps={{
                            name: 'Region',
                            id: 'nativeSelect-region',
                        }}
                        onChange={SetSelectedRegion}
                        >
                            {regionList.map((region) => {
                                return <option key={region.adID} value={region.adID}>{region.name}</option>
                            })}
                        </NativeSelect>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    {teamList !== undefined && teamList.length > 0 ? (
                    <FormControl>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Team
                        </InputLabel>
                        <NativeSelect
                        value={selectedGroup}
                        inputProps={{
                            name: 'Team',
                            id: 'uncontrolled-native',
                        }}
                        onChange={SetSelectedGroup}
                        >
                            <option value="Region">All</option>
                            {teamList.map((team) => {
                                return <option key={team.adID} value={team.adID}>{team.name}</option>
                            })}
                        </NativeSelect>
                    </FormControl>
                    ) : null}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                        Leader
                    </Typography>
                    <Typography variant="body1" component="p">
                        {eventData.ownerName}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                        Report Submitted
                    </Typography>
                    <Typography variant="body1" component="p">
                        {PrettyDate(eventData?.createdAt)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                        Participants
                    </Typography>
                    <Typography variant="body1" component="p">
                        {eventData.completedParticipants} Completed Participants
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{maxHeight: 200, overflow: 'auto', padding:5}}>
                        <Typography variant="p" component="p" sx={{fontWeight: 600}}>
                            List {eventData?.participants?.length ? '(' + eventData?.participants?.length + ')' : null}:
                        </Typography>
                        {eventData?.participants?.length === 0 ? <Typography variant="body1" component="p">No Participants Registered</Typography> : 
                        <List>
                            {eventData?.participants?.map((participant, index) => (
                                <Box key={index}>
                                    <Typography variant="body1" component="p">
                                        {participant.fName} {participant.lName} - {participant.completed ? "Completed" : "Not Completed"}
                                    </Typography>
                                </Box>
                            ))}
                        </List>
                        }
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{display:'flex', flexDirection:'row', gap:'10px'}}>
                    <Button variant="contained" color="error" onClick={OnRejectReport}>
                        Ignore Report
                    </Button>
                    <Button variant="contained" color="primary" onClick={OnViewEvent}>
                        View Event
                    </Button>
                    <Button variant="contained" color="primary" onClick={OnAcceptReport}>
                        Ingest to Salesforce
                    </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}