import React, {useState, useEffect, useContext } from 'react';
import SophConstants from '../../constants/SophConstants';
import { AldersgateContext } from '../../context/aldersgate';
import Grid from '@mui/material/Grid';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid';

import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';

import IconedTextButton from '../IconedTextButton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default function OriginalPageEdit(props) {
    const {viewPage} = props;
    
    const [translationData, setTranslationData] = useState({translations:[]});
    const [hasOriginal, sethasOriginal] = useState(false);

    const [formData, setFormData] = useState({adID:"",containerID:"",languageID:"en",originalID:null,translation:"", "id":0,"changed":false});
    const [formDisabled, setFormDisabled] = useState(true);

    const [snackbar, setSnackbar] = React.useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    const [pageSize, setPageSize] = React.useState(25);

    const aldersgateContext = useContext(AldersgateContext);
    

    //load page data from server
    useEffect(() => {

        if(!hasOriginal) {

            aldersgateContext.GetFromAPI('translation/get/en/' + viewPage).then((success, err) => {

                if(success) {
                    CreateTranslationData(success);

                    sethasOriginal(true);
                }
        
                if(err) {
                    console.log("Error: " + err);
                }
        
            }).catch((error) => {
                console.log("Error: " + error);
            });

            //fetch(SophConstants.API_ADDR + '/api/translation/get/en/' + viewPage + '?api-key=foo', {
        }

    }, [viewPage]);

    //create page data object from server data
    var CreateTranslationData = (data) => {

        let translationObject = {...data};
        translationObject.originalID = data.adID;

        translationObject.translations = [];
        
        let index = 0;
        data.translations.forEach(function(value) {
        let translationInput = {...value};
            translationInput.id = index;
            translationInput.changed = false;

            translationObject.translations.push(translationInput);
            index++;
        });
    
        setFormData({adID:"",containerID:translationObject.adID,languageID:"en",originalID:null,translation:"", "id":0,"changed":false});
        setTranslationData(translationObject);
        
    }

    //save cell changes to server
    const handleCellEditCommit = (params) => {
          try {
            setTranslationData(prevState => {
                
                //create data object
                let translationObject = {...prevState};
                translationObject.translations[params.id].translation = params.value;
                translationObject.translations[params.id].changed = true;
                
                let changedArray = [];
                changedArray.push(translationObject.translations[params.id]);

                if(changedArray.length > 0) {


                    let postData = {
                        languageID: translationObject.languageID,
                        pageIdentifier: translationObject.pageIdentifier,
                        translationID: translationObject.translationID,
                        translations: changedArray
                    };

                    aldersgateContext.PostToAPI('translation/addtranslations/en/' + viewPage, postData).then((success, err) => {
                        if(success) {
                            setSnackbar({ children: "Item: " + translationObject.translations[params.id].adID + ' successfully saved', severity: 'success' });
                            return "success";
                        }
                
                        if(err) {
                            console.log("Error: " + err);
                            setSnackbar({ children: "Item: " + params.id + ' failed to save - ' + err, severity: 'error' });
                        }
                
                    }).catch((error) => {
                        console.log("Error: " + error);
                        setSnackbar({ children: "Item: " + params.id + ' failed to save - ' + error, severity: 'error' });
                    });

                    // fetch(SophConstants.API_ADDR + '/api/translation/addtranslations/en/' + viewPage + '?api-key=foo', {
                    //     method: 'post',
                    //     mode: 'cors', // no-cors, *cors, same-origin
                    //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    //     credentials: 'same-origin', // include, *same-origin, omit
                    //     headers: {
                    //         'Content-Type': 'application/json'
                    //     },
                    //     body: JSON.stringify({
                    //         languageID: translationObject.languageID,
                    //         pageIdentifier: translationObject.pageIdentifier,
                    //         translationID: translationObject.translationID,
                    //         translations: changedArray
                    //     })
                    // }).then((res) => {
                    //     setSnackbar({ children: "Item: " + translationObject.translations[params.id].adID + ' successfully saved', severity: 'success' });
                    //     return "success";
                    // }).then((jsonData, status) => {
                        
                    // }).catch((err) => {
                    //     setSnackbar({ children: "Item: " + params.id + ' failed to save - ' + err, severity: 'error' });
                    // });
                }

                return {...prevState, ...translationObject};
            });
          } catch (error) {
            setSnackbar({ children: 'Error while saving -' + error, severity: 'error' });
            // Restore the row in case of error
            
        }
    };


    const handleSubmit = (event) => {

        if(formData.adID === "" || formData.adID === null || formData.adID === undefined) {
            setSnackbar({ children: 'ID cannot be null', severity: 'error' });
            return;
        }

        if(formData.translation === "" || formData.translation === null || formData.translation === undefined) {
            setSnackbar({ children: 'Text cannot be null', severity: 'error' });
            return;
        }


        let addRow = {adID:formData.adID,containerID:formData.containerID,languageID:"en",originalID:null,translation:formData.translation, "id":translationData.translations.length,"changed":false};
        let changedArray = [];

        changedArray.push(addRow);

        setTranslationData(prevState => {
            // Object.assign would also work
            let translationObject = {...prevState};
            translationObject.translations = [...prevState.translations];
            translationObject.translations.push(addRow);

            if(changedArray.length > 0) {
                let postData = {
                    languageID: translationObject.languageID,
                    pageIdentifier: translationObject.pageIdentifier,
                    translationID: translationObject.translationID,
                    translations: changedArray
                };


                aldersgateContext.PostToAPI('translation/addtranslations/en/' + viewPage, postData).then((success, err) => {
                    if(success) {
                        setSnackbar({ children: 'New item: ' + formData.adID + ' successfully saved', severity: 'success' });
                        return "success";
                    }
            
                    if(err) {
                        console.log("Error: " + err);
                        setSnackbar({ children: "New item: " + formData.adID + ' failed to save - ' + err, severity: 'error' });
                    }
            
                }).catch((error) => {
                    console.log("Error: " + error);
                    setSnackbar({ children: "New item: " + formData.adID + ' failed to save - ' + error, severity: 'error' });
                });

                // fetch(SophConstants.API_ADDR + '/api/translation/addtranslations/en/' + viewPage + '?api-key=foo', {
                //     method: 'post',
                //     mode: 'cors', // no-cors, *cors, same-origin
                //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                //     credentials: 'same-origin', // include, *same-origin, omit
                //     headers: {
                //         'Content-Type': 'application/json'
                //     },
                //     body: JSON.stringify({
                //         languageID: translationObject.languageID,
                //         pageIdentifier: translationObject.pageIdentifier,
                //         translationID: translationObject.translationID,
                //         translations: changedArray
                //     })
                // }).then((res) => {
                //     setSnackbar({ children: 'New item: ' + formData.adID + ' successfully saved', severity: 'success' });
                //     return "success";
                // }).then((jsonData, status) => {
                    
                // }).catch((err) => {
                //     setSnackbar({ children: "New item: " + formData.adID + ' failed to save - ' + err, severity: 'error' });
                // });
            }

            return {...prevState, ...translationObject};
        });


        setFormDisabled(true);

    }


    //create DataGrid headers
    const columns = [
        { field: 'translation', headerName: 'Text', flex: 1, editable: true },
        { field: 'adID', headerName: 'ID', flex:0.3, editable: false }
      ];


    //**Add New Row

    //close add row form
    const handleClosePageForm = (event, name) => {
        setFormDisabled(true);
    }

    const handleOpenPageForm = (event, name) => {
        setFormDisabled(false);
    }

    const handleTextChange = (event) => {
        let value = {[event.target.id]: event.target.value};
        setFormData(prevState => {
            return {...prevState, ...value};
        });
    }

    //open add row form
    const NewRowElement = (formDisabled) => {
        if(!formDisabled) {
            return (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 250 }} aria-label="simple table">
                        <TableBody>
                        
                        <TableRow
                            key="newRow"
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row"><TextField id="translation" label="Text" defaultValue="" variant="standard" onChange={handleTextChange}/></TableCell>
                            <TableCell align="right"><TextField id="adID" label="Database ID" defaultValue="" variant="standard" onChange={handleTextChange}/></TableCell>
                            <TableCell align="right"><Button variant="outlined" color="success" onClick={handleSubmit}>Save</Button> <Button variant="outlined" onClick={handleClosePageForm} color="error">Cancel</Button></TableCell>
                        </TableRow> 

                        </TableBody>
                    </Table>
                </TableContainer>
            );
        } else {
            return null;
        }
    }

    
    return (
    <Grid container>
        <Grid item xs={12}>
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                <DataGrid pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[25, 50, 75]}
                    pagination
                    autoHeight
                    rows={translationData.translations} columns={columns} onCellEditCommit={handleCellEditCommit}/>
                </div>
            </div>
        </Grid>


        <Grid item xs={12}>

            {NewRowElement(formDisabled)}

            <div class="font-icon-wrapper" onClick={handleOpenPageForm}><IconedTextButton  icon={<Avatar><AddIcon /></Avatar>} title="Add Phrase" /></div>

            {!!snackbar && (
                    <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
            )}
        </Grid>
    </Grid>
    );
}