import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AldersgateContext } from '../context/aldersgate';
import useToken from './../components/auth/useToken';
import AuthContext from '../context/AuthContext';
import { ClearAuthToken } from './auth/LoadAuthToken';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import localizer from '../localization/localizer';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import LanguageIcon from '@mui/icons-material/Language';
import Stack from '@mui/material/Stack';

export default function NavBar() {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

	const authContext = React.useContext(AuthContext);
	const [aState, setAState] = React.useState({ loggedIn: false, adID: null, level: 0, token: null, expiry: null });
	const aldersgateContext = useContext(AldersgateContext);

	React.useEffect(() => {
		setAState(prev => {
			return ({ ...authContext.accountState });
		});
	}, [authContext]);


	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const theme = useTheme();
	const mdMatches = useMediaQuery(theme.breakpoints.up('md'));


	const handleProfileMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	const handleLogout = () => {

		console.log("Logging out");
		console.log(JSON.stringify(aState, null, 2));

		let tokenID = aState.token;

		aldersgateContext.PostToAPI('user/logout', { token: tokenID }).then((success, err) => {
			if (success) {
				
			}

			if (err) {
				console.log("Error: " + err);
			}

		}).catch((error) => {
			console.log("Error: " + error);
		});
		

		//fetch(SophConstants.API_ADDR + '/api/user/logout?api-key=foo', {
		


		ClearAuthToken();

		authContext.setAccountState({ loggedIn: false, token: null, level: 0, adID: null, expiry: null });

		setAnchorEl(null);
		handleMobileMenuClose();
		//window.location.reload(false);
	}

	const handleMobileMenuOpen = (event) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const menuId = 'primary-search-account-menu';
	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			{aState.loggedIn ? <MenuItem onClick={handleMenuClose} component={Link} to="/user/profile">My Account</MenuItem> : <MenuItem onClick={handleMenuClose} component={Link} to="/user/login">Sign In</MenuItem>}
			{aState.loggedIn ? <MenuItem onClick={handleLogout} >Sign Out</MenuItem> : <MenuItem onClick={handleMenuClose} component={Link} to="/user/register">Register</MenuItem>}

		</Menu>
	);

	const mobileMenuId = 'primary-search-account-menu-mobile';
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem>
				<IconButton component={Link} to="/dashboard" aria-label="show 4 new mails" color="inherit">
					<Badge badgeContent={0} color="secondary">
						<AutoAwesomeMosaicIcon />
					</Badge>
				</IconButton>
				<p>Dashboard</p>
			</MenuItem>
			<MenuItem>
				<IconButton aria-label="show new notifications" color="inherit">
					<Badge badgeContent={0} color="secondary">
						<NotificationsIcon />
					</Badge>
				</IconButton>
				<p>Notifications</p>
			</MenuItem>
			<MenuItem onClick={handleProfileMenuOpen}>
				<IconButton
					aria-label="account of current user"
					aria-controls="primary-search-account-menu"
					aria-haspopup="true"
					color="inherit"
				>
					<AccountCircle />
				</IconButton>
				<p>Profile</p>
			</MenuItem>
		</Menu>
	);

	const RenderLanguageSelect = () => {

		const [language, setLanguage] = React.useState(localizer.getLanguage());

		const OnLanguageChange = (event) => {
			console.log(event.target.value);
			localizer.setLanguage(event.target.value);
			setLanguage(event.target.value);
		}

		return (
			<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
				<Stack direction="row" spacing={1} alignItems="center">
					<LanguageIcon />
					<NativeSelect
						id="language-select"
						value={language}
						onChange={OnLanguageChange}

						sx={{ color: 'white' }} inputProps={{
							name: 'language',
							id: 'language-select-label',
						}}
					>
						<option value='en'>English</option>
					</NativeSelect>
				</Stack>
			</FormControl>
		)
	}

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position="static">
				<Toolbar>


					{mdMatches ?
						<img
							height="35"
							src={`${process.env.PUBLIC_URL}/ws_ILI_Logo.png`}
							alt="logo" />
						: null}

					{!mdMatches ?
						<img
							height="35"
							src={`${process.env.PUBLIC_URL}/ws_ILI_Logo_NoTag.png`}
							alt="logo" />
						: null}

					<Box sx={{ flexGrow: 1 }} />

					<RenderLanguageSelect />

					{mdMatches ?
						<Box>
							<IconButton component={Link} to="/dashboard" aria-label="show 4 new mails" color="inherit">
								<Badge badgeContent={0} color="secondary">
									<AutoAwesomeMosaicIcon />
								</Badge>
							</IconButton>
							<IconButton aria-label="show new notifications" color="inherit">
								<Badge badgeContent={0} color="secondary">
									<NotificationsIcon />
								</Badge>
							</IconButton>
							<IconButton
								edge="end"
								aria-label="account of current user"
								aria-controls={menuId}
								aria-haspopup="true"
								onClick={handleProfileMenuOpen}
								color="inherit"
							>
								<AccountCircle />
							</IconButton>
						</Box>
						: null}

					{!mdMatches ?
						<Box>
							<IconButton
								aria-label="show more"
								aria-controls={mobileMenuId}
								aria-haspopup="true"
								onClick={handleMobileMenuOpen}
								color="inherit"
							>
								<MoreIcon />
							</IconButton>
						</Box>
						: null}


				</Toolbar>
			</AppBar>
			{renderMobileMenu}
			{renderMenu}
		</Box>
	);
}

/*
						<option value='es'>Español</option>
						<option value='fr'>Français</option>
						<option value='pt'>Português</option>
						<option value='ru'>русский</option>
						*/