import React, { useState, useEffect, useContext } from "react";
import { AldersgateContext } from '../context/aldersgate';
import AuthContext from "../context/AuthContext";
import UserLogin from "../components/user/UserLogin";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { styled } from '@mui/material/styles';
import { indigo, deepPurple, purple } from '@mui/material/colors';


function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default function RegisterUser(props) {

    const [userData, setUserData] = useState({fName: "", lName: "", trainingLevel: "", country: "", city: "", state: "", dateJoined: null});

    const authContext = useContext(AuthContext);
    const aldersgateContext = useContext(AldersgateContext);
    const [aState, setAState] = useState({loggedIn:false, adID:null, level:0, token:null, expiry:null});

    

    useEffect(() => {
      setAState(prev => {
          return({...authContext.accountState});
      });
    }, [authContext]);

    useEffect(() => {
        if(aState.loggedIn) {
            aldersgateContext.GetFromAPI('user/' + aState.adID).then((success, err) => {

                if(success) {
                    ProcessProfile(success);
                }

            }).catch((error) => {
                console.log("Error: " + error);
            });

            // fetch(SophConstants.API_ADDR + '/api/user/' + aState.adID + "?api-key=foo", {
            //     method: 'get',
            //     mode: 'cors', // no-cors, *cors, same-origin
            //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            //     credentials: 'same-origin', // include, *same-origin, omit
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     body: null
            // }).then((res) => {
            //     return res.json();
            // }).then((jsonData, status) => {
            //     console.log(JSON.stringify(jsonData, null, 4));
            //     ProcessProfile(jsonData);

            // }).catch((err) => {
            //     if(value < 10)
            //         return wait(500).then(() => setValue(value+1));
            // });
        }
        
    }, [aState]);

    //check for token, block if not logged in
    if(aState.loggedIn === false) {
        return <UserLogin />
    }


    const ProcessProfile = (props) => {
        const { fName, lName, trainingLevel, country, city, state, createdDate } = props;

        setUserData(prevState => ({
            ...prevState,
            fName: fName,
            lName: lName,
            trainingLevel: trainingLevel,
            country: country,
            city: city,
            state: state,
            dateJoined: new Date(createdDate)
        }));
    }

    const  LocalizedDateString = (date) => {
        var userLang = navigator.language || navigator.userLanguage;
        let dateToPrint = new Date(date);
        let dateString = dateToPrint.toLocaleDateString(userLang);

        return (dateString);
    }

    const LocationDisplay = (country, city, state) => {
        let locationString = "";
        
        if(city)
            locationString = city;

        if(state) {
            if(locationString.length > 0) {
                locationString += " " + state;
            } else {
                locationString += state;
            }
        }

        if(country) {
            if(locationString.length > 0) {
                locationString += ", " + country;
            } else {
                locationString += country;
            }
        }

        return locationString;
    }

    return (
        <Box m={1.5} bgcolor="background.paper" >
            <Grid container >
                <Grid item xs={0} sm={0} md={1} lg={4} xl={4}>
                </Grid>
                <Grid item xs={12} sm={12} md={10} lg={4} xl={4}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box sx={{background: 'linear-gradient(to right bottom, #36EAEF, #6B0AC9)', height: '120px', width: '100%'}}>
                                <Box sx={{color: 'black', textAlign: 'center', paddingTop: '0px', fontSize: '30px'}}>
                                    <AccountCircleIcon sx={{ fontSize: 150 }} />
                                </Box> 
                            </Box>
                        </Grid>

                        <Grid item xs={12} mt={4}>
                            <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                                <Box>
                                    <Typography variant="h4">
                                        {userData.fName} {userData.lName}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="p">
                                        History Maker
                                    </Typography>
                                </Box>
                            </Box>

                        </Grid>

                        <Grid item xs={12} mt={4}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                     {LocationDisplay(userData.country, userData.city, userData.state )}
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    Date Joined: {LocalizedDateString(userData.dateJoined)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={0} sm={0} md={1} lg={4} xl={4}>
                </Grid>
            </Grid>
        </Box>
    )
}