import React, { useState, useEffect, useContext } from "react";
import { AldersgateContext } from '../context/aldersgate';
import AuthContext from "../context/AuthContext";
import UserLogin from "../components/user/UserLogin";
import NavBreadcrumbs from "../components/NavBreadcrumbs";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CenteredLoading from '../components/CenteredLoading';

import ViewListIcon from '@mui/icons-material/ViewList';
import EditIcon from '@mui/icons-material/Edit';
import ChatIcon from '@mui/icons-material/Chat';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';

import ViewAssignedReportAdmin from "../components/reporting/ViewAssignedReportAdmin";

    function wait(delay){
        return new Promise((resolve) => setTimeout(resolve, delay));
    }

    function DisplayPage(props) {
        const { pageNumber, isLoading, groupInfo, setFinalized } = props;
        switch(pageNumber) {
            case 0:
                return <ViewAssignedReportAdmin reportData={groupInfo} setFinalized={setFinalized} isLoading={isLoading}/>;
            case 1:
                return null;
            case 2:
                return null;
            case 3:
                return null;
    
            default:
                return <ViewAssignedReportAdmin />;
        }
    }

    //view the assigned report
    export default function ViewQReportAdmin(props) {
        const [value, setValue] = useState(0);
        const [assignedReport, setAssignedReport] = useState({});
        const [isLoading, setIsLoading] = useState(true);

        const [navPage, setNavPage] = useState(0);

        const authContext = useContext(AuthContext);
        const aldersgateContext = useContext(AldersgateContext);
        const [aState, setAState] = useState({loggedIn:false, adID:null, level:0, token:null, expiry:null});

        useEffect(() => {
            if(props.match.params && props.match.params.id) {
                aldersgateContext.GetFromAPI('reports/assigned/get/' + props.match.params.id).then((success, err) => {

                    if(success) {
                        ParseReportData(success);
                        setIsLoading(false);
                    }
            
                    if(err) {
                        console.log("Error: " + err);
                    }
            
                }).catch((error) => {
                    console.log("Error: " + error);
                });

                /*fetch(SophConstants.API_ADDR + '/api/reports/assigned/get/' + props.match.params.id + "?api-key=foo", {
                    method: 'get',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: null
                }).then((res) => {
                    return res.json();
                }).then((jsonData, status) => {
                    ParseReportData(jsonData);
                    setIsLoading(false);
                }).catch((err) => {
                    if(value < 10)
                        return wait(500).then(() => setValue(value+1));
                });*/
            }
        }, [value]);

        useEffect(() => {
            setAState(prev => {
                return({...authContext.accountState});
            });
        }, [authContext]);

        function ParseReportData(props) {
            let reportAssignment = {...props};
            console.log("reportAssignment: " + JSON.stringify(reportAssignment));

            for(let i = 0; i < reportAssignment.events.length; i++) {
                let event = reportAssignment.events[i];
                reportAssignment.events[i].dateRange = {startingDate:event.startingDate, endingDate:event.endingDate};
            }

            setAssignedReport(reportAssignment);
            
        }

        const SetFinalized = () => {
            setAssignedReport(prev => {
                return({...prev, finalized:true});
            });
        }

        function DisplayGroup(isLoading, groupInfo, setFinalized) {

            return (
                <Box m={1.5} sx={{ pb: 7 }} bgcolor="background.paper" >
                    <Grid container >
                        <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                            <NavBreadcrumbs crumbs={[{href:"/dashboard", text:"Dashboard"}]} currentPage="Reports" />
                            <DisplayPage pageNumber={navPage} isLoading={isLoading} groupInfo={groupInfo} setFinalized={setFinalized} />
                            
                        </Grid>
                    </Grid>
                    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                        <BottomNavigation
                        showLabels
                        value={navPage}
                        onChange={(event, newValue) => {
                            setNavPage(newValue);
                        }}
                        >
                        <BottomNavigationAction label="Report" icon={<ViewListIcon />} />
                        <BottomNavigationAction label="Messages" icon={<ChatIcon />} />
                        <BottomNavigationAction label="Edit" icon={<EditIcon />} />
                        </BottomNavigation>
                    </Paper>
                </Box>
            );
        }



        return (
        <Box sx={{flexGrow:1}}>
            {(isLoading) ? (
                <CenteredLoading />
            ) : (
                DisplayGroup(isLoading, assignedReport, SetFinalized)
            )}
        </Box>
      )
  }