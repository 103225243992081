import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';

export default function RegFieldDropdown(props) {
    const { onChange } = props;
    const { fieldName, size, placeholderText, label, fieldChange, defaultValue, options } = props.data;
    const { fieldKey } = props;
    const disabled = props.disabled;


    const onTagsChange = (event, values) => {
        onChange(values);
    }

    let defaultVal = null;
    //if defaultValue is in the options, set it as the default value
    if(defaultValue !== null && defaultValue !== undefined) {
        for(var i = 0; i < options.length; i++) {
            if(options[i].value === defaultValue) {
                defaultVal = options[i];
            }
        }
    }

    return (
        <Grid key={fieldKey+"-dropdown"} item xs={12} sm={12} md={size}>
            <FormControl fullWidth>
            <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={options}
            onChange={onTagsChange}
            defaultValue={defaultVal}
            disabled={disabled}

            renderInput={(params) => (
                <TextField
                  disabled={disabled}
                  {...params}
                  label={label}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                  variant="standard"
                />
              )}
            />
            </FormControl>
        </Grid>
    )
}

/*
            <InputLabel id={fieldKey+"-label"}>{label}</InputLabel>
                <Select
                labelId={fieldKey+"-label"}
                id={fieldKey}
                defaultValue={defaultValue}
                label={label}
                margin="none"
                size="small"
                fullWidth
                variant="standard"
                onChange={fieldChange(fieldName)}
                >
                    {options.map((option, index) => {
                        return (
                            <MenuItem value={option.value}>{option.display}</MenuItem>
                        )
                    }
                    )}
                </Select>
                </FormControl>
                */