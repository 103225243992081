import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../context/AuthContext";
import UserLogin from "../components/user/UserLogin";
import { AldersgateContext } from "../context/aldersgate";
import NavBreadcrumbs from "../components/NavBreadcrumbs";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CenteredLoading from '../components/CenteredLoading';

import HomeIcon from '@mui/icons-material/Home';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import EditIcon from '@mui/icons-material/Edit';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';

import GroupOverview from "../components/groupview/GroupOverview";


    function wait(delay){
        return new Promise((resolve) => setTimeout(resolve, delay));
    }

    function DisplayPage(props) {
        const { pageNumber, isLoading, groupInfo } = props;
        switch(pageNumber) {
            case 0:
                return <GroupOverview groupData={groupInfo} isLoading={isLoading}/>;
            case 1:
                return null;
            case 2:
                return null;
            case 3:
                return null;
    
            default:
                return <GroupOverview />;
        }
    }

    export default function ViewGroup(props) {
        const [value, setValue] = useState(0);
        const [groupData, setGroupData] = useState({});
        const [isLoading, setIsLoading] = useState(true);

        const [navPage, setNavPage] = React.useState(0);

        const authContext = React.useContext(AuthContext);
        const [aState, setAState] = React.useState({loggedIn:false, adID:null, level:0, token:null, expiry:null});
        const aldersgateContext = useContext(AldersgateContext);
        
        React.useEffect(() => {
          setAState(prev => {
              return({...authContext.accountState});
          });
        }, [authContext]);

        useEffect(() => {
            if(props.match.params && props.match.params.id) {

                aldersgateContext.GetFromAPI('group/' + props.match.params.id, null, 1).then((success, err) => {
                    if(success) {
                        ParseGroupData(success);
                        setIsLoading(false);
                    }
            
                    if(err) {
                        console.log("Error: " + err);
                    }
            
                }).catch((error) => {
                    console.log("Error: " + error);
                });

                //fetch(SophConstants.API_ADDR + '/api/group/' + props.match.params.id + "?api-key=foo", {
                    
            }
        }, [value]);

        function ParseGroupData(props) {
            let group = {...props};
            console.log("group: " + JSON.stringify(group));
            
            for(let i = 0; i < group.members.length; i++) {
                if(group.members[i].adID == group.ownerID) {
                    group.owner = group.members[i];
                    break;
                }
            }

            setGroupData(group);
            
        }

        function DisplayGroup(isLoading, groupInfo) {

            return (
                <Box m={1.5} bgcolor="background.paper" >
                    <Grid container >
                        <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                            <NavBreadcrumbs crumbs={[{href:"/dashboard", text:"Dashboard"}]} currentPage="Group" />

                            <DisplayPage pageNumber={navPage} isLoading={isLoading} groupInfo={groupInfo} />
                            
                        </Grid>
                    </Grid>
                    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                        <BottomNavigation
                        showLabels
                        value={navPage}
                        onChange={(event, newValue) => {
                            setNavPage(newValue);
                        }}
                        >
                        <BottomNavigationAction label="Home" icon={<HomeIcon />} />
                        <BottomNavigationAction label="Members" icon={<PeopleAltIcon />} />
                        <BottomNavigationAction label="Edit" icon={<EditIcon />} />
                        </BottomNavigation>
                    </Paper>
                </Box>
            );
        }

        //check for token, block if not logged in
        if(aState.loggedIn === false) {
            return <UserLogin />
        }


        return (
        <Box sx={{flexGrow:1}}>
            {(isLoading) ? (
                <CenteredLoading />
            ) : (
                DisplayGroup(isLoading, groupData)
            )}
        </Box>
      )
  }