import { useState } from 'react';

export default function useToken() {
    const getToken = () => {
        const tokenString = sessionStorage.getItem('token'); //localStorage
        const userToken = JSON.parse(tokenString);
        console.log("saved token: " + tokenString);
        return userToken;
      };

      const [token, setToken] = useState(getToken());

      const saveToken = userToken => {
        console.log("saving token: " + userToken);
        sessionStorage.setItem('token', JSON.stringify(userToken)); //localStorage
        setToken(userToken);
      };

      return {
        setToken: saveToken,
        token
      }
}