import React, { useState, useEffect } from 'react';
import DReportEventListRow from './DReportEventListRow';
import DReportEventListCard from './DReportEventListCard';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function DReportEventListItem(props) {
    const { rowData } = props;

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Box>
            {smMatches ? (<DReportEventListRow key={rowData.adID} rowItemData={rowData} />)
                : (<DReportEventListCard key={rowData.adID} rowItemData={rowData} />)
            }
        </Box>
    );
}