import React from 'react';
import Box from '@mui/material/Box';

export default function EventAssignments(props) {
    return (
        <Box>
            <h3>Assignments</h3>
        </Box>
    );
};
