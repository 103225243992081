import React, {useContext, useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import bcrypt from 'bcryptjs';
import SophConstants from '../../constants/SophConstants';
import UserLogin from './UserLogin';
import { AldersgateContext } from '../../context/aldersgate';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

import CircularProgress from '@mui/material/CircularProgress';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { set } from 'date-fns';

export default function PasswordResetForm(props) {
    const { confirmCode } = props;

     const [formData, setFormData] = useState({
        otp: '',
        password: '',
        password2: '',
        showPassword: false,
    });

    const [loading, setLoading] = useState(false);

    const [formDisabled, setFormDisabled] = useState(false);
    const [submitting, setSubmitting] = useState({status:0});
    const [alertData, setAlertData] = useState({open:false, message:"", severity:"success"});
    const aldersgateContext = useContext(AldersgateContext);
    const [showLoginForm, setShowLoginForm] = useState(false);

    useEffect(() => {
        setFormData({otp: confirmCode, password: '', password2: '', showPassword: false});
    }, [confirmCode]);

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleChange = prop => event => {
        setFormData({ ...formData, [prop]: event.target.value });
    }

    const handleClickShowPassword = () => {
        setFormData({ ...formData, showPassword: !formData.showPassword });
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        setFormDisabled(true);

        if(formData.password !== formData.password2) {
            let newState = {open:true, message:"Passwords do not match", severity:"error"}
            setAlertData(newState);
            setFormDisabled(false);
            setLoading(false);
            return;
        }

        var hash = bcrypt.hashSync(formData.password, SophConstants.slt);

        aldersgateContext.PostToAPI('user/resetpassword/' + confirmCode, {pword: hash}).then((success, err) => {
            if(success) {
                let newState = {open:true, message:"Password reset successful", severity:"success"}
                setAlertData(newState);
                setShowLoginForm(true);
                setLoading(false);
            }
    
            if(err) {
                console.log("Error: " + err);

                let newState = {open:true, message:"There was a problem resetting your password", severity:"error"}
                setAlertData(newState);
                setFormDisabled(false);
                setLoading(false);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
            let newState = {open:true, message:"There was a problem resetting your password", severity:"error"}
            setFormDisabled(false);
            setAlertData(newState);
            setLoading(false);
        });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlertData(prevState => {
            let newState = {...prevState};
            newState.open = false;
            return {...newState};
        });
    };

    function wait(delay){
        return new Promise((resolve) => setTimeout(resolve, delay));
    }

    function SubmitButtonIcon(props) {
        switch (props.submitStatus) {
            case 0:
                return (<SendIcon />); 
            case 1:
                return (<CircularProgress size={20}/>);
            case 2:
                wait(500).then(() => setSubmitting({status:3}));
                return (<CheckIcon />);
            default:
                return (<CheckIcon />);
        }
    }

    if(showLoginForm) {
        return (
            <UserLogin redirectPage='/dashboard' />
        );
    } else {
        return (
            <div>
                <Grid container >
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={1} sm={1} md={2} lg={2} xl={3}>
                            </Grid>
                            <Grid item xs={10} sm={10} md={8} lg={8} xl={6} mt={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography component="h2" variant="h5" color="textPrimary" align="left">
                                            Reset Password
                                        </Typography>
                                        <Typography component="p" variant="p" color="textSecondary" align="left">
                                            Enter your details below
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormControl sx={{ width: '100%' }} variant="outlined">
                                            <Box sx={{mb:1}} htmlFor="outlined-adornment-password" >
                                                <InputLabel>New Password</InputLabel>
                                            </Box>
                                            <OutlinedInput
                                                fullWidth
                                                size="small"
                                                id="outlined-adornment-password"
                                                type={formData.showPassword ? 'text' : 'password'}
                                                value={formData.password}
                                                disabled={formDisabled}
                                                onChange={handleChange('password')}
                                                endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    >
                                                    {formData.showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                                }
                                                label="New Password"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormControl sx={{ width: '100%' }} variant="outlined">
                                            <Box sx={{mb:1}} htmlFor="outlined-adornment-password" >
                                                <InputLabel>Confirm Password</InputLabel>
                                            </Box>
                                            <OutlinedInput
                                                fullWidth
                                                size="small"
                                                id="outlined-adornment-password"
                                                type={formData.showPassword ? 'text' : 'password'}
                                                value={formData.password2}
                                                disabled={formDisabled}
                                                onChange={handleChange('password2')}
                                                endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    >
                                                    {formData.showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                                }
                                                label="Confirm Password"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={handleSubmit}
                                        
                                        disabled={formDisabled}
                                        endIcon={<SubmitButtonIcon submitStatus={submitting.status}/>}>
                                            Reset
                                    </Button>
                                        
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={1} sm={1} md={2} lg={2} xl={3}>
                            </Grid>
                        </Grid>
                        <Snackbar open={alertData.open} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity={alertData.severity} sx={{ width: '100%' }}>
                                {alertData.message}
                            </Alert>
                        </Snackbar>
                    </Grid>
                </Grid>
            </div>
        );
    }



}