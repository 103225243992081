import React, {useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../context/aldersgate';
import CountrySelect from '../CountrySelect';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Box  from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import IconedTextButton from '../IconedTextButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import NativeSelect from '@mui/material/NativeSelect';
import AddIcon from '@mui/icons-material/Add';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssessmentIcon from '@mui/icons-material/Assessment';

export default function ViewFacilitator(props) {
    const {closeForm, updateInformation } = props;
    const {updateData, setSnackbarMessage } = updateInformation;
    const [formData, setFormData] = useState(props.data);
    const [changedData, setChangedData] = useState({});
    const [value, setValue] = useState(0);
    const aldersgateContext = useContext(AldersgateContext);

    const handleCloseForm = () => {
        closeForm(true);
    }
    
    const handleTextChange = (event) => {
      let value = {[event.target.id]: event.target.value};
    
      setChangedData(prevState => {
          let newState = {...prevState};
          newState[event.target.id] = event.target.value;
          return {...newState};
      });
    
      setFormData(prevState => {
        let newState = {...prevState};
        newState[event.target.id] = event.target.value;
        return {...newState};
      });
    }

    const handleCountryChange = (event, values) => {
        let code = "";
        if(values && values.code)
          code = values.code;
    
          setChangedData(prevState => {
            let newState = {...prevState};
            newState.countryID = code;
            return {...newState};
        });
    };
    
    const handleSubmit = () => {
      let editedData = {...changedData};
      editedData.id = undefined;
      let eventID = formData.eventID;
      let adID = formData.adID;

      aldersgateContext.PostToAPI('event/' + eventID + '/updatefacilitator/' + adID, editedData, null, 1).then((success, err) => {
        if(success) {
            updateData(prevState => {
                let newState = prevState;
                newState = newState+1;
                return {...newState};
            });
    
            setSnackbarMessage("Facilitator Updated: " + (formData.fName ? formData.fName : "null") + " " +  (formData.lName ? formData.lName : "null") );    
        }

        if(err) {
            console.log("Error: " + err);
        }

    }).catch((error) => {
        console.log("Error: " + error);
    });
      
      //fetch(SophConstants.API_ADDR + '/api/event/' + eventID + '/updatefacilitator/' + adID + '?api-key=foo', {
    
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item >
                        <Box sx={{textAlign:"center"}}>
                            <Typography component="h2" variant="h4" color="textSecondary">{formData.fName} {formData.lName}</Typography>
                            View and edit facilitator details
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}  sx={{mt:2}}>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={{mr:1}}>
                        <AssignmentIcon color="action"/>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" align="left" color="textSecondary">Contact Details</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    variant="standard"
                    label="First Name"
                    id="fName"
                    value={formData.fName}
                    sx={{  width: '100%' }}
                    onChange={handleTextChange}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
                    variant="standard"
                    label="Last Name"
                    id="lName"
                    value={formData.lName}
                    sx={{  width: '100%' }}
                    onChange={handleTextChange}
                />
            </Grid>
            
            <Grid item xs={0} sm={12} >
            </Grid>

            <Grid item xs={12} sm={6}>
            <TextField
                    variant="standard"
                    label="Email"
                    id="email"
                    value={formData.email}
                    sx={{  width: '100%' }}
                    onChange={handleTextChange}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
            <TextField
                    variant="standard"
                    label="Phone Number"
                    id="phoneNumber"
                    value={formData.phoneNumber}
                    sx={{  width: '100%' }}
                    onChange={handleTextChange}
                />
            </Grid>

            <Grid item xs={0} sm={12} >
            </Grid>

            <Grid item xs={12} sm={6}>
                <CountrySelect handleCountryChange={handleCountryChange} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    variant="standard"
                    label="State/Provence"
                    id="state"
                    value={formData.state}
                    sx={{  width: '100%' }}
                    onChange={handleTextChange}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    variant="standard"
                    label="City"
                    id="city"
                    value={formData.city}
                    sx={{  width: '100%' }}
                    onChange={handleTextChange}
                />
            </Grid>


            <Grid item xs={12}  sx={{mt:2}}>

                <Grid container direction="row" alignItems="center">
                    <Grid item sx={{mr:1}}>
                        <AssessmentIcon color="action"/>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" align="left" color="textSecondary">Event Details</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="dropped">
                    Confirmed
                    </InputLabel>
                    <NativeSelect
                        value={formData.confirmed}
                        onChange={handleTextChange}
                        sx={{  width: '100%' }}
                        inputProps={{
                        name: 'confirmed',
                        id: 'confirmed',
                        }}
                    >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                    </NativeSelect>
                </FormControl>
            </Grid>


            <Grid item xs={12} mt={5}>

                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Button variant="contained" color="success" onClick={handleSubmit}>Save</Button>
                        </Grid>
                        
                        <Grid item>
                            <Button variant="contained" onClick={handleCloseForm} color="error">Cancel</Button>
                        </Grid>
                    </Grid>

                </Grid>
        </Grid>

        

      );
}

