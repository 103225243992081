import React from 'react';
import EventNameCard from './EventNameCard';
import Grid from '@mui/material/Grid';
import EditTabView from './edit/EditTabView';



export default function EditOverview(props) {
    const { eventData, isLoading, updateInformation } = props;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <EventNameCard isLoaded={!isLoading} eventData={eventData}/>
            </Grid>

            <Grid item xs={12}>
                <EditTabView eventData={eventData} updateInformation={updateInformation}/>
            </Grid>

            <Grid item xs={12}>
                
            </Grid>
        </Grid>
    );

}