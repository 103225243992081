import React, { useState, useEffect } from 'react';
import { CSVLink } from "react-csv";
import { useTheme } from '@mui/material/styles';
import SophConstants from '../../../constants/SophConstants';
import localizer from '../../../localization/localizer';
import useMediaQuery from '@mui/material/useMediaQuery';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DFacilitatorListItem from './DFacilitatorListItem';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DownloadIcon from '@mui/icons-material/Download';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export default function DFacilitatorList(props) {
    const { peopleListData, onRowClick, eventName, signupID } = props;

    
    const [peopleList, setPeopleList] = useState([]);
    const [sortBy, setSortBy] = useState('signupDate');
    const [sortDirection, setSortDirection] = useState('desc');
    const [lNameSortState, setLNameSortState] = useState(0);
    const [emailSortState, setEmailSortState] = useState(0);
    const [fNameSortState, setFNameSortState] = useState(0);
    const [dateSortState, setDateSortState] = useState(1);

    const [dropDownState, setDropDownState] = useState(0);

    const [currentPage, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [displayRows, setDisplayRows] = useState([]);


    
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [signupLink, setSignupLink] = useState('');


    useEffect(() => {
        let lang = localizer.getLanguage();
        if(lang)
            lang = '/' + lang;
        
        setSignupLink(SophConstants.PUBLIC_ADDR + lang + '/reg/' + signupID);
    }, [signupID]);

    let fontSizeTitle = 14;

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: (!smMatches) ? '80%' : 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    
    useEffect(() => {

        if(peopleListData === undefined || peopleListData === null)
            return;

        let sortData = [...peopleListData];

        let i = 0;
        let rows = [];
        sortData.sort(handleSortBy);
        sortData.forEach(person => {
            let processedRow = {...person, elementID:i, onRowClick:onRowClick};
            i++;
            rows.push(processedRow);
        });

        setPeopleList(rows);
    }, [peopleListData, sortBy, sortDirection]);

    useEffect(() => {
        let page = currentPage ? currentPage : 0;
        let count = peopleList.length;
        let start = page * rowsPerPage;
        let end = start + rowsPerPage;

        if(end > count)
            end = count;
        
        let rows = peopleList.slice(start, end);
        setDisplayRows(rows);
    }, [peopleList, currentPage, rowsPerPage]);

    useEffect(() => {
        if(smMatches) {
            setRowsPerPage(15);
        } else {
            setRowsPerPage(5);
        }

        setPage(0);
    }, [smMatches]);
    

    const handleSortBy = (a, b) => {
        if(sortDirection === 'asc') {
            if(a[sortBy] < b[sortBy]) {
                return -1;
            }
            if(a[sortBy] > b[sortBy]) {
                return 1;
            }
            return 0;
        } else {
            if(a[sortBy] < b[sortBy]) {
                return 1;
            }
            if(a[sortBy] > b[sortBy]) {
                return -1;
            }
            return 0;
        }
    }

    const FirstNameSort = direction => () => {
        setSortBy('fName');
        setSortDirection(direction);
        setFNameSortState(prev => {
            let newState = prev +1;

            if(newState > 2)
                newState = 0;

            return newState;
        });

        setLNameSortState(0);
        setEmailSortState(0);
        setDateSortState(0);
        setDropDownState(1);
    }

    const LastNameSort = direction => () => {
        setSortBy('lName');
        setSortDirection(direction);
        setLNameSortState(prev => {
            let newState = prev +1;

            if(newState > 2)
                newState = 0;

            return newState;
        });

        setFNameSortState(0);
        setEmailSortState(0);
        setDateSortState(0);
        setDropDownState(2);
    }

    const EmailSort = direction => () => {
        setSortBy('email');
        setSortDirection(direction);
        setEmailSortState(prev => {
            let newState = prev +1;

            if(newState > 2)
                newState = 0;

            return newState;
        });

        setFNameSortState(0);
        setLNameSortState(0);
        setDateSortState(0);
    }

    const DateSort = direction => () => {
        setSortBy('signupDate');
        setSortDirection(direction);
        setFNameSortState(0);
        setLNameSortState(0);
        setEmailSortState(0);
        setDateSortState(0);
        setDropDownState(0);
    }


    const RenderFNameSortArrow = () => {
        if(fNameSortState === 0) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#bbbbbb', textAlign:'left' }}>
                    <IconButton onClick={FirstNameSort('asc')}>
                        <ArrowDropDownIcon  sx={{color:'#bbbbbb'}}/>
                    </IconButton>
                </Box>
            )
        }

        if(fNameSortState === 1) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                    <IconButton onClick={FirstNameSort('desc')}>
                        <ArrowDropDownIcon sx={{color:'#000000'}}/>
                    </IconButton>
                </Box>
            )
        }

        if(fNameSortState === 2) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                    <IconButton onClick={DateSort('desc')}>
                        <ArrowDropUpIcon  sx={{color:'#000000'}} />
                    </IconButton>
                </Box>
            )
        }
    }

    const RenderLNameSortArrow = () => {
        if(lNameSortState === 0) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#bbbbbb', textAlign:'left' }}>
                    <IconButton onClick={LastNameSort('asc')}>
                        <ArrowDropDownIcon  sx={{color:'#bbbbbb'}}/>
                    </IconButton>
                </Box>
            )
        }

        if(lNameSortState === 1) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                    <IconButton onClick={LastNameSort('desc')}>
                        <ArrowDropDownIcon sx={{color:'#000000'}}/>
                    </IconButton>
                </Box>
            )
        }

        if(lNameSortState === 2) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                    <IconButton onClick={DateSort('desc')}>
                        <ArrowDropUpIcon  sx={{color:'#000000'}} />
                    </IconButton>
                </Box>
            )
        }
    }

    const RenderEmailSortArrow = () => {
        if(emailSortState === 0) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#bbbbbb', textAlign:'left' }}>
                    <IconButton onClick={EmailSort('asc')}>
                        <ArrowDropDownIcon  sx={{color:'#bbbbbb'}}/>
                    </IconButton>
                </Box>
            )
        }

        if(emailSortState === 1) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                    <IconButton onClick={EmailSort('desc')}>
                        <ArrowDropDownIcon sx={{color:'#000000'}}/>
                    </IconButton>
                </Box>
            )
        }

        if(emailSortState === 2) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                    <IconButton onClick={DateSort('desc')}>
                        <ArrowDropUpIcon  sx={{color:'#000000'}} />
                    </IconButton>
                </Box>
            )
        }
    }

    const RenderPageButtons = () => {
        return (
            <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                <IconButton onClick={DateSort('desc')}>
                    <KeyboardArrowLeftIcon  sx={{color:'#000000'}} />
                </IconButton>
                <IconButton onClick={DateSort('desc')}>
                    <KeyboardArrowRightIcon  sx={{color:'#000000'}} />
                </IconButton>
            </Box>
        )
    }

    const RenderHeader = () => {
        return (
            <Box>
                <Grid container p={1} sx={{backgroundColor:"#ffffff"}}>
                    <Grid item xs={4} sm={3}>
                        <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                            <Box>
                                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                    <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                                        <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                            First
                                        </Box>
                                        <RenderFNameSortArrow />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                        
                    <Grid item xs={4} sm={5} sx={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center'}}>
                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                            <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                Last
                            </Box>
                            <RenderLNameSortArrow />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={4} sx={{width:'100%', height:'100%', display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center', alignContent:'center'}}>
                        <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                            <Box>
                                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                                    <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                        Email
                                    </Box>
                                    <RenderEmailSortArrow />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                </Grid>
            </Box>
        );
    }

    const handleDropdownChange = (event) => {
        setDropDownState(event.target.value);
        switch(event.target.value) {
            case 0:
                setSortBy('signupDate');
                setSortDirection('desc');
                setFNameSortState(0);
                setLNameSortState(0);
                setEmailSortState(0);
                setDateSortState(0);
                break;
            case 1:
                setSortBy('fName');
                setSortDirection('asc');
                setDateSortState(0);
                setFNameSortState(1);
                setLNameSortState(0);
                setEmailSortState(0);
                break;
            case 2:
                setSortBy('lName');
                setSortDirection('asc');
                setDateSortState(0);
                setFNameSortState(0);
                setLNameSortState(1);
                setEmailSortState(0);
                break;
            default:
                break;
        }
    }

    const RenderFooter = () => {

        let page = currentPage ? currentPage : 0;
        let count = peopleList.length;
        let start = page * rowsPerPage;
        let end = start + rowsPerPage;

        if(end > count)
            end = count;
        
        const RenderTools = () => {
            if(displayRows.length <= 0) //if no rows, don't show tools
                return (<Box></Box>);

            return (
                    <Box>
                        {smMatches ? (
                        <Box>
                            <Button onClick={handleOpen}>Download</Button>
                        </Box>
                    ) : (
                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                            <Box>
                                <FormControl sx={{ m: 2, minWidth: 120 }} size="small">
                                    <InputLabel id="demo-select-small-label">Sort By</InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={dropDownState}
                                        label="Sort By"
                                        onChange={handleDropdownChange}
                                    >
                                        <MenuItem value={0}>Date</MenuItem>
                                        <MenuItem value={1}>First Name</MenuItem>
                                        <MenuItem value={2}>Last Name</MenuItem>
                                        
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box>
                                <IconButton aria-label="delete" size="large" onClick={handleOpen}>
                                    <CloudDownloadIcon fontSize="inherit"/>
                                </IconButton>
                            </Box>
                        </Box>
                    )}
                </Box>
            );
        }

        //footer with pagination on right side
        return (
            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', alignContent:'center'}} >
                 
                 {<RenderTools />}

                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center', alignContent:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left', color:'bbbbbb' }}>
                        {start+1}-{end} of {count}
                    </Box>
                    <Box>
                        <IconButton onClick={SetPageBackward}>
                            <KeyboardArrowLeftIcon  sx={{color:'#bbbbbb'}} />
                        </IconButton>
                    </Box>
                    <Box>
                        <IconButton onClick={SetPageForward}>
                            <KeyboardArrowRightIcon  sx={{color:'#bbbbbb'}} />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        )
    }

    const SetPageForward = () => {
        setPage( prev => {
            let newPage = currentPage + 1;

            if(newPage * rowsPerPage > peopleList.length)
                return prev;
    
            if(newPage < 0)
                return prev;

                return newPage;
            }
        );
    }

    const SetPageBackward = () => {
        setPage( prev => {
            let newPage = currentPage - 1;

            if(newPage * rowsPerPage > peopleList.length)
                return prev;
    
            if(newPage < 0)
                return prev;

                return newPage;
            }
        );
    }

    const RenderDownloadModal = () => {
        const [downloadFName, setDownloadFName] = useState(true);
        const [downloadLName, setDownloadLName] = useState(true);
        const [downloadEmail, setDownloadEmail] = useState(false);
        const [downloadPhone, setDownloadPhone] = useState(false);
        const [downloadCountry, setDownloadCountry] = useState(false);
        const [downloadState, setDownloadState] = useState(false);
        const [downloadCity, setDownloadCity] = useState(false);
        const [downloadAttending, setDownloadAttending] = useState(false);
        const [downloadAgreed, setDownloadAgreed] = useState(false);

        const [downloadBtnEnabled, setDownloadBtnEnabled] = useState(false);
        const [headers, setHeaders] = useState([{ label: "First Name", key: "firstname" }, { label: "Last Name", key: "lastname" }]);
        const [csvData, setCsvData] = useState([]);

        useEffect(() => {
            if(downloadAgreed && (downloadFName || downloadLName || downloadEmail || downloadPhone || downloadCountry || downloadState || downloadCity || downloadAttending)) {
                setDownloadBtnEnabled(true);
            } else {
                setDownloadBtnEnabled(false);
            }

            let newHeaders = [];
            if(downloadFName)
                newHeaders.push({ label: "First Name", key: "fName" });
            if(downloadLName)
                newHeaders.push({ label: "Last Name", key: "lName" });
            if(downloadEmail)
                newHeaders.push({ label: "Email", key: "email" });
            if(downloadPhone)
                newHeaders.push({ label: "Phone", key: "phone" });
            if(downloadCountry)
                newHeaders.push({ label: "Country", key: "country" });
            if(downloadState)
                newHeaders.push({ label: "State", key: "state" });
            if(downloadCity)
                newHeaders.push({ label: "City", key: "city" });
            if(downloadAttending)
                newHeaders.push({ label: "Attending", key: "attending" });

            setHeaders(newHeaders);

            SetupDownloadData();

        }, [downloadFName, downloadLName, downloadEmail, downloadPhone, downloadCountry, downloadState, downloadCity, downloadAttending, downloadAgreed]);

        const SetupDownloadData = () => {
            let data = [];
            peopleList.forEach(person => {
                let row = {};
                if(downloadFName)
                    row.fName = person.fName;
                if(downloadLName)
                    row.lName = person.lName;
                if(downloadEmail)
                    row.email = person.email;
                if(downloadPhone)
                    row.phone = person.phoneNumber;
                if(downloadCountry)
                    row.country = person.countryID;
                if(downloadState)
                    row.state = person.state;
                if(downloadCity)
                    row.city = person.city;
                if(downloadAttending)
                    row.attending = !person.dropped;

                data.push(row);
            });

            setCsvData(data);
        }
        
        const handleSwitchOnChange = (event) => {
            switch(event.target.name) {
                case 'downloadFName':
                    setDownloadFName(event.target.checked);
                    break;
                case 'downloadLName':
                    setDownloadLName(event.target.checked);
                    break;
                case 'downloadEmail':
                    setDownloadEmail(event.target.checked);
                    break;
                case 'downloadPhone':
                    setDownloadPhone(event.target.checked);
                    break;
                case 'downloadCountry':
                    setDownloadCountry(event.target.checked);
                    break;
                case 'downloadState':
                    setDownloadState(event.target.checked);
                    break;
                case 'downloadCity':
                    setDownloadCity(event.target.checked);
                    break;
                case 'downloadAttending':
                    setDownloadAttending(event.target.checked);
                    break;
                case 'downloadAgreed':
                    setDownloadAgreed(event.target.checked);
                    break;
                default:
                    break;
            }
        }

        return (
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Download List
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Select the fields you would like to download
                    </Typography>
                    <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start', alignContent:'center'}}>
                        <Box>
                            <FormControlLabel control={<Switch checked={downloadFName} name='downloadFName' onChange={handleSwitchOnChange} />} label="First Name" />
                        </Box>
                        <Box>
                            <FormControlLabel control={<Switch checked={downloadLName} name='downloadLName' onChange={handleSwitchOnChange}/>} label="Last Name" />
                        </Box>
                        <Box>
                            <FormControlLabel control={<Switch checked={downloadEmail} name='downloadEmail' onChange={handleSwitchOnChange} color="warning" />} label="Email" />
                        </Box>
                        <Box>
                            <FormControlLabel control={<Switch checked={downloadPhone} name='downloadPhone' onChange={handleSwitchOnChange} color="warning"/>} label="Phone" />
                        </Box>
                        <Box>
                            <FormControlLabel control={<Switch checked={downloadCountry} name='downloadCountry' onChange={handleSwitchOnChange} />} label="Country" />
                        </Box>
                        <Box>
                            <FormControlLabel control={<Switch checked={downloadState} name='downloadState' onChange={handleSwitchOnChange}/>} label="State" />
                        </Box>
                        <Box>
                            <FormControlLabel control={<Switch checked={downloadCity} name='downloadCity' onChange={handleSwitchOnChange} />} label="City" />
                        </Box>
                        <Box>
                            <FormControlLabel control={<Switch checked={downloadAttending} name='downloadAttending' onChange={handleSwitchOnChange} />} label="Attending" />
                        </Box>
                    </Box>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        By downloading this list, you agree to protect the privacy of the individuals on this list, to not share it with anyone, and to distroy all digital and physical copies after the event.
                    </Typography>
                    <Box>
                        <FormControlLabel control={<Switch checked={downloadAgreed} name='downloadAgreed' onChange={handleSwitchOnChange} />} label="I agree" />
                    </Box>
                    <Box mt={4} sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', alignContent:'center'}}>
                        <Box pr={2}>
                            <Button onClick={handleClose} sx={{color:'red'}}>Cancel</Button>
                        </Box>
                        <Box pl={2}>
                            {(downloadBtnEnabled) ? (
                        <CSVLink style={{ textDecoration: 'none' }} data={csvData} filename={"RosterExport.csv"} headers={headers}><Button variant="contained" onClick={handleClose} disabled={!downloadBtnEnabled} startIcon={<DownloadIcon />}>Download</Button></CSVLink>
                            ) : (
                                <Button variant="contained" onClick={handleClose} disabled={true} startIcon={<DownloadIcon />}>Download</Button>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Modal>
        );
    }

    return (
        <Box mb={1}>
            {smMatches ? (<RenderHeader />)
                : (null)
            }
            <Box>
                {(displayRows.length > 0) ?  displayRows.map((rowItemData) => (
                    <DFacilitatorListItem key={rowItemData.id} rowData={rowItemData} />
                )) : (<Box><Box pt={3}>No facilitators registered</Box></Box>)}
            </Box>

            <RenderFooter />
            <RenderDownloadModal />
        </Box>
    )
}