import React, {useEffect, useState, useContext} from  'react';
import { Link, useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {QRCodeSVG} from 'qrcode.react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';



export default function RegisteredEventInfo(props) {
    const {eventData} = props;

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));
    const xsMatches = useMediaQuery(theme.breakpoints.up('sm'));

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#ffffff',
        border: '2px solid #000',
        overflow:'scroll',
        boxShadow: 24,
        p: 4,
        width:(smMatches) ? '40%' : '85%'
    };

    const PrettyDate = (date) => {
        let d = new Date(date);
        let month = d.toLocaleString('default', { month: 'long' });
        let day = d.getDate();
        let year = d.getFullYear();

        return month + " " + day + ", " + year;
    }

    const PrettyDateRange = (startDate, endDate) => {
        let d = new Date(startDate);
        let month = d.toLocaleString('default', { month: 'long' });
        let day = d.getDate();
        let year = d.getFullYear();

        let d2 = new Date(endDate);
        let month2 = d2.toLocaleString('default', { month: 'long' });
        let day2 = d2.getDate();
        let year2 = d2.getFullYear();

        if(year === year2) {
            return month + " " + day + " - " + month2 + " " + day2 + ", " + year;
        } else {
            return month + " " + day + ", " + year + " - " + month2 + " " + day2 + ", " + year2;
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent: 'center', gap:"15px", backgroundColor:'#3573b9', width:'100%' }}>
                            <Box p={1}>
                                <Typography variant="h5" color="ButtonHighlight" component="div">
                                    Upcoming Training
                                </Typography>
                            </Box>
                        </Box>
                        <Box p={2}>
                            <Box sx={{ display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center' }}>

                                <Box>
                                    <h3>{eventData?.eventName}</h3>
                                </Box>
                                <Box>
                                {PrettyDateRange(eventData?.startingDate, eventData?.endingDate)}
                                </Box>
                                <Box>
                                    {eventData?.eventMedium}
                                </Box>
                            </Box>
                            <Box mt={5} sx={{ display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent: 'center', gap:"15px" }}>
                                <Box>
                                    <Button variant="contained" color="primary" component={Link} to={"/event/vista/" + eventData?.signupID} startIcon={<WysiwygIcon />}>
                                        View Event
                                    </Button>
                                </Box>
                                <Box>
                                    <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<QrCode2Icon />}>
                                        Show Ticket
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', height:'100%', width:'100%'}}>
                        <Box sx={{ display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', height:'100%', width:'100%', outline: "4px solid #3573b9", pb:'6px'}}>
                            <Box>
                                {xsMatches ? (
                                    <h1>{eventData?.eventName}</h1>
                                ) : (
                                    <h3>{eventData?.eventName}</h3>
                                )}
                            </Box>
                            <Box>
                                {PrettyDate(eventData?.startingDate)} - {PrettyDate(eventData?.endingDate)}
                            </Box>
                            
                            <Box sx={{height:'100%', width:'100%'}}>
                            <QRCodeSVG id="123456" value={"{\"eID\":\"" + eventData?.signupID + "\",\"pID\":\"" + eventData?.participantID + "\"}"} 
                            level={"M"}
                            width={"100%"}
                            height={"100%"}
                            includeMargin={true}
                            />

                            </Box>

                            <Box>
                                Registered {PrettyDate(eventData?.signupDate)}
                            </Box>
                        </Box>
                        <Box mt={4} sx={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'center', alignContent:'center'}}>
                            {smMatches ? (
                                <Box>
                                    <Button variant="contained" size="large" color="error" onClick={handleClose}>
                                        Close
                                    </Button>
                                </Box>
                            ) : (
                                <Button variant="contained" size="large" color="error" fullWidth onClick={handleClose}>
                                    Close
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

/*
                            <QRCode
                                id="123456"
                                value={"{\"eID\":\"" + eventData?.signupID + "\",\"pID\":\"" + eventData?.participantID + "\"}"}
                                size={350}
                                level={"M"}
                                includeMargin={true}
                            />*/