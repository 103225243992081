import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import NativeSelect from '@mui/material/NativeSelect';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddIcon from '@mui/icons-material/Add';
import Box  from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

export default function AddParticipantsToReport(props) {
    const { participants, addNewParticipant, removeParticipant } = props;

    const [newParticipant, setNewParticipant] = useState({fName:'', lName:'', email:'', phone:'', countryID:''});

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setNewParticipant({ ...newParticipant, [name]: value });
    }

    const handleAddParticipant = () => {

        if(newParticipant.fName === '' || newParticipant.lName === '')
            return;

        addNewParticipant(newParticipant);

        setNewParticipant(prev => {
            return({fName:'', lName:'', email:'', phone:'', countryID:''});
        });
    }

    const handleDelete = chipInfo => () => {
        removeParticipant(chipInfo);
      };

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" >
                        Add Participants
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField id="fName" name="fName" label="First Name" variant="standard" value={newParticipant.fName} fullWidth onChange={handleFormChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField id="lName" name="lName" label="Last Name" variant="standard" value={newParticipant.lName} fullWidth onChange={handleFormChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField id="email" name="email" label="Email" variant="standard" value={newParticipant.email} fullWidth onChange={handleFormChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField id="phone" name="phone" label="Phone" variant="standard" value={newParticipant.phone} fullWidth onChange={handleFormChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Box mt={1} sx={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'center'}}>
                        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddParticipant}>Add Participant</Button>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" >
                        Participants ({participants.length})
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'flex-start'}}>
                    <Stack direction="row" spacing={1}>
                            {participants.map((participant, index) => {
                                return (
                                    <Chip key={index} name={index} label={participant.fName + " " + participant.lName} variant="outlined" onDelete={handleDelete(index)} />
                                )
                            })}
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )

}