import React, { useState, useEffect } from 'react';
import { CSVLink } from "react-csv";
import { useTheme } from '@mui/material/styles';
import SophConstants from '../../../constants/SophConstants';
import localizer from '../../../localization/localizer';
import useMediaQuery from '@mui/material/useMediaQuery';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DSessionsListItem from './DSessionsListItem';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DownloadIcon from '@mui/icons-material/Download';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs'

export default function DSessionsList(props) {
    const { sessionListData, onRowClick, signupID } = props;

    
    const [sessionList, setSessionList] = useState([]);
    const [sortBy, setSortBy] = useState('name');
    const [sortDirection, setSortDirection] = useState('asc');
    const [nameSortState, setNameSortState] = useState(1);
    const [dateSortState, setDateSortState] = useState(0);
    const [durationSortState, setDurationSortState] = useState(0);
    const [assignedSortState, setAssignedSortState] = useState(0);

    const [dropDownState, setDropDownState] = useState(0);

    const [currentPage, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [displayRows, setDisplayRows] = useState([]);


    
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [signupLink, setSignupLink] = useState('');


    useEffect(() => {
        let lang = localizer.getLanguage();
        if(lang)
            lang = '/' + lang;
        
        setSignupLink(SophConstants.PUBLIC_ADDR + lang + '/reg/' + signupID);
    }, [signupID]);

    let fontSizeTitle = 14;

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: (!smMatches) ? '80%' : 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    
    useEffect(() => {

        if(sessionListData === undefined || sessionListData === null)
            return;

        let sortData = [...sessionListData];

        let i = 0;
        let rows = [];
        sortData.sort(handleSortBy);
        sortData.forEach(session => {
            let processedRow = {...session, elementID:i, onRowClick:onRowClick};
            i++;
            rows.push(processedRow);
        });

        setSessionList(rows);
    }, [sessionListData, sortBy, sortDirection]);

    useEffect(() => {
        let page = currentPage ? currentPage : 0;
        let count = sessionList.length;
        let start = page * rowsPerPage;
        let end = start + rowsPerPage;

        if(end > count)
            end = count;
        
        let rows = sessionList.slice(start, end);
        setDisplayRows(rows);
    }, [sessionList, currentPage, rowsPerPage]);

    useEffect(() => {
        if(smMatches) {
            setRowsPerPage(15);
        } else {
            setRowsPerPage(5);
        }

        setPage(0);
    }, [smMatches]);
    

    const handleSortBy = (a, b) => {
        if(sortDirection === 'asc') {
            if(a[sortBy] < b[sortBy]) {
                return -1;
            }
            if(a[sortBy] > b[sortBy]) {
                return 1;
            }
            return 0;
        } else {
            if(a[sortBy] < b[sortBy]) {
                return 1;
            }
            if(a[sortBy] > b[sortBy]) {
                return -1;
            }
            return 0;
        }
    }

    const SessionNameSort = direction => () => {
        setSortBy('name');
        setSortDirection(direction);
        setNameSortState(prev => {
            let newState = prev +1;

            if(newState > 2)
                newState = 0;

            return newState;
        });

        setDateSortState(0);
        setDurationSortState(0);
        setAssignedSortState(0);
        setDropDownState(0);
    }

    const SessionDateSort = direction => () => {
        setSortBy('sessionStart');
        setSortDirection(direction);
        setDateSortState(prev => {
            let newState = prev +1;

            if(newState > 2)
                newState = 0;

            return newState;
        });

        setDurationSortState(0);
        setNameSortState(0);
        setAssignedSortState(0);
        setDropDownState(1);
    }

    const SessionDurationSort = direction => () => {
        setSortBy('sessionLength');
        setSortDirection(direction);
        setDurationSortState(prev => {
            let newState = prev +1;

            if(newState > 2)
                newState = 0;

            return newState;
        });

        setNameSortState(0);
        setAssignedSortState(0);
        setDateSortState(0);
        setDropDownState(2);
    }

    const AssignedSort = direction => () => {
        setSortBy('facilitatorName');
        setSortDirection(direction);
        setAssignedSortState(prev => {
            let newState = prev +1;

            if(newState > 2)
                newState = 0;

            return newState;
        });

        setDurationSortState(0);
        setNameSortState(0);
        setDateSortState(0);
        setDropDownState(3);
    }


    const RenderSessionNameSortArrow = () => {
        if(nameSortState === 0) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#bbbbbb', textAlign:'left' }}>
                    <IconButton onClick={SessionNameSort('asc')}>
                        <ArrowDropDownIcon  sx={{color:'#bbbbbb'}}/>
                    </IconButton>
                </Box>
            )
        }

        if(nameSortState === 1) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                    <IconButton onClick={SessionNameSort('desc')}>
                        <ArrowDropDownIcon sx={{color:'#000000'}}/>
                    </IconButton>
                </Box>
            )
        }

        if(nameSortState === 2) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                    <IconButton onClick={AssignedSort('desc')}>
                        <ArrowDropUpIcon  sx={{color:'#000000'}} />
                    </IconButton>
                </Box>
            )
        }
    }

    const RenderSessionDateSortArrow = () => {
        if(dateSortState === 0) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#bbbbbb', textAlign:'left' }}>
                    <IconButton onClick={SessionDateSort('asc')}>
                        <ArrowDropDownIcon  sx={{color:'#bbbbbb'}}/>
                    </IconButton>
                </Box>
            )
        }

        if(dateSortState === 1) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                    <IconButton onClick={SessionDateSort('desc')}>
                        <ArrowDropDownIcon sx={{color:'#000000'}}/>
                    </IconButton>
                </Box>
            )
        }

        if(dateSortState === 2) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                    <IconButton onClick={AssignedSort('desc')}>
                        <ArrowDropUpIcon  sx={{color:'#000000'}} />
                    </IconButton>
                </Box>
            )
        }
    }

    const RenderSessionDurationSortArrow = () => {
        if(durationSortState === 0) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#bbbbbb', textAlign:'left' }}>
                    <IconButton onClick={SessionDurationSort('asc')}>
                        <ArrowDropDownIcon  sx={{color:'#bbbbbb'}}/>
                    </IconButton>
                </Box>
            )
        }

        if(durationSortState === 1) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                    <IconButton onClick={SessionDurationSort('desc')}>
                        <ArrowDropDownIcon sx={{color:'#000000'}}/>
                    </IconButton>
                </Box>
            )
        }

        if(durationSortState === 2) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                    <IconButton onClick={AssignedSort('desc')}>
                        <ArrowDropUpIcon  sx={{color:'#000000'}} />
                    </IconButton>
                </Box>
            )
        }
    }

    const RenderAssignedSortArrow = () => {
        if(assignedSortState === 0) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#bbbbbb', textAlign:'left' }}>
                    <IconButton onClick={AssignedSort('asc')}>
                        <ArrowDropDownIcon  sx={{color:'#bbbbbb'}}/>
                    </IconButton>
                </Box>
            )
        }

        if(assignedSortState === 1) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                    <IconButton onClick={AssignedSort('desc')}>
                        <ArrowDropDownIcon sx={{color:'#000000'}}/>
                    </IconButton>
                </Box>
            )
        }

        if(assignedSortState === 2) {
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                    <IconButton onClick={AssignedSort('desc')}>
                        <ArrowDropUpIcon  sx={{color:'#000000'}} />
                    </IconButton>
                </Box>
            )
        }
    }

    const RenderPageButtons = () => {
        return (
            <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                <IconButton onClick={AssignedSort('desc')}>
                    <KeyboardArrowLeftIcon  sx={{color:'#000000'}} />
                </IconButton>
                <IconButton onClick={AssignedSort('desc')}>
                    <KeyboardArrowRightIcon  sx={{color:'#000000'}} />
                </IconButton>
            </Box>
        )
    }

    const RenderHeader = () => {
        return (
            <Box>
                <Grid container p={1} sx={{backgroundColor:"#ffffff"}}>
                    <Grid item xs={4} sm={4}>
                        <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                            <Box>
                                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                    <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                                        <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                            Session
                                        </Box>
                                        <RenderSessionNameSortArrow />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                        
                    <Grid item xs={4} sm={3} sx={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center'}}>
                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                            <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                Date
                            </Box>
                            <RenderSessionDateSortArrow />
                        </Box>
                    </Grid>

                    <Grid item xs={4} sm={1} sx={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center'}}>
                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                            <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                Duration
                            </Box>
                            <RenderSessionDurationSortArrow />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={4} sx={{width:'100%', height:'100%', display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center', alignContent:'center'}}>
                        <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                            <Box>
                                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                                    <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                        Assigned
                                    </Box>
                                    <RenderAssignedSortArrow />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                </Grid>
            </Box>
        );
    }

    const handleDropdownChange = (event) => {
        setDropDownState(event.target.value);
        switch(event.target.value) {
            case 0:
                setSortBy('sessionName');
                setSortDirection('desc');
                setDurationSortState(0);
                setNameSortState(1);
                setDateSortState(0);
                setAssignedSortState(0);
                break;
            case 1:
                setSortBy('sessionStart');
                setSortDirection('asc');
                setDateSortState(1);
                setDurationSortState(0);
                setNameSortState(0);
                setAssignedSortState(0);
                break;
            case 2:
                setSortBy('sessionDuration');
                setSortDirection('desc');
                setDateSortState(0);
                setDurationSortState(1);
                setNameSortState(0);
                setAssignedSortState(0);
                break;
            case 3:
                setSortBy('facilitatorName');
                setSortDirection('desc');
                setDateSortState(0);
                setDurationSortState(0);
                setNameSortState(0);
                setAssignedSortState(1);
                break;
            default:
                break;
        }
    }

    const RenderFooter = () => {

        let page = currentPage ? currentPage : 0;
        let count = sessionList.length;
        let start = page * rowsPerPage;
        let end = start + rowsPerPage;

        if(end > count)
            end = count;
        
        const RenderTools = () => {
            if(displayRows.length <= 0) //if no rows, don't show tools
                return (<Box></Box>);

            return (
                    <Box>
                        {smMatches ? (
                        <Box>
                            
                        </Box>
                    ) : (
                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                            <Box>
                                <FormControl sx={{ m: 2, minWidth: 120 }} size="small">
                                    <InputLabel id="demo-select-small-label">Sort By</InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={dropDownState}
                                        label="Sort By"
                                        onChange={handleDropdownChange}
                                    >
                                        <MenuItem value={0}>Session Name</MenuItem>
                                        <MenuItem value={1}>Session Start</MenuItem>
                                        <MenuItem value={2}>Session Duration</MenuItem>
                                        <MenuItem value={3}>Session Assigned</MenuItem>
                                        
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    )}
                </Box>
            );
        }

        //footer with pagination on right side
        return (
            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', alignContent:'center'}} >
                 
                 {<RenderTools />}

                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center', alignContent:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left', color:'bbbbbb' }}>
                        {start+1}-{end} of {count}
                    </Box>
                    <Box>
                        <IconButton onClick={SetPageBackward}>
                            <KeyboardArrowLeftIcon  sx={{color:'#bbbbbb'}} />
                        </IconButton>
                    </Box>
                    <Box>
                        <IconButton onClick={SetPageForward}>
                            <KeyboardArrowRightIcon  sx={{color:'#bbbbbb'}} />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        )
    }

    const SetPageForward = () => {
        setPage( prev => {
            let newPage = currentPage + 1;

            if(newPage * rowsPerPage > sessionList.length)
                return prev;
    
            if(newPage < 0)
                return prev;

                return newPage;
            }
        );
    }

    const SetPageBackward = () => {
        setPage( prev => {
            let newPage = currentPage - 1;

            if(newPage * rowsPerPage > sessionList.length)
                return prev;
    
            if(newPage < 0)
                return prev;

                return newPage;
            }
        );
    }

    return (
        <Box mb={1}>
            {smMatches ? (<RenderHeader />)
                : (null)
            }
            <Box>
                {(displayRows.length > 0) ?  displayRows.map((rowItemData) => (
                    <DSessionsListItem key={rowItemData.id} rowData={rowItemData} />
                )) : (<Box sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignContent:'center'}}><Box pt={6} pb={6}>No sessions assigned</Box></Box>)}
            </Box>

            <RenderFooter />
        </Box>
    )
}