import React, {useContext} from 'react';
import SophConstants from '../../constants/SophConstants';
import { AldersgateContext } from '../../context/aldersgate';
import useToken from '../auth/useToken';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CountrySelect from '../CountrySelect';

export default function CreateGroupView(props) {
    const [formData, setFormData] = React.useState({ groupName: '', groupDescription: '', groupCountry: ''});
    const { token } = useToken();
    const aldersgateContext = useContext(AldersgateContext);

    function handleChange(event) {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    function handleSubmit(event) {
        event.preventDefault();

        let submitData = {ownerID: token.adID, name:formData.groupName, description:formData.groupDescription, targetCountry: formData.groupCountry};

        aldersgateContext.PostToAPI('group', submitData, null, 1).then((success, err) => {
            if(success) {
                
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        // fetch(SophConstants.API_ADDR + '/api/group/?api-key=foo', {
        //     method: 'post',
        //     mode: 'cors', // no-cors, *cors, same-origin
        //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //     credentials: 'same-origin', // include, *same-origin, omit
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(submitData)
        // }).then((res) => {
        //     return res.json();
        // }).then((jsonData, status) => {
        //     console.log(JSON.stringify(jsonData));
        //     //setIsLoading(false);
        //     //history.push("../event/view/" + jsonData.eventID);//
        // }).catch((err) => {
        //     console.log("error: " + err);
        // });

    }

    function handleCountryChange(event, countryData) {
        let code = "";
        if(countryData && countryData.code)
          code = countryData.code;

        setFormData(prevState => ({
            ...prevState,
            ['groupCountry']: code
        }));
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom color="textSecondary">
                    Create a Group
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" gutterBottom color="textSecondary">
                    ILI Training groups are teams of leaders who are working together to multiply their training through facilitating ILI training events.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="outlined-basic"
                    name='groupName'
                    label="Group Name"
                    fullWidth
                    value={formData.groupName}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12}>
                <CountrySelect handleCountryChange={handleCountryChange}/>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="outlined-basic"
                    label="Group Description"
                    variant="outlined"
                    name='groupDescription'
                    value={formData.groupDescription}
                    rows={4}
                    multiline
                    fullWidth
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Create Group
                </Button>
            </Grid>
        </Grid>
    );
}