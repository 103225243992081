import React, {useEffect, useRef, useState} from  'react';
import SophConstants from '../../constants/SophConstants';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ChatItem from './ChatItem';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';


function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default function ChatView(props) {

    const { chatOwnerID } = props;

    const scrollRef = useRef(null);
    const [chatData, setChatData] = React.useState({chatmsgs:[]});
    const [message, setMessage] = React.useState("");
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        LoadChatData();
    }, [value]);

    useEffect(() => {
        if (scrollRef.current) {
          scrollRef.current.scrollIntoView({ behaviour: "smooth" });
        }
      }, [chatData]);

    function LoadChatData() {
        if(chatOwnerID !== undefined) {
            fetch(SophConstants.API_ADDR + '/api/chat/owner/' + chatOwnerID + '?api-key=foo', {
                method: 'get',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                },
                body: null
            }).then((res) => {
                return res.json();
            }).then((jsonData, status) => {
                console.log(JSON.stringify(jsonData));
                setChatData(jsonData);
            }).catch((err) => {
                if(value < 20)
                    return wait(500).then(() => setValue(value+1));
            });
        }
    }


    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    }

    const handleMessageSubmit = (event) => {
        event.preventDefault();
        if(message.length > 0) {
            let chatID = undefined;
            if(chatData.adID !== undefined) {
                chatID = chatData.adID;
            }

            let submitData = {chatID:chatID, sourceID:chatOwnerID, userID:"test1234", msg:message, msgType:"text"}; //{ chatID, userID, msg }

            //console.log("send message: " + JSON.stringify(submitData));

            fetch(SophConstants.API_ADDR + '/api/chat/setmsg' + '?api-key=foo', {
                method: 'post',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(submitData)
            }).then((res) => {
                return res.json();
            }).then((jsonData, status) => {
                //console.log(JSON.stringify(jsonData));
                //LoadChatData();
                setMessage("");
                setValue(prevState => {
                    return prevState + 1;
                });
            }).catch((err) => {
                console.log("Chat submit error: " + err);
            });
        }
    }

    function GenerateDisplayChats(chatProps) {
        const { chatArray}  = chatProps;

        if(chatArray !== undefined && chatArray.length > 0) {

            let displayChats = [];
            for(let i = 0; i < chatArray.length; i++) {
                let fName = (chatArray[i].sender.fName) ? chatArray[i].sender.fName : "";
                let lName = (chatArray[i].sender.lName) ? chatArray[i].sender.lName : "";
                displayChats.push(<ChatItem adID={chatArray.adID} fName={fName} lName={lName} createDate={chatArray[i].createDate} message={chatArray[i].msg} />);

                if(i < chatArray.length - 1) {
                    displayChats.push(<Divider variant="inset" component="li" />);
                } else {
                    displayChats.push(<Divider variant="inset" component="li" ref={scrollRef} />);
                }
                
            }
            return (
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {displayChats}
                </List>
            );
        } else {
            return (
                <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <Typography variant="h5" component="h3">
                        No messages found
                    </Typography>
                </Box>
            );
        }
    }

    return (
    <Grid container spacing={1}>
        <Grid item xs={12}>
            <Paper style={{maxHeight: 400, overflow: 'auto'}} elevation={0}>
                <GenerateDisplayChats chatArray={chatData.chatmsgs} />
            </Paper>
        </Grid>
        <Grid item xs={12} m={2}>
            <Box sx={{ width: '100%', height:"100%", display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }} >
                <Box sx={{width:"100%", height:"100%", mr: 1}} ><TextField id="message-send" label="Enter Your Message" value={message} variant="outlined" sx={{width:"100%"}} onChange={handleMessageChange} /></Box>
                <Box sx={{height:"100"}}>
                <Button variant="contained" endIcon={<SendIcon />} sx={{height:"55px"}} onClick={handleMessageSubmit}>
                    Send
                </Button>
                </Box>
            </Box>
        </Grid>
    </Grid>
    );
}