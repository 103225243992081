import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


export default function IconedText(props) {
    const {title, children, icon, renderSize} = props;
    let comp = "h2";
    let variant = "h6";
    let size = {fontSize:16};

    if(renderSize === "large") {
        comp = "h1";
        variant = "h5";
        size = {fontSize:18};
    }

    if(renderSize === "medium") {
        comp = "h2";
        variant = "h6";
        size = {fontSize:16};
    }

    if(renderSize === "small") {
        comp = "h2";
        variant = "subtitle2";
        size = {fontSize:14};
    }

    return (
        <Box sx={{display: 'flex', justifyContent: 'center', py: 1}} bgcolor="background.paper">
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>

                {icon}

            </Box>
            <Box>

                <Typography component={comp} variant={variant} color="textSecondary" align="left">
                    {title}
                </Typography>

                <Typography sx={ size } color="textSecondary" align="left">
                    {children}
                </Typography>

            </Box>
        </Box>
    );
}