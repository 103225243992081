import React from 'react';
import ReportDatagrid from './ReportDatagrid';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

export default function ReportOverview(props) {
    const { eventData, isLoading } = props;


    return (
        <Grid container spacing={2}>

            <Grid item xs={12}>
                <ReportDatagrid eventData={eventData}/>
            </Grid>
        </Grid>
    );

}