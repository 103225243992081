import React from 'react';
import CenteredLoading from "../CenteredLoading";
import Typography from '@mui/material/Typography';

export default function EventNameCard(props) {
    const { eventData } = props;

    function LocalizedDateString(date) {
        var userLang = navigator.language || navigator.userLanguage;
        let dateToPrint = new Date(date);
        let dateString = dateToPrint.toLocaleDateString(userLang);

        return (dateString);
    }

    const ReportReminder = () => {
        if(eventData.endingDate !== undefined && eventData.endingDate !== null && eventData.endingDate !== "") {
            //if event ending date has passed and there is no report, set status to "Report Missing"
            let endingDate = new Date(eventData.endingDate);
            let currentDate = new Date();
            if(endingDate < currentDate) {
                if(eventData.report === undefined || eventData.report === null || eventData.report === "") {
                    return (
                        <Typography component="h2" variant="h6" color="#f59725"> Please Complete the Report for This Event</Typography>
                    );
                }
            }
        }

        return null;
    }

    return (
        <div>
            {(!props.isLoaded) ? (
                    <CenteredLoading />
                  ) : (
                    <div>
                        <Typography component="h2" variant="h4" color="textSecondary">{props.eventData.eventName}</Typography>
                        <Typography component="h2" variant="h6" color="textSecondary">{LocalizedDateString(props.eventData.startingDate)} - {LocalizedDateString(props.eventData.endingDate)}</Typography>
                        <ReportReminder />

                    </div>
                  )}
        </div>
    );
}