import react, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import TextField from '@mui/material/TextField';

export default function ReportApprovalAdmin(props) {
    const { reportData, submitReport } = props;

    const [sumbitStatus, setSubmitStatus] = useState(0);
    const [submitForm, setSubmitForm] = useState({confirm:false, userName:''});

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setSubmitForm(prev => {
            return {...prev, [name]: value};
        });
    }

    const handleCheckBoxChange = (event) => {
        const { name, checked } = event.target;
        setSubmitForm(prev => {
            return {...prev, [name]: checked};
        });
    }

    if(reportData?.finalized) {
        return (
            <Box sx={{ display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', alignContent:'center',  padding:'10px', gap:'10px' }}>
                <Box>
                    <Typography variant="h6" color="textSecondary">Approve Report</Typography>
                    <Typography mb={2} variant="body1" color="textSecondary">After reviewing all events, please complete the following information to submit your report</Typography>
                </Box>
                <Box>
                <FormControlLabel control={<Checkbox name='confirm' value={submitForm.confirm} onChange={handleCheckBoxChange} />} label="I approve this information as correct." />
                </Box>
                <Box sx={{width:'90%'}}>
                    <TextField name='userName' fullWidth size="small" required id="outlined-basic" label="Type your name" variant="outlined" value={submitForm.userName} onChange={handleFormChange} />
                </Box>
                <Box mt={2}>
                    <Button disabled={(sumbitStatus < 1 && submitForm.confirm && submitForm.userName != '') ? false : true} size="large" variant="contained" color="success" onClick={submitReport} startIcon={<CheckCircleOutlineIcon />}>Approve Report</Button>
                </Box>
            </Box>
        )
    } else {
        return (
            <Box sx={{ display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', alignContent:'center',  padding:'10px', gap:'10px' }}>
                <Box>
                    <Typography variant="h6" color="textSecondary">Report Status</Typography>
                    <Typography mb={2} variant="body1" color="textSecondary">This report has not been completed by the assigned leader</Typography>
                </Box>
            </Box>
        )
    }
}