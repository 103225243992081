import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CountrySelect from '../CountrySelect';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

export default function CreateEventForm3(props) {

    const { values, handleChange, handelDateChange, handleCountryChange } = props;
    
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <CountrySelect handleCountryChange={handleCountryChange} currentCountry={values.countryID} />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
            <TextField
              placeholder="State"
              label="State"
              onChange={handleChange('state')}
              defaultValue={values.state}
              margin="normal"
              fullWidth
              variant='standard'
            />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
            <TextField
              placeholder="City"
              label="City"
              onChange={handleChange('city')}
              defaultValue={values.city}
              margin="normal"
              fullWidth
              variant='standard'
            />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                    id="startingDate"
                    label="Starting Date"
                    inputFormat="MM/dd/yyyy"
                    value={values.startingDate}
                  onChange={handelDateChange('startingDate')}
                    renderInput={(params) => <TextField variant="standard" sx={{  width: '100%' }} {...params} />}
                />
            </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                    id="endingDate"
                    label="Ending Date"
                    inputFormat="MM/dd/yyyy"
                    value={values.endingDate}
                  onChange={handelDateChange('endingDate')}
                    renderInput={(params) => <TextField variant="standard" sx={{  width: '100%' }} {...params} />}
                />
            </LocalizationProvider>
            </Grid>

            </Grid>
    );
}