import React, { useState } from 'react';
import EditEventDetails from './EditEventDetails';
import EditEventMSGs from './EditEventMSGs';
import EditEventForm from './EditEventForm';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import EditTabIcons from './EditTabs';


export default function EditTabView(props) {
    const {eventData, updateInformation} = props;

    const [tabName, setTabName] = useState({currentTab: 0});

    function changeTabCallback(newTabName) {
        setTabName({currentTab: newTabName});
    }

    function DispalyTab(value) {
        const { tabValue } = value;

        switch(tabValue) {
            case 0:
                return <EditEventDetails eventData={eventData} updateInformation={updateInformation}/>;
            case 1:
                return <EditEventForm eventData={eventData} updateInformation={updateInformation} />;
            case 2:
                return <EditEventMSGs eventData={eventData} updateInformation={updateInformation}/>;
            default:
                return <div></div>;
        }
    }

    return (

        <Grid container >
            <Grid item xs={12}>
                <EditTabIcons changeTabCallback={changeTabCallback}/>
            </Grid>
            <Grid item xs={12}>
                <DispalyTab tabValue={tabName.currentTab}/>
            </Grid>
        </Grid>

    );
}