import React, {useEffect, useState, useContext} from  'react';
import { Link, useHistory } from 'react-router-dom';
import { AldersgateContext } from '../../../../context/aldersgate';
import ADNewEvents from './newevents/ADNewEvents';
import ADNewUsers from './newusers/ADNewUsers';
import DNewlyReportedList from './DReportedList/DNewlyReportedList';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import useToken from './../../../auth/useToken'
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';


function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default function ADashboardHomeView(props) {
    return (

        <Grid container spacing={3} pb={10}>
            <Grid item xs={12}>
                <ADNewEvents  />
            </Grid>
            <Grid item xs={12}>
                <DNewlyReportedList />
            </Grid>
            <Grid item xs={12}>
                <ADNewUsers />
            </Grid>
        </Grid>

    );
}

/*

            

*/