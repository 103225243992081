import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import EventTabs from './EventTabs';
import ParticipantsDataGrid from './ParticipantsDataGrid';
import FacilitatorsDataGrid from './FacilitatorsDataGrid';
import SessionsDataGrid from './SessionsDataGrid';
import ResourcesDataGrid from './ResourcesDataGrid';


export default function EventTabView(props) {
    const { eventData, isLoading, updateInformation } = props;

    const [tabName, setTabName] = useState({currentTab: 0});

    function changeTabCallback(newTabName) {
        setTabName({currentTab: newTabName});
    }

    function DispalyTab(value) {
        const { tabValue } = value;

        switch(tabValue) {
            case 0:
                return <ParticipantsDataGrid eventData={eventData} updateInformation={updateInformation}/>;
            case 1:
                return <FacilitatorsDataGrid eventData={eventData} updateInformation={updateInformation}/>;
            case 2:
                return <SessionsDataGrid eventData={eventData} updateInformation={updateInformation}/>;
            default:
                return <ResourcesDataGrid eventType={eventData.eventType}/>;
        }
    }

    return (
        <Box flexGrow={1}>
            <Grid container >
                <Grid item xs={12}>
                    <EventTabs changeTabCallback={changeTabCallback}/>
                </Grid>
                <Grid item xs={12}>
                    <DispalyTab tabValue={tabName.currentTab}/>
                </Grid>
            </Grid>
        </Box>
    );
}