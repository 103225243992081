import React from 'react';
import Grid from '@mui/material/Grid';
import TranslationInput from "./TranslationInput";
import CenteredLoading from './../CenteredLoading';

export default function TrnaslationInput(props) {
    const { translationData } = props;

    var TranslationInputs = () => {
        let translations = translationData.translations;
        let onTranslationChange = translationData.onTranslationChange;

        if(translations == null || translations == undefined) {
            return <CenteredLoading />
        }

        let rows = [];
        for (var key in translations) {
            rows.push( <TranslationInput onTranslationChange={onTranslationChange} translationData={translations[key]} />);
        }
        return <Grid container>{rows}</Grid>;
    }

    return (
        <div>
        {(!translationData || !translationData.translations) ? (
            <CenteredLoading />
        ) : (
        <Grid item sm={12} md={12} lg={12} xl={12}>
            
                {TranslationInputs()}

        </Grid>
        )}
        </div>
    );
}