import React from 'react';
import EventNameCard from './EventNameCard';
import EventInfoContainer from './EventInfoContainer';
import EventTabView from './EventTabView';
import SignupLinks from './SignupLinks';
import Grid from '@mui/material/Grid';


export default function EventOverview(props) {
    const { eventData, isLoading, updateInformation } = props;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <EventNameCard isLoaded={!isLoading} eventData={eventData}/>
            </Grid>

            <Grid item xs={12}>
                <EventInfoContainer eventData={eventData}/>
            </Grid>

            <Grid item xs={12}>
                <EventTabView eventData={eventData} updateInformation={updateInformation}/>
            </Grid>

            <Grid item xs={12}>
                <SignupLinks eventData={eventData}/>
            </Grid>
        </Grid>
    );

}