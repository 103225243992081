import React, {useState, useEffect } from 'react';
import SophConstants from '../../../constants/SophConstants';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CenteredLoading from '../../CenteredLoading';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import QRCode from "qrcode.react";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import VimeoCard from '../../VimeoCard';
import ImageCard from '../../ImageCard';

function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

const BannerOptions = [
    {value: 0, sx:{position:"relative",
        margin:"0 auto",
        backgroundImage: `url(${process.env.PUBLIC_URL + '/reghero.png'})`,
        backgroundRepeat  : 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize:"cover",
        justifyContent: "center"}, 
    label: "Image"},
    {value: 1, sx:{background: 'linear-gradient(to right bottom, #36EAEF, #6B0AC9)'}, label: "Gradient"},
    {value: 2, sx:{background: 'linear-gradient(to right bottom, #4158D0, #C850C0, #FFCC70)'}, label: "Gradient"},
    {value: 3, sx:{background: 'linear-gradient(to right bottom, #8EC5FC, #E0C3FC)'}, label: "Gradient"},
    {value: 4, sx:{background: 'linear-gradient(to right bottom, #FF3CAC, #784BA0, #2B86C5)'}, label: "Gradient"},
    {value: 5, sx:{background: 'linear-gradient(to right bottom, #FBAB7E, #F7CE68)'}, label: "Gradient"},
    {value: 6, sx:{background: 'linear-gradient(to right bottom, #FEB692, #EA5455)'}, label: "Gradient"},
    {value: 7, sx:{background: 'linear-gradient(to right bottom, #F093FB, #F5576C)'}, label: "Gradient"},
    {value: 8, sx:{background: 'linear-gradient(to right bottom, #5EE7DF, #B490CA)'}, label: "Gradient"},
    {value: 9, sx:{background: '#3573b9'}, label: "Solid"},
    {value: 10, sx:{background: '#aaaaaa'}, label: "Solid"},
    {value: 11, sx:{background: '#ffffff'}, label: "Solid"},
];

const modalBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '300',
    bgcolor: 'background.paper',
    border: '2px solid #555',
    boxShadow: 24,
    p: 4,
  };

export default function ParticipantDashboard(props) {
    const { token } = props;

    const [userData, setUserData] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [value, setValue] = useState(0);

    useEffect(() => {
        //get user data

        fetch(SophConstants.API_ADDR + '/api/user/' + token.adID + '/participant?api-key=foo', {
            method: 'get',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            body: null
        }).then((res) => {
            return res.json();
        }).then((jsonData, status) => {

            setUserData(jsonData);
            
        }).catch((err) => {
            if(value < 20)
                return wait(500).then(() => setValue(value+1));
        });
        
    }, [value]);
    

    const CloseModal = () => {
        setModalOpen(false);
    }

    const OpenModal = () => {
        setModalOpen(true);
    }

    const RenderModal = (modalParams) => {
        const { fName, lName, participantID, eventID, eventName, startingDate, endingDate, eventType } = modalParams;

        return (
            <Modal
                open={modalOpen}
                onClose={CloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalBoxStyle}>
                    <Box sx={{ display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Box>
                            <Typography variant="h4" align='center' color="textSecondary">
                                Event Ticket
                            </Typography>
                        </Box>
                        <Box>
                        <QRCode
                            id="123456"
                            value={"{\"eID\":\"" + eventID + "\",\"pID\":\"" + participantID + "\"}"}
                            size={350}
                            level={"M"}
                            includeMargin={true}
                        />
                        </Box>
                        <Box>
                            <Typography variant="body1" component="p" align='center' sx={{fontWeight:'bold'}}>
                                {fName} {lName}
                            </Typography>
                            <Typography variant="body2" component="p" align='center'>
                                Event: {eventName}
                            </Typography>
                            {startingDate ? <Typography variant="body2" component="p" align='center'>
                                Dates: {LocalizedDateString(startingDate)} - {LocalizedDateString(endingDate)}
                            </Typography> : null}
                            <Typography variant="body2" component="p" align='center'>
                                ID: {participantID}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection:'row', justifyContent:'center', width:'100%'}} mt={4}>
                            <Box>
                                <Button variant="contained" startIcon={<CloseIcon />} color="error" onClick={CloseModal} >Close</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box> 
                
            </Modal>
        )
    }

    
    function LocalizedDateString(date) {
        var userLang = navigator.language || navigator.userLanguage;
        let dateToPrint = new Date(date);
        let dateString = dateToPrint.toLocaleDateString(userLang);

        return (dateString);
    }

    const EventCard = (eventProps) => {
        const {eventData} = eventProps;
        let bannerID = 0;
        let description = null;

        let pdfURL = GetBookPDFURL(eventData.eventType);

        // if(pdfURL !== null) {
        //     pdfURL = process.env.PUBLIC_URL + "/" + pdfURL;
        // }

        function GetBookPDFURL(type) {
            switch(type) {
                case "Module 1":
                    return "https://iliteam.box.com/shared/static/v8ry3hncnbgtofvcuwl3s0q96hlls26s.pdf";
                case "Module 2":
                    return "https://iliteam.box.com/shared/static/cf4clni1kugpp9g21qur5g6xhu7tt0cj.pdf";
                case "Journey":
                    return "https://iliteam.box.com/shared/static/n9zbg7u8kt68l8rvpw445e17ppowqwv8.pdf";
                default:
                    return null;
            }
        }

        if(eventData.signupform !== undefined && eventData.signupform !== null) {
            bannerID =  eventData.signupform.bannerID;
            description = eventData.signupform.description;
        }

        let headerBannerSX = BannerOptions[bannerID].sx;
        headerBannerSX = {...headerBannerSX, ...{width:"100%", display:"flex", height:"100%", justifyContent: "center", alignItems:"center", flexDirection: "column"}};


        return (
            <Card sx={{ maxWidth: 345, width:"100%" }}>
                <CardMedia
                    component="div"
                    alt="header banner"
                    height="140px"
                    sx={{height:140}}
                >
                    <Box sx={headerBannerSX}>
                        <Typography gutterBottom variant="h5" component="div" color="white" sx={{fontWeight: 'bold'}}>
                            {eventData.eventName}
                        </Typography>
                    </Box>
                </CardMedia>
                <CardContent>
                    <Typography  variant="h6" component="div" color='textSecondary'>
                        {LocalizedDateString(eventData.startingDate)} - {LocalizedDateString(eventData.endingDate)}
                    </Typography>
                    <Typography variant="p" component="div" color='textSecondary'>
                        {eventData.city}, {eventData.state}, {eventData.countryID}
                    </Typography>
                </CardContent>
                <CardActions sx={{display:'flex', justifyContent:"space-between"}}>
                    <Button size="small" onClick={OpenModal} >Show Ticket</Button>
                    {pdfURL ? <Button size="small" href={pdfURL} target="_blank" rel="noreferrer" download>Training Book</Button> : null}
                </CardActions>
                <RenderModal fName={userData.fName} lName={userData.lName} participantID={eventData.participantID} eventID={eventData.signupID} eventName={eventData.eventName} startingDate={eventData.startingDate} endingDate={eventData.endingDate}/>
            </Card>        
        );
    }

    //<Button size="small" ><a href={pdfURL} style={{ color:'primary' }} target="_blank" rel="noreferrer" download>Training Book</a></Button>

    const RenderEvents = () => {
        if(userData == null || userData.events == null) {
            return (
                <Typography variant="p" component="p" align='left'>
                    No Events
                </Typography>
            );
        }

        if(userData.events.length === 0) {
            return (
                <Typography variant="p" component="p" align='left'>
                    No Events
                </Typography>
            );
        } 

        let eventList = [];
        for(let i=0; i < userData.events.length; i++) {
            eventList.push(<EventCard key={i} eventData={userData.events[i]} />);
        }

        return eventList;
        
    }


    if(userData == null) {
        return (
            <Box>
                <CenteredLoading />
            </Box>
        );
    } else {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5" component="h2" color='textSecondary'>
                        Welcome {userData.fName} {userData.lName}!
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" component="h2" align='left' color='textSecondary'>
                        Your Events
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <RenderEvents />
                </Grid>

                <Grid item xs={12} mt={4}>
                    <Typography variant="h6" component="h2" align='left' color='textSecondary'>
                        ILI Resources
                    </Typography>
                </Grid>

                <Grid item xs={12} lg={7}>
                    <Paper variant="outlined">
                        <VimeoCard videoLink="748840720" title="ILI Leaders" description="Watch testimonies of how the Eight Core Values have impacted leaders like you" buttonText = "Share"/>
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={5}>
                    <Paper variant="outlined">
                        <ImageCard imageFile="water1.png" title="Leader Community" description="Join like-minded leaders who are called to spread the Gospel at Training.ILITeam.org" buttonText = "Share"/>
                    </Paper>
                </Grid>

            </Grid>
        );
    }
}
