import React, {useState, useEffect, useContext } from 'react';
import Link from '@mui/material/Link';
import { useHistory } from 'react-router-dom';
import { AldersgateContext } from '../../context/aldersgate';
import SophConstants from '../../constants/SophConstants';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';

import IconedTextButton from '../../components/IconedTextButton';


export default function OriginalPageViewTranslations(props) {
    const language = "en";
    const {pageIdentifier, originalID} = props;

    const history = useHistory();

    const [value, setValue] = useState(0);
    const [originalData, setOriginalData] = useState([]);
    const [hasOriginal, sethasOriginal] = useState(false);

    const [formDisabled, setFormDisabled] = useState(true);
    const [formData, setFormData] = useState({adID:"", languageID:"", originalID:originalID, pageIdentifier:pageIdentifier});

    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {

        if(!hasOriginal) {

            aldersgateContext.GetFromAPI('translation/alltranslations/' + pageIdentifier).then((success, err) => {
                if(success) {
                    setOriginalData(success);
                    sethasOriginal(true);
                }
        
                if(err) {
                    console.log("Error: " + err);
                }
        
            }).catch((error) => {
                console.log("Error: " + error);
            });

            //fetch(SophConstants.API_ADDR + '/api/translation/alltranslations/' + pageIdentifier + '?api-key=foo', {
        }

    }, [value]);


    const handleAddPage = (event, name) => {
        console.log(event);

        setFormDisabled(false);
    }

    const handleClosePageForm = (event, name) => {
        setFormDisabled(true);
    }

    const handleTextChange = (event) => {
        setFormData(prevState => {
            let newState = {...prevState};
            newState[event.target.id] = event.target.value;
            newState.originalID = originalID;
            return {...newState};
        });
    }

    const handleSubmit = (event) => {
        console.log(JSON.stringify(formData));

        aldersgateContext.PostToAPI('translation/addtcontainer', formData).then((success, err) => {
            if(success) {
                setOriginalData(prevState => {
                    let current = [...prevState];
                    current.push(formData);
                    return [...current];
                });
                
                setFormData({adID:"", languageID:"", originalID:originalID, pageIdentifier:pageIdentifier});
    
                setValue((prevState => {
                    return (prevState+1);
                }));
                
                handleClosePageForm();
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        // fetch(SophConstants.API_ADDR + '/api/translation/addtcontainer?api-key=foo', {
        //     method: 'post',
        //     mode: 'cors', // no-cors, *cors, same-origin
        //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //     credentials: 'same-origin', // include, *same-origin, omit
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(formData)
        // }).then((res) => {

        //     setOriginalData(prevState => {
        //         let current = [...prevState];
        //         current.push(formData);
        //         return [...current];
        //     });
            
        //     setFormData({adID:"", languageID:"", originalID:originalID, pageIdentifier:pageIdentifier});

        //     setValue((prevState => {
        //         return (prevState+1);
        //     }));
            
        //     handleClosePageForm();
            
        //     return res.json();
        // }).then((jsonData, status) => {
            
        // }).catch((err) => {
            
        // });

    }

    const NewPageElement = (props) => {
        if(props == false) {
            return (
                <TableRow
                    key="newRow"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row"><TextField id="languageID" label="Language Code" defaultValue="" variant="standard" onChange={handleTextChange}/></TableCell>
                    <TableCell align="right"><TextField id="adID" label="Database ID" defaultValue="" variant="standard" onChange={handleTextChange}/></TableCell>
                    <TableCell align="right"><Button variant="outlined" color="success" onClick={handleSubmit}>Save</Button> <Button variant="outlined" onClick={handleClosePageForm} color="error">Cancel</Button></TableCell>
                </TableRow> 
            )
        } else {
            return null;
        }
    }

    //const [isLoading, setIsLoading] = useState(true);


    return (
    <div >
        <Box bgcolor="background.paper" sx={{
            '& .MuiTextField-root': { width: '100%' },
        }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 250 }} aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell><b>Language Code</b></TableCell>
                                        <TableCell align="right"><b>ID</b></TableCell>
                                        <TableCell align="right"><b></b></TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {originalData.map((row) => (
                                            <TableRow
                                            key={row.adID}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                            <TableCell component="th" scope="row">
                                                {row.languageID}
                                            </TableCell>
                                            <TableCell align="right">{row.adID}</TableCell>
                                            <TableCell align="right"><Button variant="outlined" onClick={() => { history.push('/' + language + '/translate/page/' + row.pageIdentifier + '?lang=' + row.languageID); }}>Edit</Button></TableCell>
                                            </TableRow>
                                        ))}

                                        {NewPageElement(formDisabled)}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div class="font-icon-wrapper" onClick={handleAddPage}><IconedTextButton  icon={<Avatar><AddIcon /></Avatar>} title="Add Language" /></div>
                        </Grid>
                    </Grid>

        </Box>
    </div>
    );
}

//[Log] [{"adID":"es-dashboard","languageID":"es","originalID":"en-dashboard","pageIdentifier":"dashboard","updatedAt":"2022-01-06T19:28:12.544Z"}] (main.chunk.js, line 10777)