import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import localizer from './localizer';

export default function LocalWrapper(props) {
    const { children } = props;

    const history = useHistory();
    const [value, setValue] = useState(0);

    useEffect(() => {
        localizer.AddLanguageChangeCallback(LanguageCallback);
        localizer.AddRedirectCallback(Redirect);
    }, []);

    const LanguageCallback = (lang) => {
        let path = window.location.pathname;

        // get the first part of the path
        let pathParts = path.split('/');
        let firstPart = pathParts[1];

        // if the first part is a language code, remove it
        if (localizer.IsSupportedLanguage(firstPart)) {
            pathParts.splice(1, 1);
            path = pathParts.join('/');
        }

        // add the new language code
        path = '/' + lang + path;

        if(path !== window.location.pathname)
            history.push(path);
    }

    const Redirect = () => {
        let path = window.location.pathname;

        // // get the first part of the path
        // let pathParts = path.split('/');
        // let firstPart = pathParts[1];
        
        // // if the first part is a language code, remove it
        // if (localizer.IsSupportedLanguage(firstPart)) {
        //     pathParts.splice(1, 1);
        //     path = pathParts.join('/');
        // }

        // // add the new language code
        // path = '/' + localizer.getLanguage() + path;

        console.log("redirecting to: " + path);
        history.push(path);
        
    }

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
}