import React, { useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../../../../context/aldersgate';
import ADUserItemRow from './ADUserItemRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import GroupIcon from '@mui/icons-material/Group';
import StreetviewIcon from '@mui/icons-material/Streetview';

function GetMediumIcon(medium) {
    switch (medium) {
      case "Online":
        return <TapAndPlayIcon />;
        case "In-Person":
          return <GroupIcon />;
          case "Hybrid":
            return <StreetviewIcon />;
      default:
        break;
    }
  }

export default function ADNewUserItem(props) {
    const { userListData, setUserListData } = props;
    const [allNewUsers, setAllNewUsers] = useState([]);

    const aldersgateContext = useContext(AldersgateContext);


    useEffect(() => {
        setAllNewUsers(prevState => {
            return [...userListData];
        });
    }, [userListData]);

    const options = {month: 'numeric', day: 'numeric' }

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    const CreateInSFHandler = (params) => {
        console.log('handleUpdate user: ' + params);
        let userData = [...allNewUsers];
        let newEventData = [];
        userData.forEach(element => {
            let event = {...element};
            if(event.id == params) {
                event.confirmButtonEnabled = false;
            }

            newEventData.push(event);
        });

        setUserListData(prevState => {
            return [...newEventData];
        });

        let userID = params;
        let authData = {adID:userID};

        aldersgateContext.PostToAPI('admin/user/update', authData, null, 1).then((success, err) => {
            if(success) {
                if(success.authLevel !== undefined && success.authLevel !== null) {
                    let eventData = [...allNewUsers];
                    let newUserData = [];
                    eventData.forEach(element => {
                        let event = {...element};
                        if(event.id == userID) {
                            console.log('handleUpdate user: ' + JSON.stringify(event, null, 2));
                            event.confirmButtonEnabled = false;
                            event.authLevel = success.authLevel;
                        }
        
                        newUserData.push(event);
                    });
        
                    setUserListData(prevState => {
                        return [...newUserData];
                    });
                } 
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/admin/user/update?api-key=foo', {

    }

    let fontSizeTitle = 14;

    return (
        <Box>
            <Box >
                <Grid container p={1} sx={{backgroundColor:'#cccccc'}}>
                    <Grid item xs={4} sm={3}>
                        <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                            <Box>
                                
                            </Box>
                            <Box>
                                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                    <Box sx={{  fontSize: fontSizeTitle, fontWeight: 'bold', textAlign:'left' }}>
                                        Name
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                        
                    <Grid item xs={4} sm={1} sx={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'center', alignItems:'center'}}>
                        <Box sx={{ fontSize: fontSizeTitle, fontWeight: 'bold' }}>
                            Access
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={1} sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                        <Box sx={{  fontSize: fontSizeTitle, fontWeight: 'bold' }}>
                            Training
                        </Box>
                    </Grid>


                    <Grid item xs={4} sm={1} sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                        <Box sx={{  fontSize: fontSizeTitle, fontWeight: 'bold' }}>
                            Country
                        </Box>
                    </Grid>

                    <Grid item xs={4} sm={2} sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                        <Box sx={{  fontSize: fontSizeTitle, fontWeight: 'bold' }}>
                        Joined Date
                        </Box>
                    </Grid>


                    <Grid item xs={4} sm={2} sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                        <Box sx={{  fontSize: fontSizeTitle, fontWeight: 'bold' }}>
                            Status
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={2} sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                        <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                            <Box sx={{  fontSize: fontSizeTitle, fontWeight: 'bold' }}>
                                Action
                            </Box>
                        </Box>
                    </Grid>

                </Grid>

            </Box>
            {allNewUsers.length === 0 ? <Box></Box> : allNewUsers.map((arrayItem, arrayItemIndex, wholeArray) => {
                
                if(smMatches) {
                    return (<ADUserItemRow key={arrayItemIndex} userItemData={arrayItem} actionHandler={CreateInSFHandler}/>)
                } else {
                    return (null) //<ADEventListCard key={arrayItemIndex} eventItemData={arrayItem} createSFHandler={CreateInSFHandler}/>
                }

            })}
            
            
        </Box>
    );
}