import React from 'react';
import RegisterFormPage from './RegisterFormPage';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';


export default function RegisterProcess(props) {
    const { eventData, userRegistration } = props;

    const today = new Date();

    if(eventData === undefined || eventData === null) {
        return (<div><Box m={2}><Typography variant="h5" color="error">Event not found</Typography></Box></div>);
    }

    if(eventData?.eventClosed || today > new Date(eventData.endingDate)) {
        return (<Paper elevation={1} style={{padding: '20px'}}>
                <Box m={2}>
                    <Typography variant="h5" color="error">Registration for this event has ended</Typography>
                </Box>
            </Paper>
        );
    }

    return (<div><RegisterFormPage formID={props.eventData.eventID} eventData={props.eventData} userRegistration={userRegistration}/></div>);
}