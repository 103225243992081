import React, { useEffect, useState, useContext } from 'react';
import SophConstants from '../../constants/SophConstants';
import { AldersgateContext } from '../../context/aldersgate';
import { Link, useHistory } from 'react-router-dom';
import CenteredLoading from './../CenteredLoading';

function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default function CreateEventComplete(props) {
    const [isLoading, setIsLoading] = useState(true);
    const aldersgateContext = useContext(AldersgateContext);

    const history = useHistory();

    useEffect(() => {

        aldersgateContext.PostToAPI('event', props.values, null, 1).then((success, err) => {
            if(success) {
                setIsLoading(false);
                history.push("../event/view/" + success.eventID);//
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/event/?api-key=foo', {
    }, []);

    return(
        <div>
            {(isLoading === true) ? (
            <CenteredLoading />
        ) : (
            <div>success!</div>
        )}
        </div>
    );

}

/*class CreateEventComplete extends React.Component {
    constructor(props) {
        super(props);
        
        // Don't call this.setState() here!
        this.state = { results: 0 };
      }

    componentDidMount(){

        fetch('http://localhost:4000/api/event/?api-key=foo', {
            method: 'post',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
              },
            body: JSON.stringify(this.props.values)
        }).then((res) => {
            return res.json();
        }).then((jsonData, status) => {
            this.setState({
                results: jsonData.eventID
            });
        }).catch((err) => {
              console.log(err);
        });
        
      }

    render() {

        return (
            <div>{this.state.results}</div>
        );
    }

}

export default CreateEventComplete;*/