import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

export default function Title(props) {
  var variant = props.variant || "h6";

  return (
    <Typography component="h2" variant={variant} color="textSecondary" gutterBottom>
      {props.children}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node,
};