import React, { useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../../../../context/aldersgate';
import ADEventListRow from './ADEventListRow';
import ADEventListCard from './ADEventListCard';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import GroupIcon from '@mui/icons-material/Group';
import StreetviewIcon from '@mui/icons-material/Streetview';

function GetMediumIcon(medium) {
    switch (medium) {
      case "Online":
        return <TapAndPlayIcon />;
        case "In-Person":
          return <GroupIcon />;
          case "Hybrid":
            return <StreetviewIcon />;
      default:
        break;
    }
  }

export default function ADEventListItem(props) {
    const { eventItemData, setEventItemData } = props;
    const [allEventData, setAllEventData] = useState([]);

    const aldersgateContext = useContext(AldersgateContext);


    useEffect(() => {
        setAllEventData(prevState => {
            return [...eventItemData];
        });
    }, [eventItemData]);

    const options = {month: 'numeric', day: 'numeric' }

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    const CreateInSFHandler = (params) => {
        console.log('handleCreateInSF h: ' + params);
        let eventData = [...allEventData];
        let newEventData = [];
        eventData.forEach(element => {
            let event = {...element};
            if(event.id == params) {
                console.log('handleCreateInSF: ' + JSON.stringify(event, null, 2));
                event.createSFButtonEnabled = false;
            }

            newEventData.push(event);
        });

        setEventItemData(prevState => {
            return [...newEventData];
        });

        let eventID = params;
        let authData = {adID:eventID};

        aldersgateContext.PostToAPI('admin/conference/new', authData, 1).then((success, err) => {
            if(success) {
                console.log(JSON.stringify(success, null, 4));

                if(success.salesforceID !== undefined && success.salesforceID !== null) {
                    let eventData = [...allEventData];
                    let newEventData = [];
                    eventData.forEach(element => {
                        let event = {...element};
                        if(event.id == eventID) {
                            console.log('handleCreateInSF: ' + JSON.stringify(event, null, 2));
                            event.createSFButtonEnabled = false;
                            event.salesforceID = success.salesforceID;
                        }
        
                        newEventData.push(event);
                    });
        
                    setEventItemData(prevState => {
                        return [...newEventData];
                    });
                }
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        /*fetch(SophConstants.API_ADDR + '/api/admin/conference/new/?api-key=foo', {
            method: 'post',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(authData)
        }).then((res) => {
            return res.json();
        }).then((jsonData, status) => {
            console.log(JSON.stringify(jsonData, null, 4));

            if(jsonData.salesforceID !== undefined && jsonData.salesforceID !== null) {
                let eventData = [...allEventData];
                let newEventData = [];
                eventData.forEach(element => {
                    let event = {...element};
                    if(event.id == eventID) {
                        console.log('handleCreateInSF: ' + JSON.stringify(event, null, 2));
                        event.createSFButtonEnabled = false;
                        event.salesforceID = jsonData.salesforceID;
                    }
    
                    newEventData.push(event);
                });
    
                setEventItemData(prevState => {
                    return [...newEventData];
                });
            } 

            //setSnackbarMessage("Event Updated!");
            
        }).catch((err) => {
            console.log(err);
        });*/
    }

    return (
        <Box>
            {allEventData.length === 0 ? <Box mt={2}>No New Events</Box> : allEventData.map((arrayItem, arrayItemIndex, wholeArray) => {
                
                if(smMatches) {
                    return (<ADEventListRow key={arrayItemIndex} eventItemData={arrayItem} createSFHandler={CreateInSFHandler}/>)
                } else {
                    return (<ADEventListCard key={arrayItemIndex} eventItemData={arrayItem} createSFHandler={CreateInSFHandler}/>)
                }

            })}
            
            
        </Box>
    );
}