import React, {useState, useEffect } from 'react';
import SophConstants from '../../constants/SophConstants';
import localizer from '../../localization/localizer';
import QRCode from "qrcode.react";
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';

export default function SignupLinks(props) {
    const { eventData } = props;

    const [signupLink, setSignupLink] = useState('');
    const [modalOpen, setModalOpen] = useState(false);


    useEffect(() => {
        let lang = localizer.getLanguage();
        if(lang)
            lang = '/' + lang;
        
        setSignupLink(SophConstants.PUBLIC_ADDR + lang + '/reg/' + eventData.signupID);
    }, [eventData]);

    const CloseModal = () => {
        setModalOpen(false);
    }

    const OpenModal = () => {
        setModalOpen(true);
    }

    const modalBoxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '300',
        bgcolor: 'background.paper',
        border: '2px solid #555',
        boxShadow: 24,
        p: 4,
      };

    const downloadQR = () => {
        const canvas = document.getElementById("123456");
        const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "EventQRCode.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const RenderModal = (modalParams) => {
        const { eventURL } = modalParams;
        return (
            <Modal
                open={modalOpen}
                onClose={CloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalBoxStyle}>
                    <Box sx={{ display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Box>
                        <QRCode
                            id="123456"
                            value={eventURL}
                            size={350}
                            level={"M"}
                            includeMargin={true}
                        />
                        </Box>
                        <Box>
                            <Typography variant="body2" component="p" align='center'>
                                {eventURL}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}} mt={4}>
                            <Box>
                                <Button variant="contained" startIcon={<CloseIcon />} color="error" onClick={CloseModal} >Close</Button>
                            </Box>
                            <Box>
                                <Button variant="contained" startIcon={<DownloadIcon />} onClick={downloadQR}>Download</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box> 
                
            </Modal>
        )
    }

    return (
        <Grid container mt={2}>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom component="div" align='left' color='textSecondary'>
                    Sign Up Link
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="p"  component="div" align='left'>
                <Link href={signupLink}>{signupLink}</Link>
                <Box ml={0.5} display='inline' />
                    <IconButton aria-label="Copy link for sign up form" size="small" onClick={() => {navigator.clipboard.writeText(signupLink)}}>
                        <ContentCopyIcon fontSize="inherit"/>
                    </IconButton>
                    <IconButton aria-label="View QR code for sign up form" size="small" onClick={OpenModal}>
                        <QrCode2Icon fontSize="inherit"/>
                    </IconButton>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <RenderModal eventURL={signupLink}/>
            </Grid>
        </Grid>
    );
}