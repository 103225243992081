import React, {useContext} from 'react';
import { AldersgateContext } from '../../context/aldersgate';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CircularProgress from '@mui/material/CircularProgress';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export default function RequestReset(props) {
     const [formData, setFormData] = React.useState({
        email: ''
    });

    const [loading, setLoading] = React.useState(false);

    const [formDisabled, setFormDisabled] = React.useState(false);
    const [submitting, setSubmitting] = React.useState({status:0});
    const [alertData, setAlertData] = React.useState({open:false, message:"", severity:"success"});

    const aldersgateContext = useContext(AldersgateContext);

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleChange = prop => event => {
        setFormData({ ...formData, [prop]: event.target.value });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        setFormDisabled(true);

        aldersgateContext.PostToAPI('user/resetpassword', {email: formData.email}).then((success, err) => {
            if(success) {
                let newState = {open:true, message:"Check your email for a password reset link", severity:"success"}
                setAlertData(newState);
                setLoading(false);
            }
    
            if(err) {
                console.log("Error: " + err);

                let newState = {open:true, message:"There was a problem sending a reset link", severity:"error"}
                setAlertData(newState);
                setFormDisabled(false);
                setLoading(false);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
            let newState = {open:true, message:"There was a problem sending a reset link", severity:"error"}
            setFormDisabled(false);
            setAlertData(newState);
            setLoading(false);
        });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlertData(prevState => {
            let newState = {...prevState};
            newState.open = false;
            return {...newState};
        });
    };

    function wait(delay){
        return new Promise((resolve) => setTimeout(resolve, delay));
    }

    function SubmitButtonIcon(props) {
        switch (props.submitStatus) {
            case 0:
                return (<SendIcon />); 
            case 1:
                return (<CircularProgress size={20}/>);
            case 2:
                wait(500).then(() => setSubmitting({status:3}));
                return (<CheckIcon />);
            default:
                return (<CheckIcon />);
        }
    }

    return (
        <div>
            <Grid container >
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={1} sm={1} md={2} lg={2} xl={3}>
                        </Grid>
                        <Grid item xs={10} sm={10} md={8} lg={8} xl={6} mt={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography component="h2" variant="h5" color="textPrimary" align="left">
                                        Reset Password
                                    </Typography>
                                    <Typography component="p" variant="p" color="textSecondary" align="left">
                                        Enter your email to receive a password reset link in your inbox.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField id="email" label="Email" defaultValue="" variant="outlined" size="small" disabled={formDisabled} onChange={handleChange('email')} fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={handleSubmit}
                                    
                                    disabled={formDisabled}
                                    endIcon={<SubmitButtonIcon submitStatus={submitting.status}/>}>
                                        Submit
                                </Button>
                                    
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={1} sm={1} md={2} lg={2} xl={3}>
                        </Grid>
                    </Grid>
                    <Snackbar open={alertData.open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={alertData.severity} sx={{ width: '100%' }}>
                            {alertData.message}
                        </Alert>
                    </Snackbar>
                </Grid>
            </Grid>
        </div>
    );



}