import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LinearProgressBar from './LinearProgressBar';


export default function RegisteredProgress(props) {
    const registered = props.registered || 0;
    const max = props.max || 30;

    return (
        <Box m={1.5} bgcolor="background.paper" >
            <Grid container >
                <Grid item xs={12}>
                    <Typography component="h2" variant="h6" color="textSecondary" align="left">
                        {props.title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <LinearProgressBar value={registered} max={max} />
                </Grid>
            </Grid>
        </Box>
    );
}