import React, { useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../../../../context/aldersgate';
import useToken from './../../../../auth/useToken';
import ADNewUserItem from './ADNewUserItem';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default function ADNewUsers(props) {
    const { token } = useToken();

    const [value, setValue] = useState(0);
    const currentYear = new Date().getFullYear();
    const firstDay = new Date(currentYear, 0, 1);
    const lastDay = new Date(currentYear, 11, 31);
    
    const [filterData, setFilterData] = useState({authLevel: 0, trainingLevel: 0, lastLogin: firstDay});
    const [userListData, setUserListData] = useState([]);

    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {
        SearchWithFilter();
    }, [value]);

    function ProcessUsers(users) {
        let userList = [];
        let count = 0;
        users.forEach(user => {
            userList.push({
                id: user.adID,
                elementID: count++,
                fName: user.fName,
                lName: user.lName,
                authLevel: user.authLevel,
                trainingLevel: user.trainingLevel,
                createdDate: user.createdDate,
                country: user.country,
                state: user.state,
                city: user.city,
                confirmButtonEnabled: true,
            });
        });

        userList.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.createdDate) - new Date(a.createdDate);
          });

        return userList;
    }

    function SearchWithFilter() {
        let query = '';
        if(filterData.eventType !== 'All')
            query += '&eventType=' + filterData.eventType;
        if(filterData.eventStatus !== 'All')
            query += '&eventStatus=' + filterData.eventStatus;
        if(filterData.startDate !== '')
            query += '&startDateRange=' + filterData.startDate;
        if(filterData.endDate !== '') {
            let endDate = new Date(filterData.endDate);
            endDate.setDate(endDate.getDate() + 1); // Add 1 day to include the last day
            query += '&endDateRange=' + endDate;
        }

        if(filterData.hasSFID) {
            query += '&hasSFID=true';
        } else {
            query += '&hasSFID=false';
        }

        if(filterData.name !== '')
            query += '&name=' + filterData.name;

        query += '&includeOwner=true';


        query = "&authLevel=0&limit=5&offset=0";

        console.log(query);

        aldersgateContext.GetFromAPI('user/getlist/query/s', query, 1).then((success, err) => {
            if(success) {
                setUserListData(ProcessUsers(success));
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/user/getlist/query/s?&api-key=foo' + query, {
    }

    // useEffect(() => {
    //     setEventData(props.eventData);
    // }, [props.eventData]);

    return (
        <Card sx={{minWidth:155}}>
            <CardContent>

                <ADNewUserItem userListData={userListData} setUserListData={setUserListData} />
            
            </CardContent>
        </Card>
    );
}