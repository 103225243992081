import React, {useEffect, useState, useContext } from "react";
import { makeStyles } from '@mui/styles';
import { AldersgateContext } from "../context/aldersgate";
import PasswordResetForm from "../components/user/PasswordResetForm";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';



const useStyles = makeStyles({
        root: {
            flexGrow: 1,
        }
    });


    export default function ResetPassword(props) {
        const { confirmCode } = props?.match?.params;

        return (
            <Box>
                <Grid container >
                    <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Box mt={4} bgcolor="background.paper" >
                                    {confirmCode ? (
                                    <Typography variant="h4" gutterBottom component="div">
                                        Reset Password
                                    </Typography>
                                    ) : (
                                    <Typography variant="h4" gutterBottom component="div">
                                        Invalid Link
                                    </Typography>
                                    )}
                                </Box>

                                <PasswordResetForm confirmCode={confirmCode}/>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                    </Grid>
                </Grid>
            </Box>

      )
  }