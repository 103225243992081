import React, {useEffect, useState, useContext} from 'react';
import CountrySelect from '../CountrySelect';
import SophConstants from '../../constants/SophConstants';
import { AldersgateContext } from '../../context/aldersgate';
import AddParticipantsToReport from './AddParticipantsToReport';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import NativeSelect from '@mui/material/NativeSelect';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddIcon from '@mui/icons-material/Add';
import Box  from '@mui/material/Box';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssessmentIcon from '@mui/icons-material/Assessment';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default function AddEventDetails(props) {
    const { reportData, setEvents, tabIndex, handleCloseForm } = props;

    const [formData, setFormData] = useState({adID:"", eventName:"", eventType:"Module 1", eventMedium:"In-Person", countryID:"", state:"", city:"", startingDate:new Date(), endingDate:new Date(), leadersTrained:0, eventStatus:"complete"});
    const [participants, setParticipants] = useState([]);
    const [addedParticipants, setAddedParticipants] = useState([]);
    const aldersgateContext = useContext(AldersgateContext);

    const [value, setValue] = useState(0);

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        if(participants.length > formData.leadersTrained) {
            setFormData(prevState => {
                let newState = {...prevState};
                newState.leadersTrained = participants.length;
                return {...newState};
            });
        }
    }, [participants]);

    const startingDateChange = (newValue) => {
        
        setFormData(prevState => {
            let newState = {...prevState};
            newState.startingDate = newValue;
            return {...newState};
        });
    };

    const endingDateChange = (newValue) => {
        setFormData(prevState => {
            let newState = {...prevState};
            newState.endingDate = newValue;
            return {...newState};
        });
    };

    const handleTextChange = (event) => {
        let value = {[event.target.id]: event.target.value};
        console.log(JSON.stringify(value));
        setFormData(prevState => {
            let newState = {...prevState};
            newState[event.target.id] = event.target.value;
            return {...newState};
        });
    }

    const handleCountryChange = (event, values) => {
        let code = "";
        if(values && values.code)
          code = values.code;
    
        setFormData(prevState => {
            let newState = {...prevState};
            newState.countryID = code;
            return {...newState};
        });
      };
    
    const AddNewParticipant = (participantData) => {
        let keyID = participants.length;
        participantData.id = keyID;

        if(participantData.email === '')
            participantData.email = null;

        if(participantData.phone === '')
            participantData.phone = null;

        if(participantData.countryID === '')
            participantData.countryID = reportData.countryID;

        if(participantData.state === '')
            participantData.state = reportData.state;

        if(participantData.city === '')
            participantData.city = null;

        setParticipants(prevState => {
            let newState = [...prevState];
            newState.push(participantData);
            return [...newState];
        });

        setAddedParticipants(prevState => {
            let newState = [...prevState];
            newState.push(participantData);
            return [...newState];
        });
    }

    const RemoveParticipant = (key) => {
        setParticipants(prevState => {
            let newState = [...prevState];
            newState.splice(key, 1);
            return [...newState];
        });

        setAddedParticipants(prevState => {
            let newState = [...prevState];
            newState.splice(key, 1);
            return [...newState];
        });
    }


    const handleSubmit = () => {
        let newEvent = {...formData};
        newEvent.dateRange = {startingDate:formData.startingDate, endingDate:formData.endingDate};
        newEvent.report = {completedParticipants: formData.leadersTrained};
        newEvent.participants = addedParticipants;
        //newEvent.adID = reportData.adID;
        // newEvent.id = 1;

        aldersgateContext.PostToAPI('reports/assigned/addevent/' + reportData.adID, newEvent, null, 1).then((success, err) => {
            if(success) {
                setEvents(prevState => {
                    let newState = [...prevState];
                    let returnedEvent = {...success};
    
                    returnedEvent.dateRange = {startingDate:returnedEvent.startingDate, endingDate:returnedEvent.endingDate};
                    newState.push(returnedEvent);
                    return [...newState];
                })
    
    
                handleCloseForm();
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/reports/assigned/addevent/' + reportData.adID + '?api-key=foo', {

    }

    if(tabIndex == 1) {

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AddParticipantsToReport participants={participants} addNewParticipant={AddNewParticipant} removeParticipant={RemoveParticipant} />
                </Grid>
            </Grid>
        );
        
    }

    if(tabIndex == 0) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} mt={2}>
                    <TextField
                        variant="standard"
                        label="Name of Event"
                        id="eventName"
                        value={formData.eventName}
                        sx={{  width: '100%' }}
                        onChange={handleTextChange}
                    />
                </Grid>
                <Grid item xs={0} sm={12} >

                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="eventType">
                        Event Type
                        </InputLabel>
                        <NativeSelect
                            value={formData.eventType}
                            sx={{  width: '100%' }}
                            onChange={handleTextChange}
                            inputProps={{
                            name: 'eventType',
                            id: 'eventType',
                            }}
                        >
                            <option value='Module 1'>Module 1</option>
                            <option value='Module 2'>Module 2</option>
                            <option value='History Makers Journey'>History Makers Journey</option>
                            <option value='Mobilization'>Flight School</option>
                            <option value='Alumni Summit'>Alumni Summit</option>
                        </NativeSelect>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="eventMedium">
                        Training Method
                        </InputLabel>
                        <NativeSelect
                            value={formData.eventMedium}
                            onChange={handleTextChange}
                            sx={{  width: '100%' }}
                            inputProps={{
                            name: 'eventMedium',
                            id: 'eventMedium',
                            }}
                        >
                            <option value='In-Person'>In-Person</option>
                            <option value='Online'>Online</option>
                            <option value='Hybrid'>Hybrid</option>
                        </NativeSelect>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                            id="startingDate"
                            label="Starting Date"
                            inputFormat="MM/dd/yyyy"
                            value={formData.startingDate}
                            onChange={startingDateChange}
                            renderInput={(params) => <TextField variant="standard" sx={{  width: '100%' }} {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                            id="endingDate"
                            label="Ending Date"
                            inputFormat="MM/dd/yyyy"
                            value={formData.endingDate}
                            onChange={endingDateChange}
                            renderInput={(params) => <TextField variant="standard" sx={{  width: '100%' }} {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12}  sx={{mt:6}}>

                    <Grid container direction="row" alignItems="center">
                        <Grid item sx={{mr:1}}>
                            <AddLocationIcon color="action"/>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" align="left" color="textSecondary">Location</Typography>
                        </Grid>
                    </Grid>
                
                </Grid>
                <Grid item xs={12} sm={4}>
                    <CountrySelect handleCountryChange={handleCountryChange} currentCountry={formData.countryID} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="standard"
                        label="State/Provence"
                        id="state"
                        value={formData.state}
                        sx={{  width: '100%' }}
                        onChange={handleTextChange}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="standard"
                        label="City"
                        id="city"
                        value={formData.city}
                        sx={{  width: '100%' }}
                        onChange={handleTextChange}
                    />
                </Grid>


                <Grid item xs={12}  sx={{mt:6}}>

                    <Grid container direction="row" alignItems="center">
                        <Grid item sx={{mr:1}}>
                            <AssessmentIcon color="action"/>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" align="left" color="textSecondary">Event Reporting</Typography>
                        </Grid>
                    </Grid>
                
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="eventStatus">
                        Status
                        </InputLabel>
                        <NativeSelect
                            value={formData.eventStatus}
                            sx={{  width: '100%' }}
                            onChange={handleTextChange}
                            inputProps={{
                            name: 'eventStatus',
                            id: 'eventStatus',
                            }}
                        >
                            <option value='planning'>Planning</option>
                            <option value='complete'>Completed</option>
                            <option value='in-progress'>In Progress</option>
                            <option value='postponed'>Postponed</option>
                        </NativeSelect>
                    </FormControl>
                </Grid>

                {
                    (formData.eventStatus === "complete") ? (
                        <Grid item xs={12} sm={6}>
                            <TextField
                                sx={{  width: '100%' }}
                                variant="standard"
                                id="leadersTrained"
                                label="Number of Leaders Trained"
                                type="number"
                                value={formData.leadersTrained}
                                onChange={handleTextChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    ) : (null)
                }

                <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={2} mt={1}>
                        <Grid item>
                            <Button variant="contained" color="success" onClick={handleSubmit}>Save</Button>
                        </Grid>
                        
                        <Grid item>
                            <Button variant="contained" onClick={()=> {handleCloseForm()}} color="error">Cancel</Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        );
        
    }
}