import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import LinkIcon from '@mui/icons-material/Link';


export default function DAssignedReportListRow(props) {
    const { rowItemData } = props;

    console.log("***" + JSON.stringify(rowItemData, null, 2));

    const history = useHistory();

    const options = {month: 'numeric', day: 'numeric' }
    let bgColor = '#FFFFFF';
    if(rowItemData !== undefined && rowItemData.elementID !== undefined) {
        bgColor = (rowItemData.elementID % 2 === 0) ? '#F5F5F5' : '#FFFFFF';
    }

    let fontSizeTitle = 14;
    let fontSizeSubTitle = 12;

    const viewEventDetails = (event) => {
        let eventID = event;
        history.push("/event/view/" + eventID);
    }

    const GetStatusText = (status) => {
        switch (status) {
            case -1:
                return (<Typography color={'red'}>Rejected</Typography>);
            case 0:
                return (<Typography>Assigned</Typography>);
            case 1:
                return (<Typography color={'orange'}>In-Progress</Typography>);
            case 2:
                return (<Typography color={'blue'}>Submitted</Typography>);
            case 3:
                return (<Typography color={'green'}>Approved</Typography>);
            default:
                return "Unknown";
        }
    }

    return (
        <Box onClick={() => rowItemData.onRowClick(rowItemData.adID)}>
            <Grid container p={1} sx={{backgroundColor:bgColor,':hover': {backgroundColor:'#E0E0E0'}}} >
                <Grid item xs={3} sm={2}>
                    <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                        <Box>
                            <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                <Box sx={{  fontSize: fontSizeTitle, textAlign:'left' }}>
                                    {rowItemData.fName}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                     
                <Grid item xs={3} sm={2} sx={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center'}}>
                    <Box sx={{ fontSize: fontSizeTitle }}>
                        {rowItemData.lName}
                    </Box>
                </Grid>

                <Grid item xs={12} sm={1} sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle }}>
                        {rowItemData.eventCount ? rowItemData.eventCount : 0}
                    </Box>
                </Grid>

                <Grid item xs={4} sm={3} sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle }}>
                    {new Date(rowItemData.createdAt).toLocaleDateString(undefined, options)}
                    </Box>
                </Grid>


                <Grid item xs={4} sm={4} sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle }}>
                        {GetStatusText(rowItemData.status)}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}