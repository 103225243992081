import React, { useState, useEffect } from 'react';

import AddEventDetails from './AddEventDetails';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box  from '@mui/material/Box';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import AssignmentIcon from '@mui/icons-material/Assignment';

export default function AddEventToReport(props) {
    const { reportData, setEvents, handleCloseForm } = props;

    const [tabIndex, setTabIndex] = useState(0);

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));


    const RenderTabs = () => {
        return (
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width:'100%' }}>
                <Tabs
                    value={tabIndex}
                    onChange={(event, newValue) => {
                        setTabIndex(newValue);
                    }}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon label tabs example"
                >
                    <Tab icon={<AddLocationIcon />} label="Event Details" />
                    <Tab icon={<AssignmentIcon />} label="Participants" />
                </Tabs>
            </Box>
        );
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item sx={{textAlign:"center"}}>
                        <Typography component="h2" variant="h4" color="textSecondary">Add Event</Typography>
                        Add an event to your report
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <RenderTabs />
                <AddEventDetails  reportData={reportData} setEvents={setEvents} handleCloseForm={handleCloseForm} tabIndex={tabIndex}/>
            </Grid>
        </Grid>
    );

}