import React, {useEffect, useState, useContext} from  'react';
import { AldersgateContext } from '../../../../context/aldersgate';
import Editor from 'react-simple-wysiwyg';
import sanitizeHtml from 'sanitize-html';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import PersonIcon from '@mui/icons-material/Person';


export default function AddContentPost(props) {
    const { postADID } = props;
    const [postData, setPostData] = useState({postTitle:'', postPreview:'', postHeader:0, type:'announcement', link:null});
    const [changedPost, setChangedPost] = useState({postTitle:'', postPreview:'', postHeader:0, type:'announcement', link:null});
    const [saveBtnEnabled, setSaveBtnEnabled] = useState(false);
    const aldersgateContext = useContext(AldersgateContext);
    const [html, setHtml] = useState('my <b>HTML</b>');
  
    function onChange(e) {
        setChangedPost(prevState => {
            
            let newState = {...prevState, postPreview: e.target.value};
            return {...newState};
        });
    }

    useEffect(() => {

        if(changedPost.postTitle !== postData.postTitle || changedPost.postPreview !== postData.postPreview || changedPost.postHeader !== postData.postHeader)
            setSaveBtnEnabled(true);
        else
            setSaveBtnEnabled(false);

    }, [changedPost]);

    const setSnackbarMessage = (message) => {
        
    }

    const handleCancel = () => {
        setChangedPost({...postData});
    };


    const handleChange = (event) => {
        const { id, value } = event.target;

        setChangedPost(prevState => {
            let newState = {...prevState, [id]: value};
            return {...newState};
        });
    };

    const handleTypeChange = (event) => {
        const { value } = event.target;

        setChangedPost(prevState => {
            let newState = {...prevState, 'type': value};
            return {...newState};
        });
    };

    const handleEditorChange = (event) => {
        let newText = sanitizeHtml(event.target.value);

        //if newText is the same as the current text, don't update
        if(newText === changedPost.postPreview)
            return; 


        setChangedPost(prevState => {
            let newState = {...prevState, postPreview: newText};
            return {...newState};
        });
    }

    const handleSave = () => {
        let postData = {};

        postData.priority = 50;
        postData.title = sanitizeHtml(changedPost.postTitle);
        postData.preview = sanitizeHtml(changedPost.postPreview);
        postData.type = changedPost.type;
        postData.link = changedPost.link;
        postData.headerType = 0;
        postData.headerImageURL = null;

        //console.log("EditEventEmail msg: " + JSON.stringify(msgData, null, 4));

        aldersgateContext.PostToAPI('content/post', postData, null, 1).then((success, err) => {
            if(success) {
    
                //switch back to view mode
                handleCancel();
    
                setSnackbarMessage("Message Updated!");
            }
    
            if(err) {
                console.log("Error: " + err);
                //switch back to view mode
                handleCancel();
                setSnackbarMessage("Message update error!");
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
            //switch back to view mode
            handleCancel();
            setSnackbarMessage("Message update error!");
        });

    };

    // const BtnAlignCenter = createButton('Align center', <FormatAlignCenterIcon />, 'justifyCenter');
    // const BtnAlignLeft = createButton('Align left', <FormatAlignLeftIcon />, 'justifyLeft');
    // const BtnAlignRight = createButton('Align right', <FormatAlignRightIcon />, 'justifyRight');
    // const BtnName = createButton('Name', <PersonIcon />, ()=> {
    //     document.execCommand('insertText', false, '{FNAME}');
    // })




    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <InputLabel id="typeLable">Post Type</InputLabel>
                    <Select
                    labelId="typeLable"
                    id="type"
                    value={changedPost.type}
                    label="Post Type"
                    onChange={handleTypeChange}
                    size='small'
                    >
                    <MenuItem value="announcement">Announcement</MenuItem>
                    <MenuItem value="video">Video</MenuItem>
                    <MenuItem value="article">Article</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            {changedPost.type === 'video' ? (
                <Grid item xs={8}>
                    <TextField fullWidth size="small" label="Video Link" id="link" value={changedPost.postLink} onChange={handleChange}/>
                </Grid>) : null}

            <Grid item xs={12}>
                <TextField fullWidth size="small" label="Title" id="postTitle" value={changedPost.postTitle} onChange={handleChange}/>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ width: '100%', minHeight: 200, borderRadius: '5px', textAlign:'left' }}>
                
                <Editor value={changedPost.postPreview} onChange={onChange} containerProps={{ style: { height:'100%', minHeight: 200, overflow:'scroll' } }}/>
                    
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                    <Button variant="contained" size="small" onClick={handleSave} disabled={!saveBtnEnabled} color="success">Save</Button>
                    <Button variant="contained" size="small" onClick={handleCancel} color="error">Cancel</Button>
                </Stack>
            </Grid>
        </Grid>
    );
}

//<Editor value={changedPost.postPreview} onChange={handleEditorChange} />


/*
                <EditorProvider>
                    <Editor value={changedPost.postPreview} onChange={handleEditorChange} containerProps={{ style: { height:'100%', overflow:'scroll' } }} >
                        <Toolbar>
                            <BtnBold />
                            <BtnItalic />
                            <BtnUnderline />
                            <BtnBulletList />
                            <BtnNumberedList />
                            <BtnLink />
                            <Separator />
                            <BtnAlignLeft />
                            <BtnAlignCenter />
                            <BtnAlignRight />
                            <Separator />
                            <BtnStyles />
                            <BtnClearFormatting />
                        </Toolbar>
                    </Editor>
                </EditorProvider>
                */