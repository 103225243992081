import React, {useState, useEffect, useContext} from "react";
import {AldersgateContext} from "../../../context/aldersgate";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";


export default function EventsCount(props) {
    const {data, dataKey, groupID, startingDate, endingDate, headerText, footerText} = props;

    const [eventsCount, setEventsCount] = useState(0);
    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {
        setEventsCount(data[dataKey]);
    }, [data]);

    const GetEventsCount = () => {
        if(groupID === undefined || groupID === null && groupID !== "")
            return;

        let query = '&groupID=' + groupID;

        if(startingDate !== undefined && startingDate !== null)
        query += '&startingDate=' + startingDate.toISOString();

        if(endingDate !== undefined && endingDate !== null)
            query += '&endingDate=' + endingDate.toISOString();

        aldersgateContext.GetFromAPI('stats/eventcount', query, 1).then((success, err) => {
            if(success) {
                setEventsCount(success?.count || 0);
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        /*fetch(SophConstants.API_ADDR + '/api/stats/eventcount?api-key=foo' + query, {
            method: 'get',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            body: null
        }).then((res) => {
            return res.json();
        }).then((jsonData, status) => {
            setEventsCount(jsonData.count);
        }).catch((err) => {
            console.log("RegionOverview: " + err);
        });*/
    }
    
    const dateOptions = {month: 'numeric', day: 'numeric', year: 'numeric' }

    const GenerateDateText = () => {
        if(startingDate !== undefined && startingDate !== null && endingDate !== undefined && endingDate !== null)
            return startingDate.toLocaleDateString(undefined, dateOptions) + "-" + endingDate.toLocaleDateString(undefined, dateOptions);
        else 
            return null;
    }

    let headerTextDisplay = headerText || "Leaders Trained";
    let footerTextDisplay = footerText || GenerateDateText() || "";

    return (
        <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'center'}} p={2}>
            <Box>
                <Typography component="p" variant="h6" color="textSecondary">
                    {headerTextDisplay}
                </Typography>
            </Box>
            <Box p={1}>
                <Typography component="p" variant="h3" color="primary">
                {eventsCount}
                </Typography>
            </Box>
            <Box>
                <Typography variant="p" color={'GrayText'}>
                    {footerTextDisplay}
                </Typography>
            </Box>
        </Box>
    );
}